import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import { useWizard } from 'react-use-wizard'
import styled from 'styled-components'

const STEP_TITLES = [
    'Inserisci il tuo username',
    'Inserisci la tua password',
    'Accetta le condizioni',
]
export const StepTitle = (props: { title: string }) => {
    return (
        <div className="mb-4">
            <h2 className="text-primary font-bold text-md">{props.title}</h2>
        </div>
    )
}
export const StepNavButton = (props: {
    disabled?: boolean
    text: string
    onClick: () => void
}) => (
    <button
        type="button"
        disabled={props.disabled}
        className="bg-primary-700 border disabled:border-0 text-white border-primary disabled:bg-slate-400 disabled:text-box px-3 py-2 font-bold rounded shadow-lg w-full"
        onClick={props.onClick}
    >
        {props.text}
    </button>
)

export const StepBox = (props: { children: ReactNode }) => (
    <div className="bg-secondary/50 backdrop-blur-sm h-full w-full pt-4 px-4">
        <div className="flex flex-col h-full w-full sm:w-[480px] sm:m-auto">
            {props.children}
        </div>
    </div>
)

export const StepsWrapper = (props: { children?: ReactNode }) => {
    return (
        <motion.div
            className="grow"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -100, opacity: 0 }}
        >
            {props.children}
        </motion.div>
    )
}

export const StepError = (props: { fieldError?: FieldError }) => (
    <div>
        {props.fieldError?.message && (
            <div className="bg-secondary border border-red-800 rounded px-2">
                <span className="text-xs leading-3 text-red-400">
                    {props.fieldError.message}
                </span>
            </div>
        )}
    </div>
)
export const StepInputContainer = (props: { children: ReactNode }) => (
    <div className="p-3 bg-secondary/60 rounded shadow-lg font-bold text-lg w-full mb-2 flex items-center">
        {props.children}
    </div>
)
export const StepInput = styled.input.attrs(() => {
    return {
        className: 'h-full outline-none bg-transparent w-full',
    }
})``
