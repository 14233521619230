import Hr from '../styled/Hr'
import User, { UserSocials } from '../../models/user.model'

const Detail = ({ label, value }: { label: string; value?: string }) => {
    return (
        <div className="flex items-center gap-2 mb-2">
            <span className="text-xxxs flex-[.3] uppercase">{label}</span>
            <span className="flex-1">{value || '-'}</span>
        </div>
    )
}
const UserDetails = ({ user }: { user: User }) => {
    return (
        <div>
            <Detail label="username" value={user.username} />
            <Detail label="nome" value={user.nome} />
            <Detail label="cognome" value={user.cognome} />
            <Detail label="email" value={user.email} />
            <Detail label="referer" value={user.referer} />
            {user.socials && (
                <>
                    <Hr />
                    {Object.keys(user.socials).map((key) => (
                        <Detail
                            label={key}
                            value={user.socials![key as keyof UserSocials]}
                        />
                    ))}{' '}
                </>
            )}
        </div>
    )
}

export default UserDetails
