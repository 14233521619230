import { ChangeEvent, useEffect, useState } from 'react'
import { EsitoData } from '../../../models/forecast-composition.model'
import ToggleSwitch from '../../ToggleSwitch'

type Props = {
    onChange: (data: EsitoData) => void
}

const values: EsitoData[] = ['1', 'X', '2', '1X', 'X2', '12']

const EsitoForm = (props: Props) => {
    const ids = values.map((v) => v + Math.random())

    const [data, setData] = useState<EsitoData>('1')

    const onCheck = (e: ChangeEvent<HTMLInputElement>, esito: EsitoData) => {
        e.target.checked && setData(esito)
    }
    useEffect(() => props.onChange(data), [data])

    return (
        <div className="flex gap-1 items-center justify-start md:justify-around">
            {values.map((d, i) => (
                <ToggleSwitch
                    id={ids[i]}
                    key={d}
                    label={d}
                    checked={data == d}
                    onChange={(e) => onCheck(e, d)}
                    labelOnLeft
                />
            ))}
        </div>
    )
}

export default EsitoForm
