import { Navigate, Route, Routes } from 'react-router-dom'
import JWTGuardedRoute from './JWTGuardedRoute'
import NotLoggedGuardedRoute from './NotLoggedGuardedRoute'
import Dashboard from '../pages/admin/Dashboard'
import Pronostici from '../pages/admin/Pronostici'
import Storico from '../pages/admin/Storico'
import Client from '../pages/client'
import Home from '../pages/home'
import LoginForm from '../pages/home/LoginForm'
import Register from '../pages/home/register/Register'
import UserPronostici from '../pages/client/UserPronostici'
import MyForecasts from '../pages/client/MyForecasts'
import UserStorico from '../pages/client/UserStorico'
import routes from './routes'
import Movimenti from '../pages/tipster/Movimenti'
import PackPurchaseRevenues from '../pages/PackPurchaseRevenues'
import TipsterDashboard from '../pages/tipster/dashboard'
import UserDashboard from '../pages/client/dashboard'
import HomeDashboard from '../pages/client/dashboard2'

import UtentiPageTipster from '../pages/tipster/Utenti'
import Profile from '../pages/Profile'
import ResetPassword from '../pages/home/ResetPassword'
import NewPasswordForm from '../pages/home/NewPasswordForm'
import ChangePasswordForm from '../components/forms/ChangePassword'
import TipsterProfileUpdateForm from '../pages/tipster/TipsterProfileUpdateForm'
import ProfileHome from '../components/profile'
import UserProfileHome from '../pages/client/UserProfileHome'
import RegisterTipster from '../pages/home/register/RegisterTipster'
import HelpForm from '../components/forms/Help'
import EmailConfirmedGuardedRoute from './EmailConfirmedGuardedRoute'
import Tipsters from '../pages/client/tipsters'
import TipsterProfilePage from '../pages/client/tipsters/TipsterProfilePage'
import Charts from '../pages/Charts'
import UserProfileUpdateForm from '../pages/client/ProfileUpdateForm'
import UploadProfilePicture from '../components/profile/UploadProfilePicture'
import AdminGuardedRoute from './AdminGuardedRoute'
import TipsterGuardedRoute from './TipsterGuardedRoute'
import UserGuardedRoute from './UserGuardedRoute'
import Selling from '../pages/admin/Selling'
import Affiliate from '../pages/affiliate'
import RegisterAffiliate from '../pages/home/register/RegisterAffiliate'
import AffiliateGuardedRoute from './AffiliateGuardedRoute'
import AffiliateUtentiPage from '../pages/affiliate/utenti'
import Admin from '../pages/admin'
import PackPurchasesList from '../pages/admin/Dashboard/PackPurchasesList'
import EditPacks from '../pages/admin/Dashboard/EditPacks'
import ManageMatches from '../pages/admin/Dashboard/ManageMatches'
import AdminUtentiPage from '../pages/admin/Users'
import Forecasts from '../pages/client/forecasts'
import TipstersChart from '../components/tipsters-chart'

const Routing = () => {
    return (
        <Routes>
            {/* HOME */}
            <Route element={<NotLoggedGuardedRoute />}>
                <Route path={routes.home} element={<Home />}>
                    <Route path={routes.login} element={<LoginForm />} />
                    <Route path={routes.register} element={<Register />} />
                    <Route
                        path={routes.registerAffiliate}
                        element={<RegisterAffiliate />}
                    />
                    <Route
                        path={routes.registerTipster}
                        element={<RegisterTipster />}
                    />
                    <Route
                        path={routes.passwordReset}
                        element={<ResetPassword />}
                    />
                    <Route
                        path={routes.newPassword}
                        element={<NewPasswordForm />}
                    />
                    <Route index element={<HomeDashboard />} />
                </Route>
            </Route>

            {/* ADMIN */}
            <Route element={<JWTGuardedRoute />}>
                <Route
                    path={routes.admin}
                    element={
                        <AdminGuardedRoute>
                            <Admin />
                        </AdminGuardedRoute>
                    }
                >
                    <Route
                        index
                        element={<Navigate to={routes.adminDashboard} />}
                    />
                    <Route
                        path={routes.adminDashboard}
                        element={<Dashboard />}
                    ></Route>
                    <Route
                        path={routes.adminDashboardPackPurchases}
                        element={<PackPurchasesList />}
                    />
                    <Route
                        path={routes.adminDashboardEditPacks}
                        element={<EditPacks />}
                    />
                    <Route
                        path={routes.adminDashboardManual}
                        element={<ManageMatches />}
                    />
                    <Route
                        path={routes.adminDashboardTipsters}
                        element={<Tipsters />}
                    />
                    <Route
                        path={routes.adminPronostici}
                        element={<Pronostici />}
                    />
                    <Route path={routes.adminStorico} element={<Storico />} />
                    <Route path={routes.adminCharts} element={<Charts />} />
                    <Route
                        path={routes.adminUtenti}
                        element={<AdminUtentiPage />}
                    />
                    <Route
                        path={'/admin/tipsters/:username'}
                        element={<TipsterProfilePage />}
                    />
                    <Route path={routes.adminSelling} element={<Selling />} />
                </Route>
            </Route>

            {/* AFFILIATE */}
            <Route element={<JWTGuardedRoute />}>
                <Route
                    path={routes.affiliate}
                    element={
                        <AffiliateGuardedRoute>
                            <Affiliate />
                        </AffiliateGuardedRoute>
                    }
                >
                    <Route
                        index
                        element={<Navigate to={routes.affiliatePronostici} />}
                    />

                    <Route
                        path={routes.affiliatePronostici}
                        element={<Pronostici />}
                    />
                    <Route
                        path={routes.affiliateStorico}
                        element={<Storico />}
                    />
                    <Route path={routes.affiliateCharts} element={<Charts />} />
                    <Route
                        path={routes.affiliateUtenti}
                        element={<AffiliateUtentiPage />}
                    />
                    <Route path={routes.affiliateProfile} element={<Profile />}>
                        <Route
                            index
                            element={
                                <Navigate to={routes.affiliateProfileHome} />
                            }
                        />
                        <Route
                            path={routes.affiliateProfileHome}
                            element={<ProfileHome />}
                        />
                        <Route
                            path={routes.affiliateProfileUpdate}
                            element={<TipsterProfileUpdateForm />}
                        />
                        <Route
                            path={routes.affiliateChangePassword}
                            element={<ChangePasswordForm />}
                        />
                        <Route
                            path={routes.affiliateProfileUploadProfilePicture}
                            element={<UploadProfilePicture />}
                        />
                    </Route>
                    <Route
                        path={'/affiliate/tipsters/:username'}
                        element={<TipsterProfilePage />}
                    />
                    <Route
                        path={routes.affiliateMovimenti}
                        element={<PackPurchaseRevenues />}
                    />
                </Route>
            </Route>

            {/* TIPSTER */}
            <Route element={<JWTGuardedRoute />}>
                <Route
                    path={routes.tipster}
                    element={
                        <TipsterGuardedRoute>
                            <Admin />
                        </TipsterGuardedRoute>
                    }
                >
                    <Route
                        index
                        element={<Navigate to={routes.tipsterPronostici} />}
                    />
                    <Route
                        path={routes.tipsterDashboard}
                        element={<TipsterDashboard />}
                    />

                    <Route
                        path={routes.tipsterPronostici}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <Pronostici />
                            </EmailConfirmedGuardedRoute>
                        }
                    />

                    <Route
                        path={routes.tipsterStorico}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <Storico />
                            </EmailConfirmedGuardedRoute>
                        }
                    />
                    <Route
                        path={routes.tipsterUtenti}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <UtentiPageTipster />
                            </EmailConfirmedGuardedRoute>
                        }
                    />

                    <Route
                        path={routes.tipsterMovimenti}
                        element={<Movimenti />}
                    />
                    <Route path={routes.tipsterProfile} element={<Profile />}>
                        <Route
                            index
                            element={
                                <Navigate to={routes.tipsterProfileHome} />
                            }
                        />
                        <Route
                            path={routes.tipsterProfileHome}
                            element={<ProfileHome />}
                        />
                        <Route
                            path={routes.tipsterProfileUpdate}
                            element={<TipsterProfileUpdateForm />}
                        />
                        <Route
                            path={routes.tipsterChangePassword}
                            element={<ChangePasswordForm />}
                        />
                        <Route
                            path={routes.tipsterProfileUploadProfilePicture}
                            element={<UploadProfilePicture />}
                        />
                    </Route>
                    <Route
                        path={routes.tipsterHelp}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <HelpForm />
                            </EmailConfirmedGuardedRoute>
                        }
                    />
                    <Route
                        path={routes.tipsterCharts}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <Tipsters />
                            </EmailConfirmedGuardedRoute>
                        }
                    />
                    <Route
                        path={'/tipster/tipsters/:username'}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <TipsterProfilePage />
                            </EmailConfirmedGuardedRoute>
                        }
                    />
                </Route>
            </Route>

            <Route
                element={
                    <UserGuardedRoute>
                        <JWTGuardedRoute />
                    </UserGuardedRoute>
                }
            >
                <Route path={routes.app} element={<Client />}>
                    <Route
                        index
                        element={<Navigate to={routes.userTipsters} />}
                    />
                    <Route
                        path={routes.appDashboard2}
                        element={<HomeDashboard />}
                    />
                    <Route
                        path={routes.appDashboard}
                        element={<UserDashboard />}
                    />
                    <Route
                        path={routes.appPronostici}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <Forecasts />
                            </EmailConfirmedGuardedRoute>
                        }
                    />
                    <Route
                        path={routes.appMyPronostici}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <MyForecasts />
                            </EmailConfirmedGuardedRoute>
                        }
                    />
                    <Route path={routes.userProfile} element={<Profile />}>
                        <Route
                            index
                            element={<Navigate to={routes.userProfileHome} />}
                        />
                        <Route
                            path={routes.userProfileHome}
                            element={<UserProfileHome />}
                        />

                        <Route
                            path={routes.userChangePassword}
                            element={<ChangePasswordForm />}
                        />
                        <Route
                            path={routes.userProfileUpdate}
                            element={<UserProfileUpdateForm />}
                        />
                        <Route
                            path={routes.userProfileUploadProfilePicture}
                            element={<UploadProfilePicture />}
                        />
                    </Route>
                    <Route
                        path={routes.userTipsters}
                        element={
                            // <EmailConfirmedGuardedRoute>
                            <Tipsters />
                            // </EmailConfirmedGuardedRoute>
                        }
                    ></Route>
                    <Route
                        path={routes.userTipsters + '/:username'}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <TipsterProfilePage />
                            </EmailConfirmedGuardedRoute>
                        }
                    />
                    <Route
                        path={routes.userHelp}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <HelpForm />
                            </EmailConfirmedGuardedRoute>
                        }
                    />
                    <Route
                        path={routes.appStorico}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <Storico />
                            </EmailConfirmedGuardedRoute>
                        }
                    />

                    <Route
                        path={routes.appCharts}
                        element={
                            <EmailConfirmedGuardedRoute>
                                <Charts />
                            </EmailConfirmedGuardedRoute>
                        }
                    />
                </Route>
            </Route>
            {/* <Route path="*" element={<Navigate to="/" />} /> */}
            <Route path="*" element={<Navigate to={routes.home} />} />
        </Routes>
    )
}

export default Routing
