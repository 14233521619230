import { useWizard } from 'react-use-wizard'

const ProgressBar = (props: { total: number }) => {
    const { activeStep } = useWizard()
    return (
        <div className="block h-3 my-2 bg-secondary border border-primary-800 rounded-full relative box-content">
            <div
                style={{ width: `${(activeStep * 100) / props.total}%` }}
                className={
                    'bg-primary transition-all duration-100 ease-out absolute h-3  block rounded-full top-0 left-0 '
                }
            ></div>
        </div>
    )
}

export default ProgressBar
