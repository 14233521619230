import {
    faCircleInfo,
    faLock,
    faPowerOff,
    faTriangleExclamation,
    faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from 'react-router-dom'
import { useAppState } from '../context/AppState'
import { useAuth } from '../context/Auth'
import routes from '../routing/routes'
import Swal from 'sweetalert2'
import { useQuery } from 'react-query'
import { apiServiceInst } from '../services/Api'

const UserBar = () => {
    const { user, logout } = useAuth()
    const { balance, setBalance } = useAppState()
    const onHoldBCQuery = useQuery('on-hold-bc', {
        queryFn: () => apiServiceInst.getOnHoldBC(),
        staleTime: Infinity,
    })
    const navigate = useNavigate()

    return (
        <div
            className="p-2 shrink-0 bg-black/[.1] gap-4 h-10 border-b border-black/[.05] md:mb-4 flex 
        justify-end items-center text-white text-xs font-light"
        >
            <div className="px-2 py-[2px] md:mr-0 bg-white/[.1] rounded whitespace-nowrap">
                <Link to={routes.profile}>
                    <FontAwesomeIcon icon={faUser} className="mr-1" />
                    <span className="font-bold">{user?.username}</span>
                </Link>
            </div>
            {!user?.emailConfirmed && (
                <div className="text-yellow-400 whitespace-nowrap overflow-hidden w-20 overflow-ellipsis">
                    <FontAwesomeIcon
                        fixedWidth
                        className="pr-1"
                        icon={faTriangleExclamation}
                    />
                    <span className="text-xxs font-bold">
                        Email non confermata!
                    </span>
                </div>
            )}
            <div className="flex-1"></div>
            {!user?.admin && !user?.tipster && (
                <div className="flex gap-4 items-center">
                    {/* <span className="text-gray-500 mr-2">Bilancio:</span> */}
                    {onHoldBCQuery.data !== undefined &&
                        onHoldBCQuery.data > 0 && (
                            <div
                                className="flex items-center  gap-1 font-bold text-black whitespace-nowrap bg-gradient-to-r border border-primary from-primary to-yellow-600 shadow-lg rounded-lg px-1 text-center"
                                onClick={() =>
                                    Swal.fire({
                                        text: 'Acquista un pacchetto per sbloccare i crediti accumulati!',

                                        confirmButtonText: 'Vai!',
                                        showCancelButton: true,
                                        cancelButtonText: 'Annulla',
                                    }).then((value) => {
                                        if (value.isConfirmed) {
                                            navigate('dashboard')
                                        }
                                    })
                                }
                            >
                                <FontAwesomeIcon icon={faLock} />
                                <div>
                                    <div className="text-xxs">SBLOCCA</div>
                                    <div>{onHoldBCQuery.data} BC</div>
                                </div>
                            </div>
                        )}
                    <div className="font-bold whitespace-nowrap align-middle text-center">
                        <div className="text-xxs text-gray-200 font-light">
                            SALDO
                        </div>
                        <div>{balance} BC</div>
                    </div>
                </div>
            )}
            {!user?.admin && (
                <Link to={user?.tipster ? routes.tipsterHelp : routes.userHelp}>
                    <FontAwesomeIcon icon={faCircleInfo} />
                </Link>
            )}
            <div
                className="hover:text-gray-400 cursor-pointer"
                onClick={() => {
                    logout()
                    navigate('/')
                }}
            >
                <FontAwesomeIcon icon={faPowerOff} />
            </div>
        </div>
    )
}

export default UserBar
