import { useState } from 'react'
import InputField from '../../components/InputField'
import Label from '../../components/styled/Label'
import { PAYMENT_METHODS } from '../../constants'
import Tipster, {
    PaymentTypes,
    TipsterPaymentsMethods,
} from '../../models/tipster.model'

type Props = {
    payments: TipsterPaymentsMethods
    onUpdate: (payments: TipsterPaymentsMethods) => void
}

const PaymentsUpdateForm = (props: Props) => {
    const [payments, setPayments] = useState<TipsterPaymentsMethods>(
        props.payments || {
            payPalID: '',
            neteller: { id: '', nome: '', cognome: '' },
            skrill: { id: '', nome: '', cognome: '' },
        },
    )
    const update = (newPayments: TipsterPaymentsMethods) => {
        setPayments(newPayments)
        props.onUpdate(newPayments)
    }
    return (
        <div>
            <div className="my-2 mb-4">
                <Label>Metodo preferito</Label>
                <select
                    onChange={(e) =>
                        update({
                            ...payments,
                            favourite: e.target.value as PaymentTypes,
                        })
                    }
                    value={payments.favourite as string}
                    className="w-full pl-4 bg-black/[.15] border h-10 rounded shadow border-1 border-primary/[.15] text-sm disabled:bg-black/[.05] disabled:text-gray-50"
                >
                    {Object.keys(PAYMENT_METHODS).map((k: string) => (
                        <option key={k} value={k}>
                            {PAYMENT_METHODS[k]}
                        </option>
                    ))}
                </select>
            </div>
            <h5 className="text-xs">Paypal</h5>
            <InputField
                label="Email"
                placeholder="Email"
                type="text"
                className="my-2 mb-4"
                value={payments.paypal.email}
                onChange={(e) =>
                    update({
                        ...payments,
                        paypal: { ...payments.paypal, email: e.target.value },
                    })
                }
            />
            <h5 className="text-xs">Neteller</h5>
            <InputField
                label="Email o telefono"
                placeholder="Email o telefono"
                type="text"
                className="my-2 mb-4"
                value={payments.neteller.id}
                onChange={(e) =>
                    update({
                        ...payments,
                        neteller: { ...payments.neteller, id: e.target.value },
                    })
                }
            />
            <InputField
                label="Nome"
                placeholder="Nome"
                type="text"
                className="my-2 mb-4"
                value={payments.neteller.nome}
                onChange={(e) =>
                    update({
                        ...payments,
                        neteller: {
                            ...payments.neteller,
                            nome: e.target.value,
                        },
                    })
                }
            />
            <InputField
                label="Cognome"
                placeholder="Cognome"
                type="text"
                className="my-2 mb-4"
                value={payments.neteller.cognome}
                onChange={(e) =>
                    update({
                        ...payments,
                        neteller: {
                            ...payments.neteller,
                            cognome: e.target.value,
                        },
                    })
                }
            />

            <h5 className="text-xs">Skrill</h5>
            <InputField
                label="Email o telefono"
                placeholder="Email o telefono"
                type="text"
                className="my-2 mb-4"
                value={payments.skrill.id}
                onChange={(e) =>
                    update({
                        ...payments,
                        skrill: { ...payments.skrill, id: e.target.value },
                    })
                }
            />
            <InputField
                label="Nome"
                placeholder="Nome"
                type="text"
                className="my-2 mb-4"
                value={payments.skrill.nome}
                onChange={(e) =>
                    update({
                        ...payments,
                        skrill: {
                            ...payments.skrill,
                            nome: e.target.value,
                        },
                    })
                }
            />
            <InputField
                label="Cognome"
                placeholder="Cognome"
                type="text"
                className="my-2 mb-4"
                value={payments.skrill.cognome}
                onChange={(e) =>
                    update({
                        ...payments,
                        skrill: {
                            ...payments.skrill,
                            cognome: e.target.value,
                        },
                    })
                }
            />
        </div>
    )
}

export default PaymentsUpdateForm
