import React, { createContext, useContext, useEffect, useState } from 'react'
import ForecastModel from '../models/forecast.model'
import MatchModel from '../models/match.model'

export type PageSize = 'full' | 'default'
type AppStateContextType = {
    pageSize: PageSize
    setPageSize: (size: PageSize) => void
    forecasts: ForecastModel[]
    matches: { [key: string]: MatchModel[] }
    updateMatches: (day: string, matches: MatchModel[]) => void
    addForecast: (forecast: ForecastModel) => void
    removeForecast: (id: string) => void
    setForecasts: (forecasts: ForecastModel[]) => void
    toggleModal: (show: boolean, title?: string, content?: JSX.Element) => void
    showModal: boolean
    modalContent: JSX.Element | null
    modalTitle: string
    loading: boolean
    switchLoading: (value: boolean) => void
    balance: number
    setBalance: (value: number) => void
    selectedForecasts: ForecastModel[]
    setSelectedForecasts: (selected: boolean, forecast: ForecastModel) => void
    emptySelectedForecasts: () => void
    timezone: string
}

type Props = {
    children: JSX.Element
}

export const AppStateContext = createContext<AppStateContextType | null>(null)

export const useAppState = (): AppStateContextType => {
    const context = useContext(AppStateContext)

    if (!context) {
        throw Error('Wrong usage')
    }
    return context
}

export const AppStateContextProvider = ({ children }: Props) => {
    const [pageSize, setPageSize] = useState<PageSize>('default')
    const [matches, setMatches] = useState({})
    const [forecasts, setForecasts] = useState<ForecastModel[]>([])
    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState<JSX.Element | null>(null)
    const [modalTitle, setModalTitle] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const [balance, setBalance] = useState(0)
    const [selectedForecasts, setSelectedForecasts] = useState<ForecastModel[]>(
        [],
    )
    return (
        <AppStateContext.Provider
            value={{
                pageSize,
                setPageSize,
                matches,
                updateMatches: (day: string, dayMatches: MatchModel[]) => {
                    const newMatches: { [key: string]: MatchModel[] } = {
                        ...matches,
                    }
                    newMatches[day] = dayMatches
                    setMatches(newMatches)
                },
                forecasts,
                addForecast: (forecast: ForecastModel) => {
                    const newForecasts = [...forecasts, forecast]
                    // newForecasts.unshift(forecast)
                    setForecasts(newForecasts)
                },
                removeForecast: (id: string) => {
                    const idx = forecasts.findIndex((f) => f.id == id)
                    forecasts.splice(idx, 1)
                    const newForecasts = [...forecasts]
                    setForecasts(newForecasts)
                },
                setForecasts: (forecasts: ForecastModel[]) => {
                    setForecasts(forecasts)
                },
                toggleModal: (
                    show: boolean,
                    title?: string,
                    content?: JSX.Element,
                ) => {
                    setShowModal(show)
                    if (content) {
                        setModalContent(content)
                    }
                    if (title) {
                        setModalTitle(title)
                    }
                },
                showModal,
                modalContent,
                modalTitle,
                loading,
                switchLoading: (value: boolean) => setLoading(value),
                balance,
                setBalance,
                selectedForecasts,
                setSelectedForecasts: (
                    selected: boolean,
                    forecast: ForecastModel,
                ) => {
                    if (selected) {
                        setSelectedForecasts([...selectedForecasts, forecast])
                    } else {
                        let newSelectedForecasts = [...selectedForecasts]
                        const idx = selectedForecasts.indexOf(forecast)
                        newSelectedForecasts.splice(idx, 1)

                        setSelectedForecasts(newSelectedForecasts)
                    }
                },
                emptySelectedForecasts: () => setSelectedForecasts([]),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }}
        >
            {children}
        </AppStateContext.Provider>
    )
}
