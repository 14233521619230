import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import MatchModel from '../../models/match.model'
import routes from '../../routing/routes'
import { getProfilePictureUrl } from '../../utils'
import GridLayout from '../GridLayout'

export const TipsterName = (props: {
    creator: { id: string; username: string; profilePicture: string }
}) => {
    const username = props.creator?.username || 'YT'
    return (
        <Link to={routes.userTipsters + '/' + username}>
            <div className="p-1 hover:bg-gray-600 inline-block rounded-full -my-1">
                <img
                    className="mr-1 rounded-full w-4 h-4 inline object-cover"
                    src={getProfilePictureUrl(props.creator?.profilePicture)}
                />
                <div className="underline shadow inline-block font-bold text-xxs leading-tight">
                    {username}
                </div>
            </div>
        </Link>
    )
}

export const MatchDateTime = (props: {
    matchDate: string
    hideTime?: boolean
}) => (
    <div className="text-white/[.7] text-xxxs md:text-[0.6rem] leading-tight mb-1 whitespace-nowrap">
        <FontAwesomeIcon className="mr-1" icon={faClock} />
        <span>{format(new Date(props.matchDate), 'dd/MM/yyyy')}</span>
        {!props.hideTime && (
            <>
                <span className="mx-1 font-bold">·</span>
                <span>{format(new Date(props.matchDate), 'HH:mm')}</span>
            </>
        )}
    </div>
)

export const TeamTag = styled.div.attrs((props) => {
    return {
        className:
            props.className +
            ' leading-tight py-[1px] font-bold text-[11px] sm:text-xs text-yellow-400 text-ellipsis overflow-hidden whitespace-nowrap',
    }
})``

export const MatchGrid = (props: { match: MatchModel }) => (
    <div className="flex-1 flex flex-col py-1">
        <MatchDateTime matchDate={props.match.date} />
        <GridLayout
            rows={{ mob: 2, lg: 1 }}
            className="flex-1"
            padding={0}
            gap={0}
            elements={[
                {
                    grid: { cols: 2, rows: 1, fullWidth: true },
                    content: <TeamTag>{props.match.homeTeam}</TeamTag>,
                },
                {
                    grid: { hidden: true, lg: { cols: 1, rows: 1 } },
                    content: '-',
                },
                {
                    grid: { cols: 2, rows: 1, fullWidth: true },
                    content: <TeamTag>{props.match.awayTeam}</TeamTag>,
                },
            ]}
        ></GridLayout>
    </div>
)

export const PriceTag = (props: { price: number }) => (
    <div className="flex items-center gap-1">
        <span className="font-bold">{props.price}</span>{' '}
        <span className="font-thin text-xxs">BC</span>
    </div>
)

export const Quota = (props: { value: number }) => {
    return (
        <div className="text-center leading-tight">
            <div className="text-xxxs font-thin uppercase">Quota</div>
            <div className="font-bold text-lg text-primary">
                {props.value?.toFixed(2)}
            </div>
        </div>
    )
}

export const Stake = (props: { value?: number }) => {
    const value = props.value ? props.value + ' / 10' : 'FREE'
    return (
        <div className="text-center leading-tight">
            <div className="text-xxxs font-thin uppercase">stake</div>
            <div className="font-bold whitespace-nowrap">{value}</div>
        </div>
    )
}
