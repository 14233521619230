import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useApi } from '../../context/Api'
import routes from '../../routing/routes'
import Button from '../Button'
import InputField from '../InputField'
import TextArea from '../styled/TextArea'

const MAX_CHARACTERS = 300

const HelpForm = () => {
    const [data, setData] = useState({ subject: '', text: '', error: '' })
    const { sendHelpRequest } = useApi()
    const navigate = useNavigate()
    return (
        <div className="text-white p-2 h-full w-full md:w-1/2 mx-auto">
            <h1 className="mb-4">Invia una richiesta di assistenza</h1>
            <form className="">
                <div className="mb-4">
                    <InputField
                        maxLength={25}
                        value={data.subject}
                        onChange={(e) =>
                            setData({
                                ...data,
                                subject: e.target.value,
                                error: '',
                            })
                        }
                        type="text"
                        label="Oggetto"
                    />
                </div>
                <div className="mb-4">
                    <div className="flex justify-between">
                        <label className="block text-xxs text-primary/[.6] ml-4 mb-1">
                            Testo
                        </label>
                        <span className="text-xxxs mr-2 text-gray-400">
                            {data.text.length} / {MAX_CHARACTERS}
                        </span>
                    </div>
                    <TextArea
                        maxLength={MAX_CHARACTERS}
                        value={data.text}
                        onChange={(e) =>
                            setData({
                                ...data,
                                text: e.target.value,
                                error: '',
                            })
                        }
                        className="w-full h-40"
                        rows={20}
                    />
                </div>
                {data.error && (
                    <div className="text-xxs text-red-500 mb-4">
                        {data.error}
                    </div>
                )}
                <Button
                    onClick={(e: any) => {
                        e.preventDefault()
                        if (!data.text || !data.subject) {
                            setData({
                                ...data,
                                error: 'Inserire oggetto e testo!',
                            })
                            return
                        }
                        if (data.subject.length < 3 || data.text.length < 3) {
                            setData({
                                ...data,
                                error: 'Riempire correttamente i campi oggetto e testo!',
                            })
                            return
                        }
                        sendHelpRequest(data.subject, data.text).then((res) => {
                            res &&
                                Swal.fire({
                                    title: 'Richiesta inviata!',
                                    text: 'Sarai ricontattato dal nostro staff il prima possibile.',
                                })
                            navigate(routes.home)
                        })
                    }}
                    className="w-full md:w-auto mx-auto block"
                    color="primary"
                >
                    Invia
                </Button>
            </form>
        </div>
    )
}

export default HelpForm
