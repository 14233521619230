import {
    faDollar,
    faEnvelope,
    faLock,
    faMoneyBill,
    faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppState } from '../../context/AppState'
import { useAuth } from '../../context/Auth'
import User from '../../models/user.model'
import Button from '../Button'
import GridLayout from '../GridLayout'
import UserDetails from './UserDetails'
import UserTransactions from './UserTransactions'
import { format } from 'date-fns'
import { zonedDate } from '../../utils'

type Props = {
    index: number
    user: User
    onUpdate?: (user: User) => void
}

const UtentiRow = (props: Props) => {
    const { toggleModal } = useAppState()
    const { getUser } = useAuth()
    const gridElements = [
        {
            grid: {
                cols: 1,
                rows: 1,
                lg: { rows: 1, cols: 1 },
            },
            content: (
                <div
                    onClick={() =>
                        getUser()?.admin &&
                        toggleModal(
                            true,
                            'Dettagli utente',
                            <UserDetails user={props.user} />,
                        )
                    }
                >
                    <FontAwesomeIcon icon={faUser} />
                </div>
            ),
        },
        {
            grid: { cols: 3, rows: 1 },
            content: (
                <div className="text-center">
                    <div className="text-xs">{props.user.username}</div>
                    {props.user.registrationDate && (
                        <span className="text-xxxs font-light">
                            {format(
                                zonedDate(props.user.registrationDate),
                                'dd/MM/yyyy',
                            )}
                        </span>
                    )}
                </div>
            ),
        },

        {
            grid: { cols: 1, rows: 1, lg: { rows: 1 } },
            content: <div className="font-bold">{props.user.balance || 0}</div>,
        },
    ]
    if (getUser()?.admin) {
        gridElements.push({
            grid: { cols: 2, rows: 1, lg: { rows: 1 } },
            content: (
                <div className="gap-1 flex">
                    <Button
                        size="xs"
                        onClick={() =>
                            toggleModal(
                                true,
                                "Stato dell'utente",
                                <UserTransactions
                                    onUpdate={props.onUpdate}
                                    user={props.user}
                                />,
                            )
                        }
                    >
                        <FontAwesomeIcon fixedWidth={true} icon={faDollar} />
                    </Button>
                    <Button size="xs">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </Button>
                </div>
            ),
        })
    }
    return (
        <div
            className={`text-xxs md:text-xs lg:p-2 rounded bg-black/[.${
                props.index % 2 == 0 ? '2' : '05'
            }]`}
        >
            <GridLayout elements={gridElements} />
        </div>
    )
}

export default UtentiRow
