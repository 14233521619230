import styled from 'styled-components'

type ChildrenProps = {
    children?: React.ReactChild
}

export const PageTitle = (props: ChildrenProps) => (
    <h1 className="text-primary font-bold text-[1.5rem] mb-3">
        {props.children}
    </h1>
)

export const SectionTitle = (props: ChildrenProps) => (
    <h3 className="text-primary font-bold text-lg">{props.children}</h3>
)
type NotAvailableProps = {
    forecasts?: boolean
}
export const NotAvailableTitle = (props: NotAvailableProps) => (
    <div className="text-center p-8 text-sm font-light text-gray-500/[.5]">
        {props.forecasts && 'Nessun pronostico disponibile.'}
    </div>
)
