import ScrollableContent from '../../../components/ScrollableContent'
import { PageTitle } from '../../../components/typography'

import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import MutedText from '../../../components/styled/MutedText'
import { useApi } from '../../../context/Api'
import { useAppState } from '../../../context/AppState'
import MatchModel from '../../../models/match.model'
import { apiServiceInst } from '../../../services/Api'
import ManualOutcomeForm from './ManualOutcomeForm'
import MatchElement from './MatchElement'

const ManageMatches = () => {
    const { getManualOutcomeMatch } = useApi()
    const { toggleModal } = useAppState()
    const [matches, setMatches] = useState<MatchModel[]>([])
    useEffect(() => {
        getManualOutcomeMatch().then((res) => setMatches(res))
    }, [])

    const onPostopone = async (matchId: number) => {
        Swal.fire({
            text: 'Sei sicuro di voler settare il match come postponed?',
            showCancelButton: true,
            showCloseButton: true,
        }).then(async (res) => {
            if (res.isConfirmed) {
                await apiServiceInst.setMatchPostponed(matchId).then((res) => {
                    if (!res) {
                        return
                    }
                    setMatches(matches.filter((m) => m.id != matchId))
                })
            }
        })
    }
    const onDelete = async (matchId: number) => {
        Swal.fire({
            text: 'Sei sicuro di voler rimuovere il match da tutti i pronostici?',
            showCancelButton: true,
            showCloseButton: true,
        }).then(async (res) => {
            if (res.isConfirmed) {
                await apiServiceInst.deleteMatch(matchId).then((res) => {
                    if (!res) {
                        return
                    }
                    setMatches(matches.filter((m) => m.id != matchId))
                })
            }
        })
    }
    return (
        <div className="flex flex-col h-full">
            <PageTitle>Gestione Match</PageTitle>
            <ScrollableContent>
                <div>
                    {matches.length == 0 && (
                        <MutedText className="p-8 text-center text-sm">
                            Nessun match da gestire.
                        </MutedText>
                    )}
                    {matches.length > 0 && (
                        <div className="flex flex-col gap-2">
                            {matches.map((m, i) => (
                                <MatchElement
                                    key={i}
                                    match={m}
                                    onPostpone={() => onPostopone(m.id)}
                                    onEdit={() =>
                                        toggleModal(
                                            true,
                                            'Esita manualmente il match',
                                            <ManualOutcomeForm
                                                matchID={m.id}
                                                onSubmit={() => {
                                                    const idx = matches.find(
                                                        (mm) => mm.id == m.id,
                                                    )
                                                    const newMatches = [
                                                        ...matches,
                                                    ]
                                                    newMatches.splice(
                                                        newMatches.indexOf(
                                                            idx!,
                                                        ),
                                                        1,
                                                    )

                                                    setMatches(newMatches)
                                                }}
                                            />,
                                        )
                                    }
                                    onDelete={() => onDelete(m.id)}
                                    idx={i}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </ScrollableContent>
        </div>
    )
}

export default ManageMatches
