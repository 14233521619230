import 'animate.css'
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom'
import { useEffect } from 'react'
import { useAuth } from './context/Auth'
import Loader from './components/Loader'
import Routing from './routing/Routing'
import routes from './routing/routes'
import { useAppState } from './context/AppState'
import AppModal from './components/AppModal'
import useAuthorizations from './hooks/useAuthorizations'

function ContextApp() {
    const { showModal } = useAppState()
    const { navigateHome } = useAuthorizations()
    useEffect(() => {
        navigateHome()
    }, [])
    return (
        <>
            <Loader />
            <Routing />
            {showModal && <AppModal />}
        </>
    )
}

export default ContextApp
