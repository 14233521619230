import { useState } from 'react'
import { useQuery } from 'react-query'
import Swal from 'sweetalert2'
import Button from '../../../components/Button'
import ScrollableContent from '../../../components/ScrollableContent'
import CommissionsList from '../../../components/commissions/CommissionsList'
import Hr from '../../../components/styled/Hr'
import { ApiErrorCode } from '../../../context/Api'
import { PaymentTypes } from '../../../models/tipster.model'
import { apiServiceInst } from '../../../services/Api'
type Props = {
    userId: string
}
const TipsterCommission = (props: Props) => {
    const [commissionDue, setCommissionDue] = useState(0)
    const totalDueQuery = useQuery(
        ['commission/total-due', props.userId],
        () => apiServiceInst.getTipsterCommissionDue(props.userId),
        { onSuccess: (data) => setCommissionDue(data.total) },
    )

    const onPayout = (method: PaymentTypes | null) => {
        Swal.fire({
            text: 'Vuoi saldare le commissioni del tipster?',
            showDenyButton: true,
            showConfirmButton: true,
            showCloseButton: true,
        }).then((res) => {
            if (res.isConfirmed) {
                apiServiceInst
                    .payoutTipster(props.userId, method)
                    .then((res) => {
                        if (res) {
                            let message
                            if (typeof res === 'object') {
                                if (
                                    res.error ==
                                    ApiErrorCode.NO_TIPSTER_PAYPAL_ID
                                ) {
                                    message =
                                        'Il tipster non ha una mail PayPal assegnata, impossibile effettuare il pagamento.'
                                } else {
                                    message =
                                        "C'è stato un problema con il pagamento, verifica che il tipster abbia inserito una mail Paypal corretta."
                                }
                                Swal.fire({
                                    toast: true,
                                    text: message,
                                    position: 'bottom-right',
                                })
                                return
                            }
                        }
                        Swal.fire('Commissioni pagate correttamente.')
                        setCommissionDue(0)
                    })
            }
        })
    }
    return (
        <div className="h-full flex flex-col">
            <div>
                <div className="flex justify-between items-center mb-2">
                    <span className="text-xs font-light uppercase">
                        commissioni dovute
                    </span>
                    <span className="font-bold text-lg">
                        {commissionDue.toFixed(2)}€
                    </span>
                </div>
                <div className="flex gap-2">
                    <Button
                        color="primary"
                        className="w-full"
                        disabled={commissionDue == 0}
                        onClick={() => onPayout('paypal')}
                    >
                        Paga con Paypal
                    </Button>
                    <Button
                        className="w-full"
                        disabled={commissionDue == 0}
                        onClick={() => onPayout('none')}
                    >
                        Segna pagato
                    </Button>
                </div>
            </div>
            <Hr my={2} />
            <ScrollableContent className="flex-1 rounded my-2">
                <CommissionsList tipsterId={props.userId} />
            </ScrollableContent>
        </div>
    )
}

export default TipsterCommission
