import { createContext, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios'
import PackModel, { OfferCode, OfferComposition } from '../models/pack.model'
import { useAuth } from './Auth'
import ForecastModel, { MultiForecastModel } from '../models/forecast.model'
import ForecastDTO from '../models/forecast.dto'
import { PackDTO } from '../models/pack.dto'
import CreateTransactionDto from '../models/create-transaction.dto'
import { EventSourcePolyfill } from 'event-source-polyfill'
import UserRegistrationDataDTO from '../models/user-registration-data.dto'
import MatchModel from '../models/match.model'
import Tipster, {
    PaymentTypes,
    TipsterPaymentsMethods,
} from '../models/tipster.model'
import Swal from 'sweetalert2'
import TipsterMovement from '../models/tipster-movement.model'
import { ManualOutcomeDTO } from '../models/manual-outcome.dto'
import { UserProfileDataDTO } from '../models/user-profile-data.dto'
import endpoints from './endpoints'
import { TransactionModel } from '../models/transaction.model'
import User from '../models/user.model'
import PackTransactionModel from '../models/pack-transaction.model'
import PaginateQuery from '../models/paginate-query.model'

const BASE_URL = process.env.REACT_APP_API_URL

export enum ApiErrorCode {
    NO_TIPSTER_PAYPAL_ID = 'NO_TIPSTER_PAYPAL_ID',
    FORECASTS_LIMIT_EXCEEDED = 'FORECASTS_LIMIT_EXCEEDED',
}

type ApiContextType = {
    loginCheck: () => Promise<boolean>
    getMatches: (from: Date, to: Date) => Promise<MatchModel[]>
    getForecasts: (tipsterId?: string) => Promise<ForecastModel[]>
    getAllSellingForecasts: () => Promise<
        (ForecastModel | MultiForecastModel)[]
    >
    getStoricoForecast: () => Promise<(ForecastModel | MultiForecastModel)[]>
    getMyStoricoForecast: () => Promise<ForecastModel[]>
    getBestStoricoForecast: () => Promise<ForecastModel[]>
    getLastForecast(): Promise<ForecastModel[]>
    createForecast: (data: ForecastDTO) => Promise<ForecastModel | AxiosError>
    publishForecast: (forecastID: string) => Promise<boolean>
    deleteForecast: (forecastID: string) => Promise<boolean>
    getPacksToBuy: () => Promise<PackModel[]>
    getPacksToEdit: () => Promise<PackModel[]>
    getOffers: () => Promise<PackModel[]>
    getOffersForEdit: () => Promise<PackModel[]>
    saveOffersForEdit: (offers: {
        [key in OfferCode]: { active: boolean; composition: OfferComposition }
    }) => Promise<void>
    updatePack: (pack: PackDTO) => Promise<boolean>
    buyPack: (payload: CreateTransactionDto) => Promise<string>
    eventsSSE: (jwt: string) => EventSourcePolyfill
    sendHelpRequest: (subject: string, text: string) => Promise<boolean>
    registerUser: (
        userData: UserRegistrationDataDTO,
    ) => Promise<AxiosResponse | AxiosError>
    login: (username: string, password: string) => Promise<any>
    requestPasswordReset: (email: string) => Promise<boolean>
    newPassword: (password: string, token: string) => Promise<boolean>
    updatePassword: (
        oldPassword: string,
        newPassword: string,
    ) => Promise<boolean>
    getUsers: () => Promise<User[]>
    getUserData: () => Promise<UserProfileDataDTO>
    getUserTransactions: (userId: string) => Promise<TransactionModel[]>
    updateUserData: (data: UserProfileDataDTO) => Promise<boolean>
    giveBC: (amount: number, userId: string) => Promise<boolean>
    getTipsters: () => Promise<Tipster[]>
    getPackPurchases: (
        query: PaginateQuery,
    ) => Promise<{ total: number; data: PackTransactionModel[] }>
    browseTipsters: () => Promise<
        {
            id: string
            username: string
            profilePicture: string
            selling: number
            totalSingle: number
            totalMulti: number
            countSuccessSingle: number
            countSuccessMulti: number
            successSingle: number
            successMulti: number
            total: number
            success: number
        }[]
    >
    getTipsterUsers: (tipsterId: string) => Promise<User[]>
    getTipsterProfileData: (username: string) => Promise<Tipster>
    getTipsterStats: (
        tipsterId: string,
    ) => Promise<{ total: number; selling: number; success: number }>
    getTipsterCommissionDue: (tipsterId?: string) => Promise<number>
    getTipsterLinkBonus: (tipsterId: string) => Promise<string>
    setTipsterLinkBonus: (tipsterId: string, link: string) => Promise<boolean>
    getTipsterPaymentMethods: (
        tipsterId: string,
    ) => Promise<TipsterPaymentsMethods>
    payoutTipster: (
        tipsterId: string,
        method: PaymentTypes | null,
    ) => Promise<any>
    updateTipsterCommission: (
        tipsterId: string,
        commission: number,
    ) => Promise<any>
    buyForecast: (forecastId: string) => Promise<any>
    getMyForecasts: () => Promise<ForecastModel[]>
    getBalance: () => Promise<number>
    getTipsterMovements: (tipsterId: string) => Promise<TipsterMovement[]>
    getUserRevenuesMovements: (userId: string) => Promise<TipsterMovement[]>
    getManualOutcomeMatch: () => Promise<MatchModel[]>
    setManualOutcome: (
        matchID: number,
        outcome: ManualOutcomeDTO,
    ) => Promise<boolean>
    getCharts: (
        period: 'week' | 'month' | 'total',
    ) => Promise<{ username: string; userId: string; points: number }[]>
    uploadProfilePicture: (formData: FormData) => Promise<
        AxiosResponse<{
            message: string
            error: boolean
        }>
    >
    getRevenues: (userId: string) => Promise<number>
}

type Props = {
    children: JSX.Element
}

export const ApiContext = createContext<ApiContextType | null>(null)

export const useApi = (): ApiContextType => {
    const context = useContext(ApiContext)

    if (!context) {
        throw Error('Wrong usage')
    }
    return context
}

export const ApiContextProvider = ({ children }: Props) => {
    // const [instance, setInstance] = useState<AxiosInstance | null>(null)
    const navigate = useNavigate()
    const { getJWT, logout } = useAuth()

    const instanceInit = () => {
        const inst = axios.create({
            baseURL: BASE_URL,
        })

        inst.interceptors.request.use((request: AxiosRequestConfig<any>) => {
            const jwt = localStorage.getItem('jwt')
            if (jwt) {
                if (request.headers) {
                    request.headers.Authorization = 'Bearer ' + jwt
                } else {
                    request.headers = { Authorization: 'Bearer ' + jwt }
                }
            }
            return request
        })

        inst.interceptors.response.use(
            (config: AxiosResponse<any>) => {
                if (config.status == 403) {
                    logout()
                    navigate('/login')
                }
                return config
            },
            (error: any) => {
                if (error.response && error.response.status == 403) {
                    logout()
                    navigate('/login')
                }
                if (error.response?.status == 429) {
                    Swal.fire({
                        text: 'Stai inviando troppe richieste! Attendi un momento e riprova.',
                    })
                }
                return error
            },
        )

        return inst
    }
    const instance = useRef(instanceInit())

    return (
        <ApiContext.Provider
            value={{
                loginCheck: async () => {
                    const res = await instance.current.get('login-check')
                    return res.data
                },
                getMatches: async (
                    from: Date,
                    to: Date,
                ): Promise<MatchModel[]> => {
                    const res = await instance.current.get('/match', {
                        params: {
                            from: from.toISOString(),
                            to: to.toISOString(),
                        },
                    })
                    const data: MatchModel[] = res.data

                    return data
                },

                getForecasts: async (
                    tipsterId?: string,
                ): Promise<ForecastModel[]> => {
                    const res = await instance.current.get('forecast', {
                        params: tipsterId ? { tipsterId } : {},
                    })
                    const data: ForecastModel[] = await res.data

                    return data
                },
                getAllSellingForecasts: async () => {
                    const res = await instance.current.get('forecast/selling')

                    return await res.data
                },
                getStoricoForecast: async () => {
                    const res = await instance.current.get('forecast/storico')
                    const data: ForecastModel[] = await res.data

                    return data
                },
                getMyStoricoForecast: async () => {
                    const res = await instance.current.get(
                        'forecast/user-storico',
                    )
                    const data: ForecastModel[] = await res.data

                    return data
                },

                getBestStoricoForecast: async () => {
                    // const res = await instance.current.get(
                    //     'forecast/browse?from=1&to=10&fromQuota=3&success=true&status=evaluated',
                    // )
                    const res = await instance.current.get(
                        'forecast/win/best-public',
                    )
                    const data: ForecastModel[] = await res.data.data
                    return data
                },

                getLastForecast: async () => {
                    // const res = await instance.current.get(
                    //     'forecast/browse?from=1&to=10&fromQuota=3&success=true&status=evaluated',
                    // )
                    const res = await instance.current.get(
                        'forecast/win/best-public',
                    )
                    const data: ForecastModel[] = await res.data.data
                    return data
                },
                createForecast: async (
                    data: ForecastDTO,
                ): Promise<ForecastModel | AxiosError> => {
                    const res = await instance.current.post('forecast', data)
                    if (res instanceof AxiosError) {
                        Swal.fire(
                            'Errore',
                            'Errore nella creazione pronostico. Verifica che il match non sia già iniziato.',
                        )
                        return res
                    }
                    return res.data as ForecastModel
                },

                publishForecast: async (
                    forecastID: string,
                ): Promise<boolean> => {
                    const url = `forecast/publish/${forecastID}`
                    const res = await instance.current.post(url)
                    return res.data
                },

                deleteForecast: async (
                    forecastID: string,
                ): Promise<boolean> => {
                    const res = await instance.current.post(
                        `forecast/delete/${forecastID}`,
                    )
                    return res.data
                },

                getPacksToBuy: async (): Promise<PackModel[]> => {
                    const res = await instance.current.get('pack')
                    return res.data
                },
                getPacksToEdit: async (): Promise<PackModel[]> => {
                    const res = await instance.current.get('pack/edit')
                    return res.data
                },
                getOffers: async (): Promise<PackModel[]> => {
                    const res = await instance.current.get('pack/offers')
                    return res.data
                },
                getOffersForEdit: async (): Promise<PackModel[]> => {
                    const res = await instance.current.get('pack/offers/edit')
                    return res.data
                },
                saveOffersForEdit: async (offers) => {
                    const res = await instance.current.post(
                        'pack/offers/edit',
                        offers,
                    )
                    return res.data
                },

                updatePack: async (pack: PackDTO): Promise<boolean> => {
                    const res = await instance.current.post(
                        'pack/' + pack.code,
                        pack,
                    )
                    return res.data
                },

                buyPack: async (
                    payload: CreateTransactionDto,
                ): Promise<string> => {
                    const res = await instance.current.post(
                        `pack/buy/${payload.packId}`,
                        payload,
                    )

                    return res.data.url
                },
                sendHelpRequest: async (
                    subject: string,
                    text: string,
                ): Promise<boolean> => {
                    const res = await instance.current.post('help', {
                        subject,
                        text,
                    })
                    return res.data
                },
                eventsSSE: (jwt: string) => {
                    const url = new URL('events', BASE_URL).toString()
                    return new EventSourcePolyfill(url, {
                        headers: { Authorization: `Bearer ${jwt}` },
                    })
                },

                // checkEmailAvailable: async (value: string) => {
                //     const res = await instance.current.get('user/check/email')
                //     return res
                // },
                // checkEmailAvailable: async (value: string) => {
                //     const res = await instance.current.get('user/check/email')
                //     return res
                // },
                registerUser: async (
                    userData: UserRegistrationDataDTO,
                ): Promise<AxiosResponse | AxiosError> => {
                    const res = await instance.current.post(
                        'user/register',
                        userData,
                    )
                    return res
                },

                login: async (
                    username: string,
                    password: string,
                ): Promise<any> => {
                    const res = await instance.current.post('auth/login', {
                        username,
                        password,
                    })
                    return res.data as { access_token: string }
                },
                requestPasswordReset: async (
                    email: string,
                ): Promise<boolean> => {
                    const res = await instance.current.post(
                        'auth/password-reset',
                        {
                            email,
                        },
                    )
                    return res.data
                },
                newPassword: async (
                    password: string,
                    token: string,
                ): Promise<boolean> => {
                    const res = await instance.current.post(
                        'auth/new-password',
                        {
                            password,
                            token,
                        },
                    )
                    return res.data
                },
                updatePassword: async (
                    oldPassword: string,
                    newPassword: string,
                ): Promise<boolean> => {
                    const res = await instance.current.post(
                        'auth/change-password',
                        {
                            oldPassword,
                            newPassword,
                        },
                    )
                    return res.data
                },
                getUsers: async (): Promise<User[]> => {
                    const res = await instance.current.get('user')
                    return res.data
                },
                getUserData: async (): Promise<UserProfileDataDTO> => {
                    const res = await instance.current.get('user/data')
                    return res.data
                },
                getUserTransactions: async (
                    userId: string,
                ): Promise<TransactionModel[]> => {
                    const res = await instance.current.get(
                        endpoints.userTransactions(userId),
                    )
                    return res.data
                },
                updateUserData: async (
                    data: UserProfileDataDTO,
                ): Promise<boolean> => {
                    const res = await instance.current.post('user/data', data)
                    return res.data
                },
                getTipsters: async (): Promise<Tipster[]> => {
                    const res = await instance.current.get('tipster')
                    return res.data
                },
                getTipsterProfileData: async (username) => {
                    const res = await instance.current.get(
                        'tipster/profile/' + username,
                    )
                    return res.data
                },
                getTipsterStats: async (tipsterId) => {
                    const res = await instance.current.get(
                        `tipster/${tipsterId}/stats`,
                    )
                    return res.data
                },
                getTipsterUsers: async (tipsterId): Promise<User[]> => {
                    const res = await instance.current.get(
                        `tipster/${tipsterId}/users`,
                    )
                    return res.data
                },
                browseTipsters: async () => {
                    const res = await instance.current.get(`tipster/browse`)
                    return res.data
                },
                getTipsterCommissionDue: async (
                    tipsterId?,
                ): Promise<number> => {
                    const url = tipsterId
                        ? `user/${tipsterId}/revenues`
                        : 'user/commission-due'
                    const res = await instance.current.get(url)
                    return res.data
                },
                getTipsterPaymentMethods: async (
                    tipsterId: string,
                ): Promise<TipsterPaymentsMethods> => {
                    const url = `tipster/${tipsterId}/payment-methods`
                    const res =
                        await instance.current.get<TipsterPaymentsMethods>(url)
                    return res.data
                },
                payoutTipster: async (
                    tipsterId,
                    method: PaymentTypes | null,
                ) => {
                    const res = await instance.current.post(
                        `user/${tipsterId}/payout`,
                        { method },
                    )
                    return res.data
                },
                updateTipsterCommission: async (tipsterId, commission) => {
                    const res = await instance.current.post(
                        `user/${tipsterId}/commission`,
                        { commission },
                    )
                    return res.data
                },
                buyForecast: async (forecastId: string): Promise<any> => {
                    const res = await instance.current.post(
                        `forecast/buy/${forecastId}`,
                    )
                    return res.data
                },

                getMyForecasts: async (): Promise<ForecastModel[]> => {
                    const res = await instance.current.get('forecast/purchased')
                    return res.data
                },

                getBalance: async (): Promise<number> => {
                    const res = await instance.current.get('user/balance')
                    return res.data
                },
                getTipsterMovements: async (
                    tipsterId,
                ): Promise<TipsterMovement[]> => {
                    const res = await instance.current.get(
                        `tipster/${tipsterId}/movements`,
                    )
                    return res.data
                },
                getUserRevenuesMovements: async (
                    tipsterId,
                ): Promise<TipsterMovement[]> => {
                    const res = await instance.current.get(
                        `user/${tipsterId}/revenues-movements`,
                    )
                    return res.data
                },
                getManualOutcomeMatch: async (): Promise<MatchModel[]> => {
                    const res = await instance.current.get(
                        'match/manual-outcome',
                    )
                    return res.data
                },
                setManualOutcome: async (
                    matchID: number,
                    outcome: ManualOutcomeDTO,
                ): Promise<boolean> => {
                    const res = await instance.current.post(
                        `match/${matchID}/outcome`,
                        outcome,
                    )
                    return res.data
                },
                giveBC: async (amount, userId) => {
                    const res = await instance.current.post(
                        endpoints.userGiveBC,
                        { amount, userId },
                    )
                    return res.data
                },
                getCharts: async (period) => {
                    const res = await instance.current.get(
                        'user/charts?period=' + period,
                    )
                    return res.data
                },
                uploadProfilePicture: async (formData: FormData) => {
                    return instance.current.post<{
                        message: string
                        error: boolean
                    }>('user/upload-profile-picture', formData)
                },
                getTipsterLinkBonus: async (tipsterId) => {
                    const result = await instance.current.get(
                        `tipster/${tipsterId}/link-bonus`,
                    )

                    return result.data
                },
                setTipsterLinkBonus: async (tipsterId, linkBonus) => {
                    return instance.current.post(
                        `tipster/${tipsterId}/link-bonus`,
                        { linkBonus },
                    )
                },
                getRevenues: async (userId: string) => {
                    const res = await instance.current.get(
                        `user/${userId}/revenues`,
                    )
                    return res.data
                },
                getPackPurchases: async (
                    query: PaginateQuery,
                ): Promise<{ total: number; data: PackTransactionModel[] }> => {
                    const res = await instance.current.get(
                        'transaction/pack-purchases',
                        { params: query },
                    )
                    return res.data
                },
            }}
        >
            {children}
        </ApiContext.Provider>
    )
}
