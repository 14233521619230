import StepsNav from './StepsNav'

const CompleteStep = (props: { onComplete: () => void }) => {
    return (
        <div className="flex flex-col h-full">
            <div className="grow">
                <div className="pb-16">
                    <p className="text-primary font-bold text-xl">
                        Il tuo profilo è pronto!
                    </p>
                    <p className="text-sm font-bold">
                        Completa la registrazione per iniziare a scoprire i
                        pronostici!
                    </p>
                </div>
                <button className="animate__heartBeat bg-primary px-4 text-secondary font-bold rounded text-2xl w-full py-3">
                    Unisciti a noi!
                </button>
            </div>
            <StepsNav
                // onNext={async () => await trigger('password')}
                nextEnabled={false}
            />
        </div>
    )
}

export default CompleteStep
