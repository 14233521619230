import { useState } from 'react'
import PaginatedList from '../../../components/Pagination/PaginatedList'
import SectionNav, { SectionNavItem } from '../../../components/SectionNav'
import { useAppState } from '../../../context/AppState'
import { AffiliateListData } from '../../../models/affiliate-list-data.model'
import PaginateQuery from '../../../models/paginate-query.model'
import { useBrowseAffiliatesQuery } from '../../../queries'
import AffiliateCommission from './AffiliateCommission'
import AffiliateDetailsView from './AffiliateDetailsView'
import ListElem from './ListElem'

type TipsterProp = {
    data: AffiliateListData
}

const TipsterMenu = (props: TipsterProp) => {
    const [section, setSection] = useState('dettagli')
    return (
        <div className="h-full flex flex-col">
            <div className="mb-4">
                <SectionNav
                    elems={[
                        { id: 'dettagli', title: 'Dettagli' },
                        { id: 'guadagni', title: 'Guadagni' },
                        // { id: 'utenti', title: 'Utenti' },
                        // { id: 'pronostici', title: 'Pronostici' },
                    ]}
                    onSelect={(section: SectionNavItem) => {
                        setSection(section.id)
                    }}
                />
            </div>
            <div className="grow">
                {section == 'dettagli' && (
                    <AffiliateDetailsView data={props.data} />
                )}
                {section == 'guadagni' && (
                    <AffiliateCommission userId={props.data.id} />
                )}
                {/*
                {section == 'utenti' && <UsersList tipsterId={props.data.id} />}
                {section == 'pronostici' && (
                    <ForecastList readOnly tipsterId={props.data.id} />
                )} */}
            </div>
        </div>
    )
}
const TipsterElem = (props: TipsterProp & { onClick: () => void }) => (
    <ListElem
        name={props.data.username}
        onClick={props.onClick}
        profilePicture={props.data.profilePicture ?? undefined}
        showProfilePicture
    >
        <div className="text-xxs flex gap-2 text-gray-300">
            {/* <div className="flex gap-1">
                <span className="font-light">%S</span>
                <span className="font-bold">
                    {props.data.singleSuccessRate.toFixed(2)}
                </span>
            </div>
            <div className="flex gap-1">
                <span className="font-light">%M</span>
                <span className="font-bold">
                    {props.data.multiSuccessRate.toFixed(2)}
                </span>
            </div>
            <div className="flex gap-1">
                <span className="font-light">
                    <FontAwesomeIcon icon={faUsers} />
                </span>
                <span className="font-bold">{props.data.users || 0}</span>
            </div> */}
        </div>
    </ListElem>
)
const AffiliatiList = () => {
    const [perPage, setPerPage] = useState(15)

    const [query, setQuery] = useState<PaginateQuery>({
        from: 0,
        to: perPage,
    })
    const affiliatesQuery = useBrowseAffiliatesQuery(query)
    const onPageChange = async (page: number) => {
        setQuery({
            from: page * perPage,
            to: (page + 1) * perPage,
        })
    }

    const onTextSearch = async (text: string) => {
        setQuery({
            text,
        })
    }
    const { toggleModal } = useAppState()
    return (
        <div className="h-full">
            test
            <PaginatedList<AffiliateListData>
                items={affiliatesQuery.data?.data ?? []}
                textSearchPlaceholder="Cerca per username"
                itemBuilder={(data: AffiliateListData) => (
                    <TipsterElem
                        data={data}
                        key={data.id}
                        onClick={() =>
                            toggleModal(
                                true,
                                data.username,
                                <TipsterMenu data={data} />,
                            )
                        }
                    />
                )}
                perPage={perPage}
                total={affiliatesQuery.data?.total ?? 0}
                onPageChange={onPageChange}
                onTextSearch={onTextSearch}
            />
        </div>
    )
}

export default AffiliatiList
