import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Button from '../../../components/Button'
import routes from '../../../routing/routes'
import BestStorico from '../BestStorico'
import BestPublicSellingForecastsWidget from '../forecasts/BestPublicSellingForecasts'

const Title = ({ text, className }: { text: string; className?: string }) => (
    <div
        className={classNames(
            'text-primary h-auto text-center py-1 border-primary font-bold border-b mb-2',
            className,
        )}
    >
        {text}
    </div>
)
const HomeDashboard = () => {
    const logoImageUrl =
        'https://ytip-assets.s3.eu-central-1.amazonaws.com/Logo+YT+Feel+Bet.png'

    const mobileImageUrl =
        'https://ytip-assets.s3.eu-central-1.amazonaws.com/TipsterGameHome.jpeg'

    const comingSoonUrl =
        'https://ytip-assets.s3.eu-central-1.amazonaws.com/BudgetToolHome.jpeg'

    return (
        <div className="h-full w-full bg-secondary/90">
            <div className="flex flex-col pt-2 pb-8 px-4 lg:w-[980px] w-full h-full lg:mx-auto">
                <div className="p-2 flex justify-between items-center mb-4">
                    <img
                        src={logoImageUrl}
                        className="w-[150px] md:w-auto md:h-12"
                    />
                    <Link to={routes.login}>
                        <Button className="font-bold" color="success">
                            Login
                        </Button>
                    </Link>
                </div>
                <div className="grid md:grid-rows-2 md:grid-cols-5 gap-x-4 gap-y-4 overflow-scroll">
                    <div className="md:overflow-scroll md:pb-4 md:order-1 md:col-span-3">
                        <Title text="Top Quote" />
                        <BestPublicSellingForecastsWidget />
                    </div>
                    <div className="w-full h-full md:overflow-scroll md:pb-4 md:order-3  md:col-span-3">
                        <Title text="Ultime Win" />
                        <BestStorico widget></BestStorico>
                    </div>
                    <div className="md:order-2 flex flex-col gap-2   md:col-span-2">
                        <Title className="md:order-2" text="In Arrivo..." />

                        <div className="md:order-4">
                            <img
                                className="h-full w-full rounded-lg shadow overflow-hidden object-cover"
                                src={comingSoonUrl}
                            />
                        </div>
                        <div className="md:order-5">
                            <img
                                className="rounded-lg shadow overflow-hidden w-full"
                                src={mobileImageUrl}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeDashboard
