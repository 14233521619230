import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, useState } from 'react'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Modal from '../../../components/Modal'
import ModalFooter from '../../../components/ModalFooter'
import { useApi } from '../../../context/Api'
import { useAppState } from '../../../context/AppState'
import { useAuth } from '../../../context/Auth'
import CreateTransactionDto from '../../../models/create-transaction.dto'
import PackModel from '../../../models/pack.model'
import paypal from '../../../assets/PayPal.png'
import stripe from '../../../assets/stripe.svg'
import Swal from 'sweetalert2'

type Props = {
    pack: PackModel
}

const BuyPack = ({ pack }: Props) => {
    const { eventsSSE, buyPack } = useApi()

    const [formData, setFormData] = useState<{
        card: string
        expire: string
        ccv: string
    }>({
        card: '',
        expire: '',
        ccv: '',
    })

    const { balance, setBalance } = useAppState()

    const { getJWT } = useAuth()
    const { switchLoading, toggleModal } = useAppState()

    const onBuy = async (transactionDTO: CreateTransactionDto) => {
        switchLoading(true)
        const sse = eventsSSE(getJWT()!)
        sse.onmessage = (e) => {
            const data = JSON.parse(e.data)
            if (data.type == 'PAYMENT' && data.status == 'completed') {
                setBalance(balance + pack.betcoins)
                switchLoading(false)
                Swal.fire({
                    title: 'Acquisto completato!',
                    text: "Hai completato l'acquisto del pack!",
                }).then((res) => {
                    toggleModal(false)
                })
                sse.close()

                // increase betcoins
            }
        }
        sse.onerror = (e) => {
            switchLoading(false)
            sse.close()
            // error happend, inform the user
        }
        const url = await buyPack(transactionDTO)

        window.location.href = url
        switchLoading(true)
    }
    return (
        <div className="flex flex-col h-full">
            <div className="grow flex flex-col items-center p-4">
                <div
                    className="rounded bg-yellow-500 border border-yellow-400 
                    text-greyBg font-light inline-block py-2 px-4 mx-auto mb-4"
                >
                    Ricarica{' '}
                    <span className="font-bold">{pack.betcoins} crediti</span>{' '}
                    al prezzo speciale di{' '}
                    <span className="font-bold">{pack.price} €</span>
                </div>
                <div>
                    <Button
                        className="text-xl bg-[#6772e5] w-full mb-4 gap-2 flex items-center"
                        color="primary"
                        onClick={() =>
                            onBuy({
                                packId: pack.id,
                                amount: pack.price,
                                description: `buy pack ${pack.name}`,
                                gateway: 'STRIPE',
                            })
                        }
                    >
                        <img
                            className="w-12 h-12 object-contain"
                            src={stripe}
                        />
                        Paga con carta
                    </Button>

                    <Button
                        className="text-xl bg-white text-black w-full flex items-center gap-2"
                        color="primary"
                        onClick={() =>
                            onBuy({
                                packId: pack.id,
                                amount: pack.price,
                                description: `buy pack ${pack.name}`,
                                gateway: 'PAYPAL',
                            })
                        }
                    >
                        <img
                            className="w-12 h-12 object-contain"
                            src={paypal}
                        />
                        Paga con Paypal
                    </Button>
                </div>
            </div>
            <Button onClick={() => toggleModal(false)}>
                <FontAwesomeIcon icon={faTimes} fixedWidth={true} /> annulla
            </Button>
        </div>
    )
}

export default BuyPack
