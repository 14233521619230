import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ForecastModel, {
    MultiForecastModel,
} from '../../../models/forecast.model'
import MatchModel from '../../../models/match.model'
import GridLayout, { Elem } from '../../GridLayout'
import { MatchDateTime, TeamTag, TipsterName } from '../common'
import { forecastToString } from '../utilities'

export const MatchStorico = ({ match }: { match: MatchModel }) => (
    <div>
        {match.date && <MatchDateTime matchDate={match.date} />}
        <GridLayout
            flow={{ mob: 'row', lg: 'col' }}
            rows={{ mob: 2, lg: 1 }}
            cols={{ mob: 4, lg: 7 }}
            padding={0}
            gap={0}
            elements={[
                {
                    grid: { fullWidth: true, cols: 3, lg: { cols: 2 } },
                    content: (
                        <TeamTag className="text-left">
                            {match.homeTeam}
                        </TeamTag>
                    ),
                },
                {
                    grid: { cols: 1 },
                    content: (
                        <div className="text-left leading-tight font-bold py-[1px] text-[11px] sm:text-xs">
                            {match.homeTeamScore}
                        </div>
                    ),
                },
                {
                    grid: { cols: 1, hidden: true, lg: { hidden: false } },
                    content: ' - ',
                },
                {
                    grid: { fullWidth: true, cols: 3, lg: { cols: 2 } },
                    content: (
                        <TeamTag className="text-left">
                            {match.awayTeam}
                        </TeamTag>
                    ),
                },
                {
                    grid: { cols: 1 },
                    content: (
                        <div className="text-left leading-tight font-bold py-[1px] text-[11px] sm:text-xs">
                            {match.awayTeamScore}
                        </div>
                    ),
                },
            ]}
        ></GridLayout>
    </div>
)

export const commonElements = (
    forecast: ForecastModel,
    single = true,
): Elem[] => [
    {
        grid: {
            fullWidth: true,
            cols: 3,
            md: { cols: 6 },
        },
        content: <MatchStorico match={forecast.match} />,
    },
    {
        grid: { cols: 1 },
        content: (
            <>
                {forecast.match.status == 'Postponed' && (
                    <div className="px-1 shadow text-black font-bold text-xxxs bg-white rounded">
                        POST
                    </div>
                )}
            </>
        ),
    },
    {
        grid: { cols: 2 },
        content: forecastToString(forecast),
    },

    {
        grid: { cols: 1 },
        content: single ? (
            ''
        ) : (
            <div className="text-center">
                {forecast.success === true ? (
                    <FontAwesomeIcon icon={faCheck} className="text-success" />
                ) : forecast.success === false ? (
                    <FontAwesomeIcon icon={faXmark} className="text-danger" />
                ) : (
                    ''
                )}
            </div>
        ),
    },
]

export const ForecastHeader = ({
    forecast,
}: {
    forecast: ForecastModel | MultiForecastModel
}) => (
    <div className="-mt-1 mb-1 -ml-2 flex justify-between">
        <TipsterName creator={forecast.creator} />
        {forecast.quotation !== undefined && (
            <div className="text-xs rounded-full px-2 top-0 right-1 border border-secondary-200 inline-flex items-center gap-1">
                <span className="font-thin text-xxs">quota</span>
                <span className="font-bold">
                    {forecast.quotation.toFixed(2)}
                </span>
            </div>
        )}
    </div>
)
