import styled from 'styled-components'

const TextArea = styled.textarea.attrs((props) => {
    return {
        className:
            props.className +
            ' p-4 bg-black/[.15] border h-10 rounded shadow border-1 border-primary/[.15] ' +
            'text-sm disabled:bg-black/[.05] disabled:text-gray-500',
    }
})``

export default TextArea
