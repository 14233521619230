import { useAuth } from '../context/Auth'

type Props = {
    children: JSX.Element
}

const AffiliateGuardedRoute = (props: Props) => {
    const { getUser } = useAuth()
    if (getUser()?.affiliate) {
        return props.children
    } else {
        return <div></div>
    }
}

export default AffiliateGuardedRoute
