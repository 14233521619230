import classNames from 'classnames'
import { add, format, startOfDay } from 'date-fns'
import { it } from 'date-fns/locale'
import { useState } from 'react'

const DAYS_RANGE = 5
type Props = {
    onSelect: (value: undefined | Date) => void
}
const ForecastListDateFilter = (props: Props) => {
    let elements = []
    for (let i = 0; i < DAYS_RANGE; i++) {
        elements.push(startOfDay(add(new Date(), { days: i })))
    }
    const elementClass = (i: number) =>
        classNames(
            'text-xxs lg:text-[0.8rem] w-9 lg:w-12 cursor-pointer text-center leading-tight uppercase',
            {
                'text-yellow-500 underline': selected == i,
            },
        )
    const [selected, setSelected] = useState(-1)
    return (
        <div className="text-white flex gap-2 lg:gap-4 items-center justify-center py-2">
            <div
                className={elementClass(-1)}
                onClick={() => {
                    setSelected(-1)
                    props.onSelect(undefined)
                }}
            >
                ALL DATES
            </div>
            {elements.map((e, i) => (
                <div
                    className={elementClass(i)}
                    onClick={() => {
                        setSelected(i)
                        props.onSelect(new Date(e.toISOString()))
                    }}
                >
                    {format(e, 'eee', {
                        locale: it,
                    })}
                    <br />
                    {format(e, 'dd LLL', {
                        locale: it,
                    })}
                </div>
            ))}
        </div>
    )
}

export default ForecastListDateFilter
