import { useEffect, useState } from 'react'
import { useAuth } from '../../../context/Auth'
import { PageTitle } from '../../../components/typography'
import UsersList from './UsersList'
import TipstersList from './TipsterList'
import SectionNav from '../../../components/SectionNav'
import { apiServiceInst } from '../../../services/Api'
import { useAppState } from '../../../context/AppState'
import AffiliatiList from './AffiliatiList'

enum Section {
    users = 'users',
    tipster = 'tipsters',
    affiliati = 'affiliati',
}
const AdminUtentiPage = () => {
    const [currentSection, setCurrentSection] = useState<Section>(Section.users)
    const [totals, setTotals] = useState({
        users: 0,
        tipsters: 0,
        affiliates: 0,
    })
    const { switchLoading } = useAppState()
    useEffect(() => {
        switchLoading(true)
        Promise.all([
            apiServiceInst.browseUsers({ from: 0, to: 1 }),
            apiServiceInst.browseTipsters({ from: 0, to: 1 }),
            apiServiceInst.browseAffiliates({ from: 0, to: 1 }),
        ]).then((res) => {
            setTotals({
                users: res[0].total,
                tipsters: res[1].total,
                affiliates: res[2].total,
            })
            switchLoading(false)
        })
    }, [])
    return (
        <div className="flex flex-col h-full px-1">
            <div className="mb-2">
                <SectionNav
                    fullWidthItems
                    elems={[
                        { id: 'users', title: `Users (${totals.users})` },
                        {
                            id: 'tipsters',
                            title: `Tipsters (${totals.tipsters})`,
                        },
                        {
                            id: 'affiliati',
                            title: `Affiliati (${totals.affiliates})`,
                        },
                    ]}
                    onSelect={(item) => setCurrentSection(item.id as Section)}
                />
            </div>
            {/* <PageTitle>{Section.users}</PageTitle> */}
            {currentSection == Section.users && <UsersList />}
            {currentSection == Section.tipster && <TipstersList />}
            {currentSection == Section.affiliati && <AffiliatiList />}
        </div>
    )
}

export default AdminUtentiPage
