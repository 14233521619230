import {
    faBoltLightning,
    faClipboardList,
    faClock,
    faRankingStar,
    faTachometerAlt,
    faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { NavItem } from '../../components/Nav'

const userNavItems: NavItem[] = [
    {
        text: 'Tipsters',
        icon: faUsers,
        to: 'tipsters',
        golden: true,
    },
    {
        text: 'Pronostici',
        icon: faBoltLightning,
        to: 'pronostici',
        golden: true,
    },
    {
        text: 'Sbloccati',
        icon: faClipboardList,
        to: 'my-pronostici',
    },
    {
        text: 'Mio Storico',
        icon: faClock,
        to: 'storico',
    },
    {
        text: 'Classifiche',
        icon: faRankingStar,
        to: 'charts',
    },
    {
        text: 'Ricarica BC',
        icon: faTachometerAlt,
        to: 'dashboard',
        golden: true,
    },
]

export default userNavItems
