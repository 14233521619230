import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ForecastModel, {
    ForecastCommissionModel,
} from '../../models/forecast.model'
import { commonElements } from '../Forecast/storico/common'
import { esitoGridContent } from '../Forecast/utilities'
import GridLayout from '../GridLayout'
import { faEye } from '@fortawesome/free-solid-svg-icons'

type Props = {
    forecast: ForecastCommissionModel
}

const SingleForecast = (forecast: ForecastModel) => (
    <GridLayout
        padding={0}
        gap={0}
        rows={{ mob: 1, lg: 1 }}
        elements={commonElements(forecast, false)}
    />
)

const ForecastCommissions = ({ forecast }: Props) => {
    return (
        <div className="relative align-items-center px-1 py-2 text-white bg-secondary-400 rounded-xl text-xs font-light select-none">
            {forecast.multi && (
                <div className="absolute -top-1 left-0 right-0 flex justify-center items-start">
                    <span className="bg-gray-900 rounded-md px-1 text-white text-[7px] leading-3 uppercase">
                        multipla
                    </span>
                </div>
            )}
            <div className="flex items-end justify-end w-full px-2 pb-2 gap-2">
                <div className="bg-secondary-200 font-bold px-1 rounded-md flex items-center gap-1">
                    <FontAwesomeIcon icon={faEye} className="text-xxs" />{' '}
                    {forecast.visuals}
                </div>
                <div className="bg-success font-bold px-1 rounded-md text-black">
                    {forecast.commissionsGenerated.toFixed(2)} €
                </div>
            </div>
            <div className="flex items-center">
                <div className="flex-1 px-2">
                    <div className="w-full divide-y divide-dashed -my-2 divide-gray-600">
                        {forecast.multi &&
                            forecast.forecasts!.map((f, i) => (
                                <div key={f.id} className="py-2">
                                    {SingleForecast(f)}
                                </div>
                            ))}
                        {!forecast.multi && (
                            <div key={forecast.id} className="py-2">
                                {SingleForecast(forecast)}
                            </div>
                        )}
                    </div>
                </div>
                {esitoGridContent(forecast.success)}
            </div>
        </div>
    )
}

export default ForecastCommissions
