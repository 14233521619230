import { ChangeEvent, useEffect, useState } from 'react'
import { GoalNoGoalData } from '../../../models/forecast-composition.model'
import ToggleSwitch from '../../ToggleSwitch'

type Props = {
    onChange: (data: GoalNoGoalData) => void
}

const GoalNoGoalForm = (props: Props) => {
    const goalID = 'goal-' + Math.random()
    const noGoalID = 'nogoal-' + Math.random()

    const [data, setData] = useState<GoalNoGoalData>(true)
    const onCheck = (
        e: ChangeEvent<HTMLInputElement>,
        goal: GoalNoGoalData,
    ) => {
        e.target.checked && setData(goal)
    }
    useEffect(() => props.onChange(data), [data])
    return (
        <div className="flex justify-around">
            <ToggleSwitch
                id={goalID}
                key="goal"
                label="Goal"
                checked={data == true}
                onChange={(e) => onCheck(e, true)}
            />
            <ToggleSwitch
                id={noGoalID}
                key="nogoal"
                label="No Goal"
                checked={data == false}
                onChange={(e) => onCheck(e, false)}
            />
        </div>
    )
}

export default GoalNoGoalForm
