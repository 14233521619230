const routes = {
    home: '/home',
    login: '/home/login',
    register: '/home/register',
    registerTipster: '/home/register-tipster',
    registerAffiliate: '/home/register-affiliate',
    passwordReset: '/home/password-reset',
    newPassword: '/home/new-password',

    admin: '/admin',
    adminDashboard: '/admin/dashboard',
    adminDashboardPackPurchases: '/admin/dashboard/pack-purchases',
    adminDashboardEditPacks: '/admin/dashboard/edit-packs',
    adminDashboardManual: '/admin/dashboard/manual',
    adminDashboardTipsters: '/admin/dashboard/tipsters',
    adminPronostici: '/admin/pronostici',
    adminStorico: '/admin/storico',
    adminCharts: '/admin/charts',
    adminUtenti: '/admin/utenti',
    adminSelling: '/admin/selling',

    affiliate: '/affiliate',
    affiliatePronostici: '/affiliate/pronostici',
    affiliateStorico: '/affiliate/storico',
    affiliateCharts: '/affiliate/charts',
    affiliateUtenti: '/affiliate/utenti',
    affiliateProfile: '/affiliate/profile',
    affiliateProfileHome: '/affiliate/profile/home',
    affiliateProfileUpdate: '/affiliate/profile/update',
    affiliateProfileUploadProfilePicture:
        '/affiliate/profile/upload-profile-picture',
    affiliateChangePassword: '/affiliate/profile/change-password',
    affiliateHelp: '/affiliate/help',
    affiliateMovimenti: '/affiliate/movimenti',

    tipster: '/tipster',
    tipsterDashboard: '/tipster/dashboard',
    tipsterPronostici: '/tipster/pronostici',
    tipsterStorico: '/tipster/storico',
    tipsterUtenti: '/tipster/utenti',
    tipsterClassifiche: '/tipster/classifiche',
    tipsterMovimenti: '/tipster/movimenti',
    tipsterProfile: '/tipster/profile',
    tipsterProfileHome: '/tipster/profile/home',
    tipsterProfileUpdate: '/tipster/profile/update',
    tipsterProfileUploadProfilePicture:
        '/tipster/profile/upload-profile-picture',
    tipsterChangePassword: '/tipster/profile/change-password',
    tipsterHelp: '/tipster/help',
    tipsterCharts: '/tipster/tipsters',

    app: '/app',
    appDashboard: '/app/dashboard',
    appDashboard2: '/app/dashboard2',
    appPronostici: '/app/pronostici',
    appMyPronostici: '/app/my-pronostici',
    appStorico: '/app/storico',
    appCharts: '/app/charts',
    userProfile: '/app/profile',
    userProfileHome: '/app/profile/home',
    userChangePassword: '/app/profile/change-password',
    userProfileUpdate: '/app/profile/update',
    userProfileUploadProfilePicture: '/app/profile/upload-profile-picture',
    userTipsters: '/app/tipsters',
    userHelp: '/app/help',
    profile: 'profile',
    relativeHome: 'home',
    changePassword: 'change-password',
}

export default routes
