import ForecastModel from '../../../models/forecast.model'
import MatchModel from '../../../models/match.model'
import GridLayout, { Elem, ElemGrid } from '../../GridLayout'
import { MatchDateTime, TeamTag } from '../common'

export const teamGrid: ElemGrid = { cols: 2, rows: 1 }

export const singleForecastElements = (forecast: ForecastModel): Elem[] => [
    {
        grid: teamGrid,
        content: <TeamTag>{forecast.match.homeTeam}</TeamTag>,
    },
    {
        grid: teamGrid,
        content: <TeamTag>{forecast.match.awayTeam}</TeamTag>,
    },
]

export const MatchGrid = (props: { match: MatchModel }) => (
    <div className="flex-1 flex flex-col items-center py-1 w-full">
        <MatchDateTime matchDate={props.match.date} />
        <GridLayout
            rows={{ mob: 2, lg: 1 }}
            className="flex-1"
            padding={0}
            gap={0}
            elements={[
                {
                    grid: teamGrid,
                    content: <TeamTag>{props.match.homeTeam}</TeamTag>,
                },
                {
                    grid: { hidden: true, lg: { cols: 1, rows: 1 } },
                    content: '-',
                },
                {
                    grid: teamGrid,
                    content: <TeamTag>{props.match.awayTeam}</TeamTag>,
                },
            ]}
        ></GridLayout>
    </div>
)
export const PriceTag = (props: { price: number }) => (
    <div className="flex items-center gap-1">
        <span className="font-bold">{props.price}</span>{' '}
        <span className="font-thin text-xxs">BC</span>
    </div>
)

export const MostraButton = (props: { onClick: (e: any) => void }) => {
    return (
        <button
            className="ml-2 my-2 p-1 uppercase text-xxs rounded shadow-lg font-bold bg-primary self-center w-auto break-words text-center"
            onClick={props.onClick}
        >
            Mostra
            <br />
            pronostico
        </button>
    )
}

export const Quota = (props: { value: number }) => {
    return (
        <div className="text-center leading-tight">
            <div className="text-xxxs font-thin uppercase">Quota</div>
            <div className="font-bold text-lg text-primary">
                {props.value?.toFixed(2)}
            </div>
        </div>
    )
}
