import classNames from 'classnames'
import styled from 'styled-components'

const Hr = ({ className, my }: { className?: string; my?: number }) => (
    <hr
        className={classNames('border-white/[.05]', className, {
            [`my-${my}`]: my !== null,
            'my-4': my == null,
        })}
    />
)

styled.hr.attrs(() => {
    return {
        className: `my-4 border-white/[.05]`,
    }
})``

export default Hr
