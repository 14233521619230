import { ChangeEvent, useEffect, useState } from 'react'
import {
    Combo,
    ForecastComposition,
    Parziali,
    Team,
    Tipo,
} from '../../models/forecast-composition.model'
import Button from '../Button'
import Input from '../Input'
import ToggleSwitch from '../ToggleSwitch'
import {
    comboConstraints,
    comboParzialiConstraints,
    comboTeamConstraints,
    ConstraintConfiguration,
    PARZIALI,
    parzialiConstraints,
    teamOptionConstraints,
    TEAMS,
    TYPES,
} from './constraints'
import EsattoForm from './forms/EsattoForm'
import EsitoForm from './forms/EsitoForm'
import GoalNoGoalForm from './forms/GoalNoGoalForm'
import HandicapForm from './forms/HandicapForm'
import MultigoalForm from './forms/MultigoalForm'
import ToggleSwitchList from '../ToggleSwitchList'
import UnderOverForm from './forms/UnderOverForm'
import Swal from 'sweetalert2'

type Props = {
    onUpdate: (data: {
        composition: ForecastComposition
        quotation: string
        stake: string
        hideMatch: boolean
        hideComposition: boolean
    }) => void
}

type Constraints = {
    team: ConstraintConfiguration<Team>
    parziali: ConstraintConfiguration<Parziali>
    combo: ConstraintConfiguration<Tipo>
    comboParziali: ConstraintConfiguration<Parziali>
    comboTeam: ConstraintConfiguration<Team>
}

const TipoForm = ({
    tipo,
    onChange,
}: {
    tipo: Tipo | null
    onChange: (data: any) => void
}) => {
    switch (tipo) {
        case 'esatto':
            return <EsattoForm key={tipo} onChange={onChange} />
        case 'esito':
            return <EsitoForm key={tipo} onChange={onChange} />
        case 'goal/no_goal':
            return <GoalNoGoalForm key={tipo} onChange={onChange} />
        case 'handicap':
            return <HandicapForm key={tipo} onChange={onChange} />
        case 'under/over':
            return <UnderOverForm key={tipo} onChange={onChange} />
        case 'multigoal':
            return <MultigoalForm onChange={onChange} />
        case null:
        default:
            return <div></div>
    }
}

const Pronostico = (props: Props) => {
    const [currentTipo, setCurrentTipo] = useState<Tipo>('esatto')
    const [currentComboTipo, setCurrentComboTipo] = useState<Tipo>('esatto')
    const [composition, setComposition] = useState<ForecastComposition>({
        parziali: 'fine_partita',
        team: 'nd',
        tipo: TYPES[0],
        tipoData: null,
        combo: null,
    })

    const [constraints, setContraints] = useState<Constraints>({
        team: teamOptionConstraints(composition),
        parziali: parzialiConstraints(composition),
        combo: comboConstraints(composition),
        comboParziali: { available: [] },
        comboTeam: { default: 'nd', available: [] },
    })

    const [comboCache, setComboCache] = useState<Combo>({
        parziali: 'fine_partita',
        team: 'nd',
        tipo: TYPES[0],
        tipoData: null,
    })
    const [quotation, setQuotation] = useState('1')

    const [hideMatch, setHideMatch] = useState(false)
    const [hideComposition, setHideComposition] = useState(true)

    const [stake, setStake] = useState('')

    useEffect(() => {
        props.onUpdate({
            composition,
            quotation,
            stake,
            hideMatch,
            hideComposition,
        })
    }, [composition, quotation, stake, hideMatch, hideComposition])

    const updateTipoData = (tipo: Tipo, data: any) => {
        const newComposition = { ...composition, tipo, tipoData: data }
        setComposition(newComposition)
        updateContraints(newComposition)
    }

    const updateComboData = (comboTipo: Tipo, data: any) => {
        const newComposition = {
            ...composition,
            combo: composition.combo && {
                ...composition.combo,
                tipo: comboTipo,
                tipoData: data,
            },
        }
        setComposition(newComposition)
        composition.combo && setComboCache(composition.combo)
        updateForecastComposition(newComposition)
    }

    const updateContraints = (
        composition: ForecastComposition,
    ): Constraints => {
        let newConstraints = { ...constraints }

        newConstraints.combo = comboConstraints(composition)
        if (composition.combo) {
            if (newConstraints.combo.available.length != 0) {
                newConstraints.comboParziali =
                    comboParzialiConstraints(composition)

                onChangeTipoCombo(newConstraints.combo.default || TYPES[0])

                newConstraints.comboTeam = comboTeamConstraints(composition)
            }
        }
        newConstraints.team = teamOptionConstraints(composition)
        newConstraints.parziali = parzialiConstraints(composition)
        setContraints(newConstraints)

        return newConstraints
    }
    const updateForecastComposition = (
        updates: Partial<ForecastComposition>,
    ) => {
        let newComposition = { ...composition, tipo: currentTipo, ...updates }
        const newConstraints = updateContraints(newComposition)
        if (newComposition.combo) {
            if (newConstraints.combo.available.length == 0) {
                newComposition.combo = null
            } else {
                newComposition.combo.parziali =
                    newConstraints.comboParziali.default || PARZIALI[0]

                newComposition.combo.team =
                    newConstraints.comboTeam.default || TEAMS[0]
            }
        }

        newComposition.team = newConstraints.team.default || TEAMS[0]

        newComposition.parziali =
            newConstraints.parziali.default ||
            (newConstraints.parziali.available &&
                newConstraints.parziali.available[0]) ||
            PARZIALI[0]

        setComposition(newComposition)
    }

    const onChangeTipoCombo = (t: Tipo) => {
        setCurrentComboTipo(t)
        let message
        if (t == 'esito') {
            message =
                'Attenzione: per combo esito, i parziali sono automaticamente impostati su primo e secondo tempo.'
        }
        if (t == 'esatto') {
            message =
                'Attenzione: per combo esatto, i parziali sono automaticamente impostati su primo e secondo tempo.'
        }
        message &&
            Swal.fire({
                toast: true,
                position: 'bottom-right',
                showConfirmButton: false,
                showCloseButton: true,
                timer: 8000,
                text: message,
            })
    }
    return (
        <div>
            <div className="flex justify-around">
                <ToggleSwitchList
                    title="Parziali"
                    subtitle="Pronostico sui parziali di gioco"
                    value={composition.parziali}
                    valueList={PARZIALI}
                    onChange={(value: Parziali) =>
                        setComposition({
                            ...composition,
                            parziali: value,
                        })
                    }
                    disabled={PARZIALI.filter(
                        (v) => !constraints.parziali.available.includes(v),
                    )}
                />
                <ToggleSwitchList
                    title="Team"
                    subtitle="Associato alla squadra"
                    value={composition.team}
                    valueList={TEAMS}
                    onChange={(value: Team) =>
                        setComposition({ ...composition, team: value })
                    }
                    disabled={TEAMS.filter(
                        (v) => !constraints.team.available.includes(v),
                    )}
                />
            </div>

            <hr className="my-4 border-white/[.05]" />
            <h3 className="mb-2">Tipo</h3>

            <div className="flex flex-col">
                <div className="flex w-full pb-3 overflow-x-scroll justify-start items-start gap-2">
                    {TYPES.map((t: Tipo, i) => (
                        <Button
                            key={i}
                            className="whitespace-nowrap w-full"
                            active={t == currentTipo}
                            color={t == currentTipo ? 'primary' : null}
                            onClick={() => setCurrentTipo(t)}
                        >
                            {t.toUpperCase().replace('_', ' ')}
                        </Button>
                    ))}
                </div>
                <div className="w-full py-4 overflow-x-scroll">
                    <TipoForm
                        tipo={currentTipo}
                        onChange={(data: any) =>
                            updateTipoData(currentTipo, data)
                        }
                    />
                </div>
            </div>

            <hr className="my-6 border-white/[.05]" />

            <div className="flex justify-center gap-4 w-full">
                <div className="flex-1">
                    <label
                        className="block sm:inline sm:mr-2"
                        htmlFor="quotation"
                    >
                        Quota
                    </label>
                    <Input
                        id="quotation"
                        className="w-full"
                        step=".01"
                        type="number"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setQuotation(e.target.value)
                        }}
                        value={quotation}
                    />
                </div>

                <div className="flex-1">
                    <label className="block sm:inline sm:mr-2" htmlFor="stake">
                        Stake
                    </label>
                    {/* <span className="text-xxxs leading-tight">
                        Lacia il campo vuoto per non inserire lo stake
                    </span> */}
                    <div className="flex items-center gap-1">
                        <Input
                            id="stake"
                            className="w-full"
                            type="string"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setStake(e.target.value)
                            }}
                            value={stake}
                        />
                        <span className="whitespace-nowrap">/ 10</span>
                    </div>
                </div>
            </div>
            <div className="flex justify-between py-2">
                <ToggleSwitch
                    label="Nascondi pronstico"
                    id="hideComposition"
                    checked={hideComposition}
                    onChange={(e) => {
                        setHideComposition(e.target.checked)
                        setHideMatch(!e.target.checked)
                    }}
                />
                <ToggleSwitch
                    label="Nascondi match"
                    id="hideMatch"
                    checked={hideMatch}
                    onChange={(e) => {
                        setHideMatch(e.target.checked)
                        setHideComposition(!e.target.checked)
                    }}
                />
            </div>
            <div className="border mt-4 p-2 border-white/[.05] rounded">
                <div className="flex justify-between align-center h-full">
                    <h3 className="">Combo</h3>
                    <ToggleSwitch
                        disabled={constraints.combo.available.length == 0}
                        className="inline"
                        id="combo-switch"
                        checked={composition.combo != null}
                        onChange={() => {
                            updateForecastComposition({
                                combo: composition.combo ? null : comboCache,
                            })
                        }}
                    />
                </div>
                {composition.combo && (
                    <div className="mt-4 flex flex-col">
                        <div className="flex pb-3 overflow-x-scroll gap-2">
                            {TYPES.map((t, i) => (
                                <Button
                                    key={i}
                                    className="w-full whitespace-nowrap"
                                    active={t == currentComboTipo}
                                    color={
                                        t == currentComboTipo ? 'primary' : null
                                    }
                                    onClick={() => onChangeTipoCombo(t)}
                                    disabled={
                                        !constraints.combo.available ||
                                        !constraints.combo.available.includes(t)
                                    }
                                >
                                    {t?.toUpperCase().replace('_', ' ')}
                                </Button>
                            ))}
                        </div>
                        <div className="mx-auto pt-4 py-4 w-full overflow-scroll">
                            {composition.combo && (
                                <TipoForm
                                    tipo={currentComboTipo}
                                    onChange={(data) =>
                                        updateComboData(currentComboTipo, data)
                                    }
                                />
                            )}
                        </div>

                        <hr className="my-8 border-white/[.05]" />
                        <div className="flex justify-around">
                            <ToggleSwitchList
                                value={composition.combo.parziali}
                                valueList={PARZIALI}
                                title="Parziali"
                                onChange={(value) =>
                                    setComposition({
                                        ...composition,
                                        combo: composition.combo && {
                                            ...composition.combo,
                                            parziali: value,
                                        },
                                    })
                                }
                                disabled={PARZIALI.filter(
                                    (v) =>
                                        !constraints.comboParziali.available.includes(
                                            v,
                                        ),
                                )}
                            />
                            <ToggleSwitchList
                                title="Team"
                                onChange={(value) =>
                                    setComposition({
                                        ...composition,
                                        combo: composition.combo && {
                                            ...composition.combo,
                                            team: value,
                                        },
                                    })
                                }
                                value={composition.combo.team}
                                valueList={TEAMS}
                                disabled={TEAMS.filter(
                                    (v) =>
                                        !constraints.comboTeam.available.includes(
                                            v,
                                        ),
                                )}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Pronostico
