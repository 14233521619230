import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOutletContext } from 'react-router-dom'
import Swal from 'sweetalert2'
import { UserProfileDataDTO } from '../../models/user-profile-data.dto'
import routes from '../../routing/routes'

const UserProfileHome = () => {
    const user: UserProfileDataDTO = useOutletContext()
    return <div></div>
}

export default UserProfileHome
