import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Input from '../../../components/Input'
import { EMAIL_REGEX, PASSWORD_REGEX, USERNAME_REGEX } from '../../../utils'
import {
    EMAIL_VALIDATION_ERROR_MESSAGE,
    PASSWORD_VALIDATION_ERROR_MESSAGE,
    REQUIRED_ERROR_MESSAGE,
    USERNAME_VALIDATION_ERROR_MESSAGE,
} from './constants'

export type CommonInputTypes = {
    username: string
    email: string
    password: string
    repeatPassword: string
    form: string
    adult: boolean
    terms: boolean
    privacy: boolean
    referer: string
}
const RegisterFields = () => {
    const {
        register,
        getValues,
        formState: { errors },
    } = useFormContext<CommonInputTypes>()
    const [showPassword, setShowPassword] = useState(false)

    return (
        <div className="w-full">
            <div className="pb-8 mx-auto w-full">
                <label htmlFor="email">Email</label>
                <div>
                    <Input
                        {...register('email', {
                            required: REQUIRED_ERROR_MESSAGE,
                            pattern: {
                                value: EMAIL_REGEX,
                                message: EMAIL_VALIDATION_ERROR_MESSAGE,
                            },
                        })}
                        className="w-full"
                        id="email"
                        autoComplete="email"
                        aria-invalid={errors.email ? 'true' : 'false'}
                    />
                    {errors.email && (
                        <div className="error">{errors.email.message}</div>
                    )}
                </div>
            </div>

            <div className="pb-8 mx-auto w-full">
                <label htmlFor="username">Username</label>
                <div>
                    <Input
                        {...register('username', {
                            required: REQUIRED_ERROR_MESSAGE,
                            pattern: {
                                value: USERNAME_REGEX,
                                message: USERNAME_VALIDATION_ERROR_MESSAGE,
                            },
                        })}
                        className="w-full"
                        id="username"
                    />
                    {errors.username && (
                        <div className="error">{errors.username.message}</div>
                    )}
                </div>
            </div>

            <div className="pb-8 mx-auto w-full">
                <label htmlFor="password">Password</label>
                <div>
                    <div className="flex gap-1">
                        <Input
                            {...register('password', {
                                required: REQUIRED_ERROR_MESSAGE,
                                pattern: {
                                    message: PASSWORD_VALIDATION_ERROR_MESSAGE,
                                    value: PASSWORD_REGEX,
                                },
                            })}
                            className="w-full"
                            id="password"
                            autoComplete="new-password"
                            type={showPassword ? 'text' : 'password'}
                        />
                        <div
                            className="cursor-pointer h-10 p-2 rounded"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            <FontAwesomeIcon
                                fixedWidth
                                icon={showPassword ? faEyeSlash : faEye}
                            />
                        </div>
                    </div>
                    {errors.password && (
                        <div className="error">{errors.password.message}</div>
                    )}
                </div>
            </div>

            <div className="pb-8 mx-auto w-full">
                <label htmlFor="repeat-password">Ripeti password</label>
                <div>
                    <Input
                        {...register('repeatPassword', {
                            required: REQUIRED_ERROR_MESSAGE,
                            validate: (value: string) =>
                                value === getValues().password ||
                                'Le password non coincidono.',
                        })}
                        className="w-full"
                        id="repeat-password"
                        autoComplete="new-password"
                        type={showPassword ? 'text' : 'password'}
                    />
                    {errors.repeatPassword && (
                        <div className="error">
                            {errors.repeatPassword?.message}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default RegisterFields
