import Box from '../../../components/Box'
import ScrollableContent from '../../../components/ScrollableContent'
import { useAuth } from '../../../context/Auth'
import PackOfferte from './PackOfferte'

const UserDashboard = () => {
    const { user } = useAuth()

    return (
        // <ScrollableContent>
        <div className="flex flex-col md:flex-row gap-2 h-full">
            <PackOfferte />
            {/* <Box title="Overview" className="flex-1"></Box> */}
        </div>
        // </ScrollableContent>
    )
}

export default UserDashboard
