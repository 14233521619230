import { faAngleRight, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ForecastModel, { MultiForecastModel } from '../../models/forecast.model'
import GridLayout from '../GridLayout'
import { Stake, TipsterName } from './common'
import { MatchGrid } from './selling/common'
import Styled from './Styled'
import { ForecastStatus, forecastToString } from './utilities'
import { ForecastHeader } from './storico/common'

type Props = {
    forecast: ForecastModel | MultiForecastModel
}

const LinkBonusButton = ({ link }: { link: string }) => {
    return (
        <a href={link}>
            <button className="ml-2 my-2 p-2 text-xxs rounded-lg leading-tight shadow-lg font-bold bg-green-500 self-center w-auto break-words">
                <div className="flex items-center gap-1">
                    <div className="text-center text-xxs md:text-xxs">
                        <div className="whitespace-nowrap font-normal">
                            Giocala con il
                        </div>
                        <div className="text-[.85rem]">BONUS</div>
                    </div>
                    <div className="flex items-center">
                        <FontAwesomeIcon
                            className="text-yellow-500"
                            icon={faStar}
                        />
                        <FontAwesomeIcon
                            className="text-xl"
                            icon={faAngleRight}
                        />
                    </div>
                </div>
            </button>
        </a>
    )
}

const SingleForecast = (forecast: ForecastModel) => (
    <GridLayout
        padding={0}
        gap={0}
        rows={{ mob: 1, lg: 1 }}
        elements={[
            {
                grid: {
                    fullWidth: true,
                    cols: 6,
                    md: { cols: 3 },
                },
                content: <MatchGrid match={forecast.match} />,
            },
            {
                grid: { cols: 2 },
                content: forecastToString(forecast),
            },

            {
                grid: { cols: 1 },
                content: '',
            },
        ]}
    />
)

const SoldForecast = ({ forecast }: Props) => {
    const getForecasts = (): ForecastModel[] => {
        if (forecast.multi) {
            return (forecast as MultiForecastModel).forecasts
        } else {
            return [forecast as ForecastModel]
        }
    }

    const elements = forecast.linkBonus
        ? [
              {
                  grid: { cols: 3 },
                  content: <Stake value={forecast.stake} />,
              },
              {
                  grid: { cols: 3 },
                  content: <LinkBonusButton link={forecast.linkBonus} />,
              },
          ]
        : [
              {
                  grid: { cols: 3, rows: 2 },
                  content: <Stake value={forecast.stake} />,
              },
          ]
    return (
        <Styled>
            <ForecastHeader forecast={forecast} />
            {forecast.multi && (
                <div className="absolute -top-1 left-0 right-0 flex justify-center items-start">
                    <span className="bg-gray-900 rounded-md px-1 text-white text-[7px] leading-3 uppercase">
                        multipla
                    </span>
                </div>
            )}
            <GridLayout
                padding={0}
                elements={[
                    {
                        grid: { cols: 1, rows: 2 },
                        content: (
                            <ForecastStatus forecast={forecast} readOnly />
                        ),
                    },
                    {
                        grid: { cols: 4, rows: 2, fullWidth: true },
                        content: (
                            <div className="w-full divide-y divide-dashed -my-2 divide-gray-600">
                                {getForecasts().map((f, i) => (
                                    <div key={i} className="py-2">
                                        {SingleForecast(f)}
                                    </div>
                                ))}
                            </div>
                        ),
                    },
                    ...elements,
                ]}
            />
        </Styled>
    )
}

export default SoldForecast
