import { ChangeEvent, useEffect, useState } from 'react'
import { EsattoData } from '../../../models/forecast-composition.model'
import Input from '../../Input'

type Props = {
    onChange: (data: EsattoData) => void
}

const EsattoForm = (props: Props) => {
    const [data, setData] = useState<EsattoData>({
        goal_fuori_casa: '0',
        goal_in_casa: '0',
    })

    const inputs = [
        {
            id: 'reti_team_casa' + Math.random(),
            label: 'Team in casa',
            placeholder: 'Reti in casa',
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                setData({
                    ...data,
                    goal_in_casa: e.target.value,
                })
            },
            value: data.goal_in_casa || '',
        },
        {
            id: 'reti_team_fuori_casa' + +Math.random(),
            label: 'Team fuori casa',
            placeholder: 'Reti fuori casa',
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                setData({
                    ...data,
                    goal_fuori_casa: e.target.value,
                })
            },
            value: data.goal_fuori_casa || '',
        },
    ]

    useEffect(() => props.onChange(data), [data])
    return (
        <div className="flex gap-2 w-full sm:w-2/4 mx-auto">
            {inputs.map((i) => (
                <div key={i.id} className="flex-1">
                    <label htmlFor={i.id} className="text-xxs block">
                        {i.label}
                    </label>
                    <Input
                        id={i.id}
                        type="number"
                        className="w-full"
                        placeholder={i.placeholder}
                        onChange={i.onChange}
                        value={i.value}
                    />
                </div>
            ))}
        </div>
    )
}

export default EsattoForm
