import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import Button from '../../components/Button'
import HomeBox from '../../components/HomeBox'
import InputField from '../../components/InputField'
import Modal from '../../components/Modal'
import { useApi } from '../../context/Api'
import routes from '../../routing/routes'

const ResetPassword = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const { requestPasswordReset } = useApi()
    const navigate = useNavigate()
    return (
        <HomeBox title="Richiesta cambio password">
            <div className="mb-4">
                <InputField
                    type="email"
                    label="Email"
                    error={error}
                    placeholder="Inserisci la mail di registrazione"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                />
            </div>
            <Button
                onClick={() => {
                    requestPasswordReset(email).then((res) => {
                        if (res) {
                            Swal.fire({
                                title: 'Richiesta inviata!',
                                text: 'Controlla la tua mail per completare il cambio password.',
                            }).then((res) => {
                                navigate(routes.home)
                            })
                        } else {
                            setError(
                                'Nessun account trovato per la mail inserita',
                            )
                        }
                    })
                }}
            >
                Richiedi
            </Button>
        </HomeBox>
    )
}

export default ResetPassword
