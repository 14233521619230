import { faBox } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Box from '../../../components/Box'
import { useApi } from '../../../context/Api'
import { useAppState } from '../../../context/AppState'
import PackModel from '../../../models/pack.model'
import OffertaForm from './OffertaForm'
import PackForm from './PackForm'
import ScrollableContent from '../../../components/ScrollableContent'

const PackCard = (props: { pack: PackModel; onClick: () => void }) => {
    return (
        <div
            className="bg-gray-600 rounded-lg shadow-xl p-4 inline-block text-center relative select-none cursor-pointer"
            onClick={props.onClick}
        >
            <div className="h-28 w-28 flex flex-col items-center justify-center">
                <h2 className="font-bold text-lg">{props.pack.name}</h2>
                <div className="leading-tight">
                    <div>{props.pack.price} €</div>

                    <div>{props.pack.betcoins} BC</div>
                </div>
                <div className="text-xs my-auto">MODIFICA</div>
            </div>
        </div>
    )
}

const EditPacks = () => {
    const { getPacksToEdit } = useApi()
    useEffect(() => {
        getPacksToEdit().then((res) => setPacks(res))
    }, [])
    const [packs, setPacks] = useState<PackModel[]>([])
    const { toggleModal } = useAppState()

    return (
        <Box title="Modifica pacchi e offerte" className="h-full">
            <ScrollableContent>
                <div className="p-2 flex flex-wrap gap-4">
                    <div
                        className="bg-yellow-600 rounded-lg shadow-xl p-4 inline-block 
            text-center relative select-none cursor-pointer"
                    >
                        <div
                            className="h-28 w-28 flex flex-col items-center justify-center"
                            onClick={() =>
                                toggleModal(
                                    true,
                                    `Modifica offerte`,
                                    <OffertaForm />,
                                )
                            }
                        >
                            <h2 className="font-bold text-lg">OFFERTE</h2>

                            <div className="text-xs my-auto">MODIFICA</div>
                        </div>
                    </div>
                    {packs.map((p, i) => (
                        <PackCard
                            pack={p}
                            key={i}
                            onClick={() =>
                                toggleModal(
                                    true,
                                    `Modifica pack ${p.name}`,
                                    <PackForm
                                        onSave={(pack) => {
                                            const idx = packs.indexOf(
                                                packs.find(
                                                    (pp) => pp.code == p.code,
                                                )!,
                                            )
                                            const newPacks = [...packs]
                                            newPacks[idx] = {
                                                ...packs[idx],
                                                ...pack,
                                            }
                                            setPacks(newPacks)
                                        }}
                                        pack={p}
                                    />,
                                )
                            }
                        />
                    ))}
                </div>
            </ScrollableContent>
        </Box>
    )
}

export default EditPacks
