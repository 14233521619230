import { faArrowUp, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import ForecastListDateFilter from '../../../components/ForecastListDateFilter'
import SectionNav from '../../../components/SectionNav'
import PaginateQuery from '../../../models/paginate-query.model'
import { apiServiceInst } from '../../../services/Api'
import ForecastsList from './ForecastsList'
import { add } from 'date-fns'

const Forecasts = () => {
    const [section, setSection] = useState('top-single')
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(
        undefined,
    )
    return (
        <div className="h-full flex flex-col">
            <div className="mb-4">
                <ForecastListDateFilter onSelect={setSelectedDate} />
                <SectionNav
                    fullWidthItems
                    elems={[
                        {
                            id: 'top-single',
                            title: (
                                <span>
                                    <FontAwesomeIcon
                                        className="text-yellow-400"
                                        icon={faStar}
                                    />{' '}
                                    Top Singole
                                </span>
                            ),
                        },
                        {
                            id: 'top-multi',
                            title: (
                                <span>
                                    <FontAwesomeIcon
                                        className="text-yellow-400"
                                        icon={faStar}
                                    />{' '}
                                    Top Multi
                                </span>
                            ),
                        },
                        {
                            id: 'high-odds',
                            title: (
                                <span>
                                    <FontAwesomeIcon
                                        className="text-yellow-400"
                                        icon={faArrowUp}
                                    />{' '}
                                    Quote alte
                                </span>
                            ),
                        },
                        { id: 'all', title: 'Tutti' },
                    ]}
                    onSelect={(item) => setSection(item.id)}
                />
            </div>
            <div className="flex flex-col grow">
                {section == 'top-single' && (
                    <ForecastsList
                        fromDate={selectedDate}
                        toDate={
                            selectedDate
                                ? add(selectedDate, { days: 1 })
                                : undefined
                        }
                        get={(options: PaginateQuery) =>
                            apiServiceInst.getTopForecasts({
                                ...options,
                                multi: false,
                            })
                        }
                        step={5}
                    />
                )}
                {section == 'top-multi' && (
                    <ForecastsList
                        fromDate={selectedDate}
                        toDate={
                            selectedDate
                                ? add(selectedDate, { days: 1 })
                                : undefined
                        }
                        get={(options: PaginateQuery) =>
                            apiServiceInst.getTopForecasts({
                                ...options,
                                multi: true,
                            })
                        }
                        step={5}
                    />
                )}
                {section == 'high-odds' && (
                    <ForecastsList
                        fromDate={selectedDate}
                        toDate={
                            selectedDate
                                ? add(selectedDate, { days: 1 })
                                : undefined
                        }
                        get={(options: PaginateQuery) =>
                            apiServiceInst.getHighOddsForecasts({
                                ...options,
                            })
                        }
                    />
                )}
                {section == 'all' && (
                    <ForecastsList
                        fromDate={selectedDate}
                        toDate={
                            selectedDate
                                ? add(selectedDate, { days: 1 })
                                : undefined
                        }
                        get={(options: PaginateQuery) =>
                            apiServiceInst.browseForSaleForecasts({
                                ...options,
                            })
                        }
                        step={20}
                    />
                )}
            </div>
        </div>
    )
}

export default Forecasts
