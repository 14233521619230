export type GridDef = {
    cols?: number | 'full'
    rows?: number | 'full'
    hidden?: boolean
}
type StructureGrid = Omit<GridDef, 'hidden'>
type Structure = {
    sm?: StructureGrid
    md?: StructureGrid
    lg?: StructureGrid
} & StructureGrid

export type ElemGrid = {
    fullWidth?: boolean
    sm?: GridDef
    lg?: GridDef
    md?: GridDef
} & GridDef
export type Elem = { grid: ElemGrid; content: any }

type Screens<T> = {
    mob?: T
    sm?: T
    md?: T
    lg?: T
}

type Props = {
    elements: Elem[]
    className?: string
    gap?: number
    structure?: Structure
    rows?: Screens<number>
    cols?: Screens<number>
    padding?: number
    flow?: Screens<'row' | 'col'>
}

const toScreen = (screens: Screens<any>, className: string) => {}

const GridLayout = (props: Props) => {
    const p = `p-${props.padding != undefined ? props.padding : 2}`
    const rows =
        props.rows &&
        (props.rows.mob && `grid-rows-${props.rows.mob}`) +
            ' ' +
            (props.rows.lg && `lg:grid-rows-${props.rows?.lg}`)

    const cols =
        props.cols &&
        (props.cols.mob && `grid-cols-${props.cols.mob}`) +
            ' ' +
            (props.cols.lg && `lg:grid-cols-${props.cols?.lg}`)

    const gap = `gap-${props.gap != undefined ? props.gap : 1}`
    const flow =
        (props.flow &&
            (props.flow.mob && `grid-flow-${props.flow.mob}`) +
                ' ' +
                (props.flow.lg && `lg:grid-flow-${props.flow?.lg}`)) ||
        'grid-flow-col'
    return (
        <div
            className={`${
                props.className || ''
            } grid ${rows} ${cols} ${flow} auto-cols-fr place-items-center justify-center ${gap} gap-x-1 ${p}`}
        >
            {props.elements.map((g, i) => {
                const classes = ['max-w-100']
                if (g.grid.hidden) classes.push('hidden')
                if (g.grid.cols) classes.push('col-span-' + g.grid.cols)
                if (g.grid.rows) classes.push('row-span-' + g.grid.rows)
                if (g.grid.sm) {
                    if (g.grid.sm.hidden) classes.push('sm:hidden')
                    else classes.push('sm:block')

                    if (g.grid.sm.cols)
                        classes.push('sm:col-span-' + g.grid.sm.cols)
                    if (g.grid.sm.rows)
                        classes.push('sm:row-span-' + g.grid.sm.rows)
                }
                if (g.grid.md) {
                    if (g.grid.md.hidden) classes.push('md:hidden')
                    else classes.push('md:block')

                    if (g.grid.md.cols)
                        classes.push('md:col-span-' + g.grid.md.cols)
                    if (g.grid.md.rows)
                        classes.push('md:row-span-' + g.grid.md.rows)
                }
                if (g.grid.lg) {
                    if (g.grid.lg.hidden) classes.push('lg:hidden')
                    else classes.push('lg:block')

                    if (g.grid.lg.cols)
                        classes.push('lg:col-span-' + g.grid.lg.cols)
                    if (g.grid.lg.rows)
                        classes.push('lg:row-span-' + g.grid.lg.rows)
                }
                if (g.grid.fullWidth) {
                    classes.push('w-full')
                }
                return (
                    <div key={i} className={classes.join(' ')}>
                        {g.content}
                    </div>
                )
            })}
        </div>
    )
}

export default GridLayout
