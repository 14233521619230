import { useWizard } from 'react-use-wizard'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { PASSWORD_VALIDATION_ERROR_MESSAGE } from './constants'
import { PASSWORD_REGEX } from '../../../utils'
import { useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { StepInput, StepInputContainer, StepTitle } from './step-elements'
import StepsNav from './StepsNav'
import TermsAndConditions from '../TermsAndConditions'
import Swal from 'sweetalert2'

const PasswordStep = () => {
    const {
        register,
        getValues,
        trigger,
        watch,
        formState: { errors },
    } = useFormContext<{
        password: string
        termsAndPrivacy: boolean
        newsletter: boolean
    }>()
    const [showPassword, setShowPassword] = useState(false)
    const [nextEnabled, setNextEnabled] = useState(true)
    const watchPassword = watch('password')
    const watchPrivacy = watch('termsAndPrivacy')
    const watchNewsletter = watch('newsletter')

    const validatePassword = () => {
        if (errors.password) {
            Swal.fire({
                toast: true,
                text: PASSWORD_VALIDATION_ERROR_MESSAGE,
                position: 'bottom',
                showCloseButton: true,
                showConfirmButton: false,
            })
            setNextEnabled(false)
            return false
        }
        return true
    }
    useEffect(() => {
        trigger(['password'])
        setNextEnabled(true)
    }, [watchPassword])
    return (
        <div className="flex flex-col h-full">
            <StepTitle title="Scegli la tua password" />

            <div className="grow">
                <div className="pb-4">
                    <StepInputContainer>
                        <StepInput
                            {...register('password', {
                                required: true,
                                pattern: {
                                    message: PASSWORD_VALIDATION_ERROR_MESSAGE,
                                    value: PASSWORD_REGEX,
                                },
                            })}
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                        />
                        <div
                            className="cursor-pointer h-full rounded text-lg"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            <FontAwesomeIcon
                                fixedWidth
                                icon={showPassword ? faEyeSlash : faEye}
                            />
                        </div>
                    </StepInputContainer>
                </div>
                {/* <StepError fieldError={errors.password} /> */}
                <div className="flex gap-2 items-center pb-4">
                    <input
                        {...register('termsAndPrivacy', {
                            validate: (value: boolean) => value === true,
                        })}
                        id="termsAndPrivacy"
                        type="checkbox"
                    />
                    <label
                        className="text-xs font-bold"
                        htmlFor="termsAndPrivacy"
                    >
                        Confermo di aver letto le condizioni di{' '}
                        <a
                            className="underline"
                            target="_blank"
                            href="https://youtipster.com/privacy"
                        >
                            privacy
                        </a>{' '}
                        e{' '}
                        <a
                            className="underline"
                            target="_blank"
                            href="https://youtipster.com/condizioni"
                        >
                            termini e condizioni
                        </a>
                        .
                    </label>
                </div>

                <div className="flex gap-2 items-center pb-4">
                    <input
                        {...register('newsletter', {
                            validate: (value: boolean) => value === true,
                        })}
                        id="newsletter"
                        type="checkbox"
                    />
                    <label className="text-xs font-bold" htmlFor="newsletter">
                        Tienimi aggiornato con la newsletter.
                    </label>
                </div>

                <TermsAndConditions />
            </div>
            <StepsNav
                onNext={validatePassword}
                nextEnabled={nextEnabled && watchPrivacy}
            />
        </div>
    )
}

export default PasswordStep
