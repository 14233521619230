import { useEffect, useState } from 'react'
import GridLayout from '../GridLayout'
import SearchBar from '../SearchBar'
import { useApi } from '../../context/Api'
import Tipster from '../../models/tipster.model'
import TipsterRow from './TipsterRow'

const Header = () => (
    <div className="text-xxs bg-black/[.5] text-white/[.55] lg:p-2">
        <GridLayout
            elements={[
                {
                    grid: { cols: 1, rows: 1, lg: { rows: 1 } },
                    content: <></>,
                },
                {
                    grid: { cols: 3, rows: 1, lg: { rows: 1 } },
                    content: <div>USERNAME</div>,
                },

                {
                    grid: { cols: 3, rows: 1, lg: { rows: 1 } },
                    content: <div>AZIONI</div>,
                },
            ]}
        />
    </div>
)

const TipsterList = () => {
    const { getTipsters } = useApi()
    const [tipsters, setTipsters] = useState<Tipster[]>([])

    useEffect(() => {
        getTipsters().then((res) => setTipsters(res))
    }, [])
    const [filterQuery, setFilterQuery] = useState('')

    const filter = (u: Tipster) => {
        const query = filterQuery.toLowerCase()

        return (
            !filter ||
            [u.nome, u.cognome, u.username].some(
                (v) => v && v.toLowerCase().match(query),
            )
        )
    }
    return (
        <div>
            <div>
                <SearchBar
                    onSearch={(query) => setFilterQuery(query)}
                    hintText="Cerca nome, cognome o username"
                />
            </div>
            <Header />
            <div>
                {tipsters.filter(filter).map((t, i) => (
                    <TipsterRow index={i + 1} key={i} tipster={t} />
                ))}
            </div>
        </div>
    )
}

export default TipsterList
