import { endOfDay, format } from 'date-fns'
import { useEffect, useState } from 'react'
import { it } from 'date-fns/locale'
import MatchModel from '../models/match.model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import add from 'date-fns/add'
import sub from 'date-fns/sub'
import CreaForecast from './CreaForecast'
import { useAppState } from '../context/AppState'
import { zonedDate } from '../utils'
import { useApi } from '../context/Api'
import SearchBar from './SearchBar'
import { MatchDateTime, TeamTag } from './Forecast/common'

const Palinsesto = () => {
    const [_matches, setMatches] = useState<MatchModel[]>([])
    const [day, setDay] = useState(new Date())
    const [filter, setFilter] = useState('')
    const { updateMatches, matches, switchLoading, toggleModal } = useAppState()
    const { getMatches } = useApi()

    const _getMatches = async (day: Date) => {
        const today = new Date()

        const formattedDay = format(day, 'yyyy-MM-dd')
        const formattedToday = format(today, 'yyyy-MM-dd')

        if (formattedDay == formattedToday) {
            day = today
        } else {
            day.setHours(0, 0, 0, 0)
        }
        if (matches[formattedDay]) {
            setMatches(
                matches[formattedDay].filter(
                    (m) =>
                        zonedDate(m.date) > day &&
                        zonedDate(m.date) < add(day, { days: 1 }),
                ),
            )
        } else {
            switchLoading(true)
            const matches = await getMatches(day, endOfDay(day))
            switchLoading(false)
            setMatches(
                matches.filter(
                    (m) =>
                        zonedDate(m.date) > day &&
                        zonedDate(m.date) < add(day, { days: 1 }),
                ),
            )
            updateMatches(formattedDay, matches)
        }
        setDay(day)
    }
    useEffect(() => {
        _getMatches(new Date())
    }, [])
    return (
        <div>
            <div className="flex justify-center items-center border-b border-black/[.15] shadow">
                <div className="flex justify-center items-center gap-2">
                    <button
                        onClick={() => _getMatches(sub(day, { days: 1 }))}
                        className="p-2 text-xs hover:text-primary"
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <div className="w-24 text-center text-sm">
                        {format(day, 'eee dd LLL', {
                            locale: it,
                        }).toLowerCase()}
                    </div>
                    <button
                        onClick={() => _getMatches(add(day, { days: 1 }))}
                        className="p-2 text-xs hover:text-primary"
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                </div>
            </div>
            <div className="border-b border-black/[.1] sticky top-0 bg-box">
                <SearchBar
                    onSearch={(query) => setFilter(query)}
                    hintText="Cerca squadre o leghe"
                />
            </div>

            {_matches &&
                _matches
                    .filter(
                        (m) =>
                            !filter ||
                            m.awayTeam
                                .toLowerCase()
                                .match(filter.toLowerCase()) ||
                            m.homeTeam
                                .toLowerCase()
                                .match(filter.toLowerCase()) ||
                            m.league.toLowerCase().match(filter.toLowerCase()),
                    )
                    .map((m: MatchModel, i) => (
                        <div
                            onClick={() =>
                                toggleModal(
                                    true,
                                    `${m.homeTeam} - ${m.awayTeam}`,
                                    <CreaForecast match={m} />,
                                )
                            }
                            key={i}
                            className={
                                'text-xs p-2 font-light text-center hover:bg-black/[.4] cursor-pointer overflow-hidden ' +
                                (i % 2 != 0
                                    ? 'bg-black/[.3]'
                                    : 'bg-black/[.05]')
                            }
                        >
                            <div className="flex justify-between mb-2">
                                <MatchDateTime matchDate={m.date} />
                                <div className="text-xxxs md:text-xxs text-ellipsis whitespace-nowrap overflow-hidden leading-tight">
                                    {m.league}
                                </div>
                            </div>
                            <div className="flex justify-center items-center w-full">
                                <TeamTag className="w-full text-right">
                                    {m.homeTeam}
                                </TeamTag>
                                <span className="mx-2">-</span>
                                <TeamTag className="text-left w-full">
                                    {m.awayTeam}
                                </TeamTag>
                            </div>
                        </div>
                    ))}
        </div>
    )
    // <div>{matches.length}</div>
}

export default Palinsesto
