import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../context/Auth'
import routes from './routes'

const PATHS_TO_SAVE = [routes.userTipsters]

const JWTGuardedRoute = () => {
    const { getJWT } = useAuth()
    const location = useLocation()
    if (getJWT()) {
        return <Outlet />
    } else {
        if (
            PATHS_TO_SAVE.map((p) => location.pathname.match(p)).some((p) => p)
        ) {
            sessionStorage.setItem('afterLoginRedirect', location.pathname)
        }
        return <Navigate to={routes.login} />
    }
}

export default JWTGuardedRoute
