import styled from 'styled-components'

type Props = {
    full?: boolean
    color?: 'primary' | 'success' | 'danger' | null
    className?: string
    children?: React.ReactNode
    size?: 'xs' | 'm'
    onClick?: (e: any) => void
    disabled?: boolean
    active?: boolean
    type?: 'button' | 'reset' | 'submit'
}

const Button = (props: Props) => {
    const color =
        props.color == 'primary'
            ? 'text-black/80'
            : props.color
            ? `text-${props.color}`
            : 'text-gray-300'
    const className = props.className || ''
    const size =
        props.size == 'm'
            ? 'px-4 py-2 text-xs'
            : props.size == 'xs'
            ? 'px-2 py-1 text-xxs'
            : 'px-4 py-2 text-xs'
    const background = props.disabled
        ? 'bg-white/[.1]'
        : props.color == 'primary'
        ? 'bg-primary'
        : 'bg-white/[.08]'
    return (
        <button
            type={props.type || 'button'}
            disabled={props.disabled}
            onClick={props.onClick}
            className={`${className} ${color} ${size} ${background} rounded border-0 shadow border-white/[.07] font-light 
            ${props.disabled && ' text-gray-400'} 
            ${
                props.active &&
                ' border-primary/[.4] shadow-primary/[.1] shadow-inner'
            }
            `}
        >
            {props.children}
        </button>
    )
}

// const Button = styled.button.attrs((props: Props) => ({
//     color: props.color,
//     className: `text-xs py-2 bg-white/[.03] border border-white/[.07] px-4 font-light ${
//         color == 'primary' ? 'text-primary' : 'text-gray-300'
//     }
//             ${(props: any) => props.full && 'w-100'}
//         `,
// }))``

export default Button
