import React, { ChangeEvent, useEffect, useState } from 'react'
import Box from '../../../components/Box'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Hr from '../../../components/styled/Hr'
import ToggleSwitch from '../../../components/ToggleSwitch'
import { useApi } from '../../../context/Api'
import { useAppState } from '../../../context/AppState'
import PackModel, {
    OfferCode,
    OfferComposition,
    PackCode,
} from '../../../models/pack.model'

const labels = {
    [OfferCode.sale]: 'Sconto',
    [OfferCode.special]: 'Special',
    [OfferCode.refund]: 'Rimborso',
    [OfferCode.welcome]: 'BC di benvenuto',
}

const OffertaForm = () => {
    const { getOffersForEdit, saveOffersForEdit } = useApi()
    const { toggleModal } = useAppState()
    useEffect(() => {
        getOffersForEdit().then((res) => {
            const newOfferta = {
                [PackCode.sale]: res.find((p) => p.code == PackCode.sale)!,
                [PackCode.special]: res.find(
                    (p) => p.code == PackCode.special,
                )!,
                [PackCode.refund]: res.find((p) => p.code == PackCode.refund)!,
                [PackCode.welcome]: res.find(
                    (p) => p.code == PackCode.welcome,
                )!,
            }
            setOfferta(newOfferta)
        })
    }, [])
    const [offerta, setOfferta] = useState<
        | {
              [key in OfferCode]: PackModel
          }
        | null
    >(null)
    const setOffertaComposition = (
        code: OfferCode,
        composition: OfferComposition,
    ) => {
        setOfferta({
            ...offerta!,
            [code]: {
                ...offerta![code],
                offerComposition: composition,
            },
        })
    }
    const forms: { [key in OfferCode]: React.ReactNode } = {
        [OfferCode.refund]: <div></div>,
        [OfferCode.sale]: offerta && (
            <div className="w-1/2 flex items-center">
                <label className="text-xxs w-1/4 text-right pr-2">%</label>
                <Input
                    className="w-3/4"
                    type="number"
                    value={offerta!.sale.offerComposition!['percentage']}
                    disabled={!offerta!.sale.active}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setOffertaComposition(OfferCode.sale, {
                            percentage: e.target.value,
                        })
                    }
                />
            </div>
        ),
        // [OfferCode.flatpack]: offerta && (
        //     <div className="w-1/2 flex flex-col md:flex-row md: gap-3 items-center">
        //         <div className="flex items-center">
        //             <label className="text-xxs w-1/4 text-right pr-2">
        //                 mesi
        //             </label>
        //             {/* <Input
        //                 className="w-3/4"
        //                 value={offerta.flatpack.mesi}
        //                 disabled={tipo != 'flatpack'}
        //                 onChange={(e: ChangeEvent<HTMLInputElement>) =>
        //                     setOfferta({
        //                         ...offerta,
        //                         flatpack: {
        //                             ...offerta.flatpack,
        //                             mesi: e.target.value,
        //                         },
        //                     })
        //                 }
        //             /> */}
        //         </div>
        //         <div className="flex items-center">
        //             <label className="text-xxs w-1/4 text-right pr-2">€</label>
        //             {/* <Input
        //                 className="w-3/4"
        //                 value={offerta.flatpack.prezzo}
        //                 disabled={tipo != 'flatpack'}
        //                 onChange={(e: ChangeEvent<HTMLInputElement>) =>
        //                     setOfferta({
        //                         ...offerta,
        //                         flatpack: {
        //                             ...offerta.flatpack,
        //                             prezzo: e.target.value,
        //                         },
        //                     })
        //                 }
        //             /> */}
        //         </div>
        //     </div>
        // ),
        [OfferCode.special]: offerta && (
            <div className="w-1/2 flex flex-col md:flex-row md: gap-3 items-center">
                <div className="flex items-center">
                    <label className="text-xxs w-1/4 text-right pr-2">BC</label>
                    <Input
                        className="w-3/4"
                        value={offerta!.special.betcoins}
                        disabled={!offerta!.special.active}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setOfferta({
                                ...offerta!,
                                special: {
                                    ...offerta!.special,
                                    betcoins: parseInt(e.target.value),
                                },
                            })
                        }
                    />
                </div>
                <div className="flex items-center">
                    <label className="text-xxs w-1/4 text-right pr-2">€</label>
                    <Input
                        className="w-3/4"
                        value={offerta!.special.price}
                        disabled={!offerta!.special.active}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setOfferta({
                                ...offerta!,
                                special: {
                                    ...offerta!.special,
                                    price: parseInt(e.target.value),
                                },
                            })
                        }
                    />
                </div>
                <div className="mb-8 md:m-0">
                    <label
                        className="block font-light text-sm pr-2"
                        htmlFor="price"
                    >
                        Sold out
                    </label>
                    <ToggleSwitch
                        id="sold-out"
                        checked={offerta!.special.soldOut}
                        disabled={!offerta!.special.active}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setOfferta({
                                ...offerta!,
                                special: {
                                    ...offerta!.special,
                                    soldOut: e.target.checked,
                                },
                            })
                        }
                    />
                </div>
            </div>
        ),
        [OfferCode.welcome]: offerta && (
            <div className="w-1/2 flex items-center">
                <label className="text-xxs w-1/4 text-right pr-2">BC</label>
                <Input
                    className="w-3/4"
                    type="number"
                    value={offerta!.welcome.offerComposition!['betcoins']}
                    disabled={!offerta!.welcome.active}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setOffertaComposition(OfferCode.welcome, {
                            betcoins: e.target.value,
                        })
                    }
                />
            </div>
        ),
    }

    return (
        <div className="flex flex-col gap-3">
            {offerta &&
                Object.keys(forms).map((k, i) => (
                    <div className="flex" key={k}>
                        <ToggleSwitch
                            label={labels[k as OfferCode]}
                            id={k}
                            className="w-1/2"
                            onChange={(e) =>
                                setOfferta({
                                    ...offerta,
                                    [k as OfferCode]: {
                                        ...offerta[k as OfferCode],
                                        active: e.target.checked,
                                    },
                                })
                            }
                            checked={offerta[k as OfferCode].active}
                        />
                        {forms[k as OfferCode]}
                    </div>
                    // {i < Object.keys(forms).length - 1 && <Hr />}
                ))}

            <Button
                onClick={() =>
                    saveOffersForEdit({
                        [PackCode.sale]: {
                            active: offerta![PackCode.sale].active!,
                            composition:
                                offerta![PackCode.sale].offerComposition!,
                        },
                        [PackCode.special]: {
                            active: offerta![PackCode.special].active!,
                            composition: {
                                price: offerta![
                                    PackCode.special
                                ].price!.toString(),
                                betcoins:
                                    offerta![
                                        PackCode.special
                                    ].betcoins!.toString(),

                                soldOut: offerta![PackCode.special].soldOut!,
                            },
                        },
                        [PackCode.refund]: {
                            active: offerta![PackCode.refund].active!,
                            composition:
                                offerta![PackCode.refund].offerComposition!,
                        },
                        [PackCode.welcome]: {
                            active: offerta![PackCode.welcome].active!,
                            composition:
                                offerta![PackCode.welcome].offerComposition!,
                        },
                    }).then((res) => toggleModal(false))
                }
            >
                Salva
            </Button>
        </div>
    )
}

export default OffertaForm
