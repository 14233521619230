import { useEffect, useState } from 'react'
import { useApi } from '../../../context/Api'
import PackTransactionModel from '../../../models/pack-transaction.model'
import { plainToInstance } from 'class-transformer'
import { zonedDate } from '../../../utils'
import { format } from 'date-fns'
import PaginatedList from '../../../components/Pagination/PaginatedList'
const PackPurchaseItem = ({ item }: { item: PackTransactionModel }) => {
    const day = format(zonedDate(item.date), 'dd/MM/yyyy')
    const time = format(zonedDate(item.date), 'HH:mm')
    return (
        <div className="text-white bg-element-bg rounded p-2">
            <div className="flex justify-between items-center">
                <div>
                    <div className="font-bold">{item.username}</div>

                    <div className="flex gap-1 text-xs">
                        <div>{day}</div>
                        <div className="font-thin">{time}</div>
                    </div>
                    <div className="text-xs font-thin text-primary">
                        {item.packName}
                    </div>
                </div>
                <div>
                    <div className="text-yellow-400 font-bold text-xl">
                        {item.amount.toFixed(2)} €
                    </div>
                </div>
            </div>
        </div>
    )
}
const PackPurchasesList = () => {
    const { getPackPurchases } = useApi()

    const [transactions, setTransactions] = useState<PackTransactionModel[]>([])
    const [perPage, setPerPage] = useState(15)
    const [total, setTotal] = useState(0)

    const changePage = (page: number) => {
        console.log(page)
        getPackPurchases({
            from: page * perPage,
            to: (page + 1) * perPage,
        }).then((res) => {
            setTotal(res.total)
            setTransactions(plainToInstance(PackTransactionModel, res.data))
        })
    }
    const textSearch = (text: string) => {
        getPackPurchases({
            text,
        }).then((res) => {
            setTransactions(plainToInstance(PackTransactionModel, res.data))
            setTotal(res.total)
        })
    }
    return (
        <div className="h-full">
            <PaginatedList<PackTransactionModel>
                items={transactions}
                textSearchPlaceholder="Cerca per username"
                itemBuilder={(data: PackTransactionModel, index?: number) => (
                    <PackPurchaseItem key={index} item={data} />
                )}
                perPage={perPage}
                total={total}
                onPageChange={changePage}
                onTextSearch={textSearch}
            />
        </div>
    )
}

export default PackPurchasesList
