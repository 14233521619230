import { Navigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useAuth } from '../context/Auth'
import routes from './routes'

type Props = {
    children: JSX.Element
}

const EmailConfirmedGuardedRoute = (props: Props) => {
    const { getUser } = useAuth()
    if (!getUser()?.emailConfirmed) {
        Swal.fire({
            title: 'Email non confermata',
            text: 'Conferma la tua email per continuare!',
        })
        return <Navigate to={routes.login} />
    } else {
        return props.children
    }
}

export default EmailConfirmedGuardedRoute
