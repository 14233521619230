import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useApi } from '../../context/Api'
import routes from '../../routing/routes'
import { validatePassword } from '../../utils'
import Button from '../Button'
import InputField from '../InputField'

const ChangePasswordForm = () => {
    const { updatePassword } = useApi()
    const navigate = useNavigate()
    const [data, setData] = useState({
        oldPassword: '',
        oldPasswordError: '',
        newPassword: '',
        newPasswordError: '',
        newPasswordConfirm: '',
        newPasswordConfirmError: '',
    })
    return (
        <div>
            <h3 className="text-primary/[.7]">Cambia password</h3>
            <form>
                <InputField
                    value={data.oldPassword}
                    className="mt-2 mb-4"
                    label="Vecchia password"
                    type="password"
                    error={data.oldPasswordError}
                    onChange={(e) => {
                        setData({
                            ...data,
                            oldPassword: e.target.value,
                            oldPasswordError: '',
                        })
                    }}
                />
                <InputField
                    value={data.newPassword}
                    className="mt-2 mb-4"
                    label="Nuova password"
                    type="password"
                    error={data.newPasswordError}
                    onChange={(e) => {
                        setData({
                            ...data,
                            newPassword: e.target.value,
                            newPasswordError: '',
                        })
                    }}
                />
                <InputField
                    value={data.newPasswordConfirm}
                    className="mt-2 mb-4"
                    label="Ripeti nuova password"
                    type="password"
                    error={data.newPasswordConfirmError}
                    onChange={(e) => {
                        setData({
                            ...data,
                            newPasswordConfirm: e.target.value,
                            newPasswordConfirmError: '',
                        })
                    }}
                />

                <Button
                    onClick={(e) => {
                        e.preventDefault()
                        const newData = { ...data }
                        let error = false
                        if (
                            !data.newPassword ||
                            !validatePassword(data.newPassword)
                        ) {
                            newData.newPasswordError =
                                'La password non è valida. Deve contenere almeno 8 caratteri e almeno 1 un numero e una lettera maiuscola.'
                            error = true
                        }
                        if (data.newPassword != data.newPasswordConfirm) {
                            newData.newPasswordConfirmError =
                                'Le password devono essere uguali.'
                            error = true
                        }
                        setData(newData)
                        if (error) {
                            return
                        }
                        updatePassword(data.oldPassword, data.newPassword).then(
                            (res) => {
                                if (!res) {
                                    setData({
                                        ...data,
                                        oldPasswordError:
                                            'La password non è corretta.',
                                    })
                                    return
                                }
                                Swal.fire({ title: 'Password cambiata!' })
                                navigate(routes.profile)
                            },
                        )
                    }}
                    color="primary"
                    className="rounded w-full"
                >
                    Aggiorna
                </Button>
            </form>
        </div>
    )
}

export default ChangePasswordForm
