import { useWizard } from 'react-use-wizard'
import { StepNavButton } from './step-elements'
import ProgressBar from './ProgressBar'

const StepsNav = (props: {
    nextEnabled: boolean
    onNext?: () => boolean | Promise<boolean>
}) => {
    const { handleStep, nextStep, isFirstStep, isLastStep, previousStep } =
        useWizard()

    return (
        <div>
            <div className="mb-8 flex flex-col gap-4">
                {!isLastStep && (
                    <StepNavButton
                        disabled={!props.nextEnabled}
                        onClick={async () =>
                            ((props.onNext && (await props.onNext())) ||
                                !props.onNext) &&
                            nextStep()
                        }
                        text="Avanti"
                    />
                )}
                <button
                    type="button"
                    className={`underline text-sm ${
                        isFirstStep ? 'invisible' : ''
                    }`}
                    onClick={previousStep}
                >
                    Indietro
                </button>
            </div>
        </div>
    )
}

export default StepsNav
