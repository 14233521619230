import { useLocation, useNavigate } from 'react-router-dom'
import { JWTPayload, useAuth } from '../context/Auth'
import routes from '../routing/routes'

const useAuthorizations = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { getUser } = useAuth()

    const navigateHome = (user?: JWTPayload) => {
        if (!user) {
            user = getUser()!
        }
        if (user) {
            const baseLocation = location.pathname
                .replace(/^\/+/, '')
                .split('/')
            switch (true) {
                case user.admin &&
                    baseLocation[0] != routes.admin.replace(/^\/+/, ''):
                    navigate(routes.admin)

                    break
                case user.affiliate &&
                    baseLocation[0] != routes.affiliate.replace(/^\/+/, ''):
                    navigate(routes.affiliatePronostici)
                    break
                case user.tipster &&
                    baseLocation[0] != routes.tipster.replace(/^\/+/, ''):
                    navigate(routes.tipsterPronostici)
                    break
                case !user.tipster &&
                    !user.admin &&
                    !user.affiliate &&
                    baseLocation[0] != routes.app.replace(/^\/+/, ''):
                    navigate(routes.app)
                    break
            }
        } else {
            console.log('no user set', user)
            // navigate(routes.home, { replace: true })
        }
    }

    return { navigateHome }
}

export default useAuthorizations
