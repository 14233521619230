import { faBox, faInfoCircle, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Box from '../../../components/Box'
import { useApi } from '../../../context/Api'
import { useAppState } from '../../../context/AppState'
import PackModel from '../../../models/pack.model'
import BuyPack from './BuyPack'
import classNames from 'classnames'
import PricingCard from '../../../components/PricingCard'
import Scrollable from '../../../components/styled/Scrollable'

const OfferCard = (props: { pack: PackModel }) => {
    return (
        <div className="animate__heartBeat bg-yellow-500 rounded-xl shadow-lg px-4 py-2 flex gap-2 select-none">
            <FontAwesomeIcon className="pt-1" icon={faStar} />
            <div>
                <h1 className="font-bold text-lg">{props.pack.name}</h1>
                <div className="text-xs leading-tight font-bold">
                    {props.pack.description}
                </div>
            </div>
        </div>
    )
}

const PackCard = (props: {
    pack: PackModel
    onClick: () => void
    backgroundUrl?: string
}) => {
    const discounted = props.pack.discounted?.toFixed(2)
    const price = props.pack.price?.toFixed(2)
    return (
        <div
            className={classNames(
                'w-24 h-24 rounded-lg shadow-xl p-4 inline-block text-center relative select-none box-content',
                {
                    'bg-gradient-to-t from-primary to-blue-600 animate__heartBeat z-20':
                        props.pack.offer,
                    'bg-gray-500 ': props.pack.soldOut,
                    'bg-success': !props.pack.offer && !props.pack.soldOut,
                    'cursor-pointer': !props.pack.soldOut,
                },
            )}
            onClick={() => !props.pack.soldOut && props.onClick()}
        >
            {props.pack.soldOut && (
                <div className="absolute bg-red-700 text-white rounded font-bold px-1 border border-red-700 text-sm bottom-5 -right-3 -rotate-45 shadow z-40">
                    ESAURITO
                </div>
            )}
            {/* {props.pack.offer && (
                <img
                    className="absolute -bottom-6  -right-2 w-16 rotate-12"
                    src="https://ytip-assets.s3.eu-central-1.amazonaws.com/christmas-special-offer-e1543320314112.png"
                />
            )} */}
            {!props.pack.offer && (
                <FontAwesomeIcon
                    className="absolute left-2 top-2 text-xs"
                    icon={props.pack.offer ? faStar : faBox}
                />
            )}
            <div className="h-20 w-full flex flex-col items-center justify-center">
                <h2 className="font-bold text-lg whitespace-nowrap">
                    {props.pack.name}
                </h2>
                <div className="leading-tight">
                    <div className="font-bold text-[1.2rem]">
                        {props.pack.betcoins} BC
                    </div>
                    <div className={discounted ? 'line-through text-xs' : ''}>
                        {price} €
                    </div>
                    {discounted && <div>{discounted} €</div>}
                </div>
            </div>
        </div>
    )
}
const PackOfferte = () => {
    const { getPacksToBuy: getPacks, getOffers } = useApi()
    useEffect(() => {
        getPacks().then((res) => setPacks(res))
        getOffers().then((res) => setOffers(res))
    }, [])
    const { toggleModal } = useAppState()
    const [packs, setPacks] = useState<PackModel[]>([])
    const [offers, setOffers] = useState<PackModel[]>([])

    return (
        <Scrollable>
            <div className="w-full flex flex-col gap-5 p-3 md:items-center">
                {packs.map((p, i) => (
                    <PricingCard
                        onClick={() =>
                            toggleModal(
                                true,
                                'Acquista pack ' + p.name,
                                <BuyPack pack={p} />,
                            )
                        }
                        key={i}
                        pack={p}
                    />
                ))}
            </div>

            <div className="text-white text-center text-sm mt-3">
                *50% subito + 50% al secondo acquisto
            </div>
            {/* <div>
                {offers.length > 0 && (
                    <div className="mb-2">
                        <h1 className="font-bold">Offerte attive</h1>
                        <div className="py-2 flex flex-col md:flex-row gap-2">
                            {offers.map((o, i) => (
                                <OfferCard key={i} pack={o} />
                            ))}
                        </div>
                    </div>
                )}
            </div> */}
        </Scrollable>
    )
}

export default PackOfferte
