import { PAYMENT_METHODS } from '../../constants'
import { TipsterPaymentsMethods } from '../../models/tipster.model'

type Props = {
    paymentMethods: TipsterPaymentsMethods
}
const Element = ({
    name,
    entries,
}: {
    name: string
    entries: { name: string; value: string }[]
}) => {
    return (
        <div className="px-2 py-1 border rounded border-white/[.1] my-2">
            <h5 className="text-xs text-center font-light uppercase">{name}</h5>
            <div className="flex justify-between overflow-x-scroll gap-2">
                {entries.map((e) => (
                    <div>
                        <div className="text-xxs text-gray-400">{e.name}</div>
                        <div className="text-xs">{e.value}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
const TipsterPaymentMethodsTable = (props: Props) => {
    return (
        <div>
            <div className="px-2 py-1 border rounded border-white/[.1] my-2">
                <div className="text-xxs">Metodo preferito</div>
                <div>{PAYMENT_METHODS[props.paymentMethods.favourite]}</div>
            </div>

            {Object.values(props.paymentMethods.paypal).some((p) => p) && (
                <Element
                    name="paypal"
                    entries={Object.entries(props.paymentMethods.paypal)
                        .filter((e) => e[1])
                        .map((e) => ({ name: e[0], value: e[1] }))}
                />
            )}
            {Object.values(props.paymentMethods.neteller).some((p) => p) && (
                <Element
                    name="neteller"
                    entries={Object.entries(props.paymentMethods.neteller).map(
                        (e) => ({ name: e[0], value: e[1] }),
                    )}
                />
            )}

            {Object.values(props.paymentMethods.skrill).some((p) => p) && (
                <Element
                    name="skrill"
                    entries={Object.entries(props.paymentMethods.skrill).map(
                        (e) => ({ name: e[0], value: e[1] }),
                    )}
                />
            )}
        </div>
    )
}

export default TipsterPaymentMethodsTable
