import { ChangeEvent, useEffect, useState } from 'react'
import { MultiGoalData } from '../../../models/forecast-composition.model'
import ToggleSwitch from '../../ToggleSwitch'

const values: MultiGoalData[] = [
    '1-2',
    '1-3',
    '1-4',
    '1-5',
    '1-6',
    '2-3',
    '2-4',
    '2-5',
    '2-6',
    '3-4',
    '3-5',
    '3-6',
    '4-5',
    '4-6',
    '7+',
]

type Props = {
    onChange: (data: MultiGoalData) => void
}

const MultigoalForm = (props: Props) => {
    const ids = values.map((v) => v + Math.random())

    const [data, setData] = useState<MultiGoalData>('1-2')

    const onCheck = (
        e: ChangeEvent<HTMLInputElement>,
        multigoal: MultiGoalData,
    ) => {
        e.target.checked && setData(multigoal)
    }
    useEffect(() => props.onChange(data), [data])

    return (
        <div className="grid grid-cols-3 gap-1">
            {values.map((d, i) => (
                <ToggleSwitch
                    id={ids[i]}
                    key={d.toString()}
                    label={d.toString()}
                    checked={data == d}
                    onChange={(e) => onCheck(e, d)}
                />
            ))}
        </div>
    )
}

export default MultigoalForm
