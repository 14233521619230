import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../context/Auth'

type Props = {
    children: JSX.Element
}

const TipsterGuardedRoute = (props: Props) => {
    const location = useLocation()
    const { getUser } = useAuth()
    if (getUser()?.tipster) {
        return props.children
    }
    if (getUser()?.admin) {
        return <Navigate to={location.pathname.replace('tipster', 'admin')} />
    } else {
        return <Navigate to={location.pathname.replace('tipster', 'app')} />
    }
}

export default TipsterGuardedRoute
