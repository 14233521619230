import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import SellingForecast from '../../../components/Forecast/selling/SellingForecast'
import { useApi } from '../../../context/Api'
import ForecastModel from '../../../models/forecast.model'
import User from '../../../models/user.model'
import { useAppState } from '../../../context/AppState'
import { getProfilePictureUrl } from '../../../utils'
import { SUCCESS_THRESHOLD } from '../../../constants'
import { apiServiceInst } from '../../../services/Api'
import { useAuth } from '../../../context/Auth'

const Detail = ({ label, value }: { label: string; value: any }) => {
    return (
        <div className="flex items-center gap-1 leading-tight">
            <span className="text-xxxs font-light uppercase">{label}</span>
            <span className="text-xxxs font-bold">{value}</span>
        </div>
    )
}

const TipsterProfilePage = () => {
    const { username } = useParams()
    const { getUser } = useAuth()
    const { switchLoading } = useAppState()
    const navigate = useNavigate()
    const [data, setData] = useState<{
        tipster: User | null
        forecasts: ForecastModel[]
        stats: {
            total: number
            selling: number
            success: number
        } | null
    }>({
        tipster: null,
        forecasts: [],
        stats: null,
    })
    const { getForecasts, getTipsterProfileData, getTipsterStats } = useApi()
    const location = useLocation() as {
        state: {
            stats: { total: number; selling: number; success: number }
        }
    }

    const getData = async () => {
        switchLoading(true)
        const tipsterData = await getTipsterProfileData(username!)
        const tipsterForecastsData =
            await apiServiceInst.browseForSaleForecasts({
                userId: tipsterData.id,
            })
        let stats = null
        if (location.state?.stats) {
            stats = location.state.stats
        } else {
            stats = await getTipsterStats(tipsterData.username)
        }
        setData({
            ...data,
            tipster: tipsterData,
            forecasts: tipsterForecastsData.data,
            stats:
                (stats && {
                    ...stats,
                    selling: tipsterForecastsData.total || 0,
                }) ||
                null,
        })
        switchLoading(false)
    }
    useEffect(() => {
        getData()
    }, [])

    const onBuy = (forecast: ForecastModel) => {
        const newForecasts = [...data.forecasts]
        newForecasts.splice(newForecasts.indexOf(forecast), 1)
        setData({
            ...data,
            forecasts: newForecasts,
            stats: data.stats && {
                ...data.stats,
                selling: newForecasts.length,
            },
        })
    }

    return (
        <div className="text-white lg:w-[680px] lg:mx-auto">
            <div className="relative mb-2 -m-2 p-2 bg-white/[.05]">
                <div
                    className="rounded-full cursor-pointer absolute right-2 top-2 text-white"
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon fixedWidth icon={faArrowLeft} />
                </div>
                <div className="flex gap-2 items-center">
                    <img
                        className="mr-1 rounded-full w-20 h-20 inline object-cover"
                        src={getProfilePictureUrl(data.tipster?.profilePicture)}
                    />
                    <div className="overflow-hidden">
                        <div className="font-bold text-xl text-white overflow-hidden overflow-ellipsis">
                            {username}
                        </div>
                        {data.stats && (
                            <div>
                                <Detail
                                    label="pubblicati"
                                    value={data.stats.total || 0}
                                />
                                <Detail
                                    label="in vendita"
                                    value={data.stats.selling || 0}
                                />
                                <Detail
                                    label="successo"
                                    value={
                                        data.stats.success > SUCCESS_THRESHOLD
                                            ? data.stats.success.toFixed(1) +
                                              '%'
                                            : `≤${SUCCESS_THRESHOLD}%`
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {data.forecasts && (
                <div className="pt-2 p-1">
                    <h2 className="font-bold mb-1">In vendita</h2>
                    <div className="flex flex-col gap-2">
                        {data.forecasts.map((f) => (
                            <SellingForecast
                                key={f.id}
                                onBuy={onBuy}
                                forecast={f}
                                purchaseDisabled={getUser()?.tipster}
                            />
                        ))}
                    </div>
                    {data.forecasts.length == 0 && (
                        <div className="text-center p-8 text-sm font-light text-gray-500/[.5]">
                            Nessun pronostico disponibile.
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default TipsterProfilePage
