import { useEffect, useRef, useState } from 'react'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import './style.scss'
import classNames from 'classnames'

type Props = {
    id: string
    label?: string
    className?: string
    onChange: (e: any) => void
    checked?: boolean
    disabled?: boolean
    labelOnLeft?: boolean
}
const ToggleSwitch = (props: Props) => {
    const input = useRef<HTMLInputElement | null>(null)
    const [checked, setChecked] = useState<boolean>(props.checked || false)
    useEffect(() => setChecked(props.checked || false), [props.checked])
    return (
        <div
            className={classNames(
                props.className,
                'flex items-center justify-start',
            )}
        >
            <label
                htmlFor={props.id}
                className={classNames('flex items-center cursor-pointer', {
                    'flex-row-reverse': props.labelOnLeft,
                    'gap-1': props.label,
                })}
            >
                <div className="relative">
                    <input
                        disabled={props.disabled}
                        ref={input}
                        onChange={(e) => {
                            setChecked(!checked)
                            props.onChange(e)
                        }}
                        type="checkbox"
                        id={props.id}
                        className="sr-only"
                        checked={checked}
                    />
                    <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                    <div
                        className={`${
                            props.disabled
                                ? 'bg-gray-500'
                                : checked
                                ? 'bg-primary'
                                : 'bg-white'
                        } ${
                            checked && 'translate-x-full'
                        } dot absolute left-1 top-1 w-6 h-6 rounded-full transition`}
                    ></div>
                </div>
                <div
                    className={classNames('font-light text-xxs', {
                        'text-gray-500': props.disabled,
                        'text-primary': !props.disabled,
                        'pl-2': props.label,
                    })}
                >
                    {props.label}
                </div>
            </label>
        </div>

        // <div className="flex items-center my-3">
        //     <Toggle
        //         onChange={() => {
        //             setChecked(!checked)
        //             props.onChange(!checked)
        //         }}
        //         className="custom"
        //         id={props.id}
        //         icons={false}
        //         checked={checked}
        //     />
        //     {props.label && (
        //         <label
        //             className="pl-2 font-light text-xxs text-primary"
        //             htmlFor={props.id}
        //         >
        //             {props.label}
        //         </label>
        //     )}
        // </div>
    )
}

export default ToggleSwitch
