import { useQuery } from 'react-query'
import { apiServiceInst } from '../../../services/Api'

import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import SellingForecast from '../../../components/Forecast/selling/SellingForecast'
import routes from '../../../routing/routes'

const BestPublicSellingForecastsWidget = () => {
    const forecasts = useQuery('best-for-sale-public', () =>
        apiServiceInst.getLastForecast({}),
    )
    const navigate = useNavigate()
    return (
        <div className="flex flex-col gap-2 h-full md:h-auto">
            {forecasts.data?.data.map((f) => (
                <SellingForecast
                    key={f.id}
                    onBuy={() => null}
                    forecast={f}
                    widget={true}
                    onBeforeBuy={(f) => {
                        Swal.fire({
                            text: 'Per visualizzare i pronostici effettua il login o crea un account, ci vorrà meno di 1 minuto!',
                        }).then(() => {
                            navigate(routes.login)
                        })
                        return false
                    }}
                />
            ))}
            {forecasts.data?.data.length === 0 && (
                <div className="text-gray-400 font-light text-center">
                    Nessun pronostico in vendita
                </div>
            )}
        </div>
    )
}

export default BestPublicSellingForecastsWidget
