import { Expose, Transform } from 'class-transformer'
import {
    EsattoData,
    EsitoData,
    ForecastComposition,
    GoalNoGoalData,
    HandicapData,
    MultiGoalData,
    Tipo,
    TipoCombo,
    UnderOverData,
} from './forecast-composition.model'
import MatchModel from './match.model'
import { IsOptional, IsString } from 'class-validator'

export interface GenericForecastModel {
    id: string
    price: number
    sold: number
    odds: number
    quotation: number
    status: 'draft' | 'selling' | string
    outcome: string
    success: boolean
    multi: boolean
    stake: number
    creator?: { id: string; username: string; profilePicture: string }
    linkBonus?: string
}

class ForecastModel implements GenericForecastModel {
    @Expose()
    id: string

    @Expose()
    match: MatchModel

    @Expose()
    sold: number

    @Expose()
    price: number

    @Expose()
    state: string

    @Expose()
    odds: number

    @Expose()
    composition: ForecastComposition

    @Expose()
    quotation: number

    @Expose()
    outcome: string

    @Expose()
    status: 'draft' | 'selling' | string

    @Expose()
    success: boolean

    @Expose()
    multi = false

    @Expose()
    stake: number

    @Expose()
    deletedOn: string | null

    @Expose()
    visuals: number

    @Expose()
    creator: {
        id: string
        username: string
        profilePicture: string
    }

    @Expose()
    @IsOptional()
    linkBonus?: string

    @Expose()
    @IsOptional()
    forecasts?: ForecastModel[]

    @Expose()
    @Transform(({ value }) => new Date(value))
    eventDate: Date

    hideComposition: boolean

    hideMatch: boolean

    public getMatches() {
        if (this.multi) {
            return this.forecasts!.map((f) => f.match)
        } else if (!this.multi) {
            return [this.match]
        } else {
            return []
        }
    }

    private tipoToString(
        tipo: Tipo | TipoCombo,
        data:
            | EsattoData
            | EsitoData
            | GoalNoGoalData
            | HandicapData
            | UnderOverData
            | MultiGoalData
            | null,
    ) {
        switch (tipo) {
            case 'esatto':
                data = data as EsattoData
                return `[${data.goal_in_casa}-${data.goal_fuori_casa}]`

            case 'esito':
                return data as string
            case 'goal/no_goal':
                return data ? 'GOAL' : 'NO GOAL'
            case 'handicap':
                data = data as HandicapData
                return `H${data}`
            case 'under/over':
                data = data as UnderOverData
                return data.over ? `over ${data.over}` : `under ${data.under}`
            case 'multigoal':
                data = data as MultiGoalData

                return data
            default:
                return ''
        }
    }
    toString() {
        let str = this.tipoToString(
            this.composition.tipo,
            this.composition.tipoData,
        )
        if (this.composition.combo) {
            str +=
                ' + ' +
                this.tipoToString(
                    this.composition.combo.tipo,
                    this.composition.combo.tipoData,
                )
        }
        return str
    }
}

export class MultiForecastModel implements GenericForecastModel {
    constructor(
        public id: string,
        public sold: number,
        public price: number,
        public state: string,
        public odds: number,
        public quotation: number,
        public outcome: string,
        public status: 'draft' | 'selling' | string,
        public success: boolean,
        public multi = true,
        public forecasts: ForecastModel[],
        public stake: number,
        public deletedOn: string | null,
        public visuals: number,
        public creator: {
            id: string
            username: string
            profilePicture: string
        },
        public linkBonus?: string,
    ) {}
}

export default ForecastModel

export type ForecastCommissionModel = ForecastModel & {
    commissionsGenerated: number
}
