import { ChangeEvent, useEffect, useState } from 'react'
import ToggleSwitch from './ToggleSwitch'

type Props<T> = {
    title?: string
    subtitle?: string
    onChange: (value: T) => void
    value: T
    valueList: T[]
    disabled?: T[]
}

const ToggleSwitchList = <T extends string>(props: Props<T>) => {
    const [value, setValue] = useState<T>(props.value || props.valueList[0])

    useEffect(() => setValue(props.value), [props.value])
    useEffect(() => {
        props.value != value && props.onChange(value)
    }, [value])

    return (
        <div className="flex flex-col gap-1">
            {props.title && <span className="text-center">{props.title}</span>}
            {props.valueList.map((t: T, i) => (
                <ToggleSwitch
                    disabled={props.disabled && props.disabled.includes(t)}
                    key={i}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        e.target.checked && setValue(t)
                    }}
                    id={t + Math.random()}
                    label={t.replace('_', ' ')}
                    checked={value == t}
                />
            ))}

            {props.subtitle && (
                <span className="text-xxs font-light text-gray-300">
                    {props.subtitle}
                </span>
            )}
        </div>
    )
}

export default ToggleSwitchList
