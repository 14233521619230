import 'animate.css'
import { ApiContextProvider } from './context/Api'
import { AppStateContextProvider } from './context/AppState'
import { AuthContextProvider } from './context/Auth'
import ContextApp from './ContextApp'
import './App.css'
import 'react-calendar/dist/Calendar.css'
import { useQuery } from 'react-query'
import { apiServiceInst } from './services/Api'
function App() {
    const loginCheckQuery = useQuery({
        queryKey: 'login-check',
        queryFn: () => apiServiceInst.loginCheck(),
        refetchOnWindowFocus: true,
    })
    return (
        <AppStateContextProvider>
            <AuthContextProvider>
                <ApiContextProvider>
                    <ContextApp />
                </ApiContextProvider>
            </AuthContextProvider>
        </AppStateContextProvider>
    )
}

export default App
