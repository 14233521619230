import { AxiosError } from 'axios'
import { plainToInstance } from 'class-transformer'
import Swal from 'sweetalert2'
import { API_ERROR_MESSAGE } from '../constants'
import { useAppState } from '../context/AppState'
import ForecastModel from '../models/forecast.model'
import { ApiError, apiServiceInst } from '../services/Api'

const usePublishForecast = () => {
    const { switchLoading } = useAppState()

    return async (forecast: ForecastModel) => {
        switchLoading(true)
        try {
            const res = await apiServiceInst.publishForecast(forecast.id)
            return res
        } catch (error) {
            const apiError = plainToInstance(
                ApiError,
                (error as AxiosError).response?.data,
            )
            let message
            if (apiError.error in API_ERROR_MESSAGE) {
                message = API_ERROR_MESSAGE[apiError.error]
            } else {
                message = ''
            }
            Swal.fire({
                title: 'Attenzione',
                text: message,
                showConfirmButton: true,
            })
        } finally {
            switchLoading(false)
        }
    }
}

export default usePublishForecast
