const TermsAndConditions = () => {
    return (
        <div className="text-[8px] text-white/[.5] text-justify h-12 overflow-y-scroll">
            Copyright 2020 YouTipster | All Rights Reserved | youtipster.com non
            è un operatore di gioco a distanza, ma un portale informativo
            dedicato al betting sportivo. Il gioco è vietato ai minori di 18
            anni. La pratica del gioco con vincite in denaro può causare
            dipendenza patologica. Informati sulle probabilità di vincita e sul
            regolamento di gioco sul sito www.aams.gov.it (Avvertenze ai sensi
            del D.L. 13 settembre 2012 n.158 convertito in Legge n. 189/2012)
            Consigliamo, inoltre, di consultare le probabilità di vincita dei
            singoli giochi presenti sui siti dei concessionari. Privacy Policy
            Cookie Policy Refund Policy terms & conditions Contact
        </div>
    )
}
export default TermsAndConditions
