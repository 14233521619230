import classNames from 'classnames'

type Props = {
    className?: string
    children?: React.ReactNode
}

const Scrollable = (props: Props) => {
    return (
        <div
            className={classNames(
                props.className,
                'relative overflow-y-scroll h-full w-full',
            )}
        >
            <div className="inset-0 absolute">{props.children}</div>
        </div>
    )
}

export default Scrollable
