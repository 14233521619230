import {
    faArrowLeft,
    faEye,
    faEyeSlash,
    faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState } from 'react'
import {
    Controller,
    FormProvider,
    useFieldArray,
    useForm,
    useFormContext,
    useWatch,
} from 'react-hook-form'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import tw from 'twin.macro'
import Box from '../../../components/Box'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import ScrollableContent from '../../../components/ScrollableContent'
import { useApi } from '../../../context/Api'
import UserRegistrationDataDTO from '../../../models/user-registration-data.dto'
import routes from '../../../routing/routes'
import TermsAndConditions from '../TermsAndConditions'
import { REQUIRED_ERROR_MESSAGE } from './constants'
import RegisterFields, { CommonInputTypes } from './RegisterFields'

type InputTypes = CommonInputTypes & {
    adult: boolean
    terms: boolean
    contract: boolean
    referer: string
    tipsterDetails: {
        canali: { nome: string; pagina: string; selected: boolean }[]
        followersRange: string
        pianiAffiliazione: string
        fatturatoRange: string
    }
}

enum CANALI {
    TELEGRAM = 'TELEGRAM',
    INSTAGRAM = 'INSTAGRAM',
    TIKTOK = 'TIKTOK',
    FACEBOOK = 'FACEBOOK',
    'PAGINA WEB (SEO)' = 'PAGINA WEB (SEO)',
}

const FOLLOWERS = [
    '0 - 1k',
    '1k - 5k',
    '5k - 20k',
    '20k - 50k',
    '50k - 100k',
    '100k+',
]

const FATTURATO = ['0 - 1k', '1k - 5k', '5k - 20k', '20k+']

const PLACEHOLDERS: { [key: string]: string } = {
    TELEGRAM: "Nome Canale o link d'invito se privato",
    'PAGINA WEB (SEO)': 'URL pagina web',
}

const CanaliForm = () => {
    const {
        register,
        setValue,
        control,
        formState: { errors },
    } = useFormContext<{
        tipsterDetails: {
            canali: { selected: boolean; pagina: string; nome: string }[]
        }
    }>()
    useEffect(() => {
        register('tipsterDetails.canali', {
            validate: (value) => {
                console.log(errors.tipsterDetails?.canali)
                return (
                    value.some((v) => v.selected) ||
                    'Devi selezionare almeno un canale!'
                )
            },
        })
    }, [])
    const canaliWatch = useWatch({ name: 'tipsterDetails.canali', control })
    return (
        <div className="w-full pb-2">
            <h4>Canali utilizzati</h4>
            <div>
                {Object.keys(CANALI).map((c, i) => (
                    <div key={i} className="mb-2">
                        <div className="flex items-center mb-1 gap-1">
                            <input
                                onChange={(e) => {
                                    setValue(
                                        `tipsterDetails.canali.${i}.selected`,
                                        e.target.checked,
                                    )
                                }}
                                type="checkbox"
                            />
                            <span className="font-light text-xs">{c}</span>
                        </div>
                        {canaliWatch?.length && canaliWatch[i].selected && (
                            <div>
                                <Input
                                    className="w-full"
                                    {...register(
                                        `tipsterDetails.canali.${i}.pagina`,
                                        {
                                            validate: {
                                                required: (value) =>
                                                    (value &&
                                                        canaliWatch[i]
                                                            .selected) ||
                                                    REQUIRED_ERROR_MESSAGE,
                                            },
                                        },
                                    )}
                                    placeholder={
                                        PLACEHOLDERS[c as CANALI] ||
                                        'Nome pagina ' + c
                                    }
                                />

                                {errors.tipsterDetails?.canali![i]?.pagina && (
                                    <div className="error">
                                        {
                                            errors.tipsterDetails?.canali![i]
                                                ?.pagina?.message
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {errors.tipsterDetails?.canali?.root && (
                <div className="error">
                    {errors.tipsterDetails?.canali.root.message}
                </div>
            )}
        </div>
    )
}
const RegisterTipster = () => {
    const navigate = useNavigate()
    const { registerUser } = useApi()
    const methods = useForm<InputTypes>({
        defaultValues: {
            tipsterDetails: {
                fatturatoRange: FATTURATO[0],
                followersRange: FOLLOWERS[0],
                pianiAffiliazione: 'false',
            },
        },
    })
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setError,
        setValue,
        control,
    } = methods
    const { fields, append } = useFieldArray({
        control,
        name: 'tipsterDetails.canali',
    })

    const [refererLink, setRefererLink] = useState(false)

    const [searchParams] = useSearchParams()

    useEffect(() => {
        if (searchParams.get('referer')) {
            const referer = searchParams.get('referer')
            setValue('referer', referer!)
            setRefererLink(true)
        }
        for (let c in CANALI) {
            append({ nome: c, pagina: '', selected: false })
        }
    }, [])

    const onSubmit = async (data: InputTypes) => {
        const payload: UserRegistrationDataDTO = {
            ...data,
            tipster: true,
            tipsterDetails: {
                ...data.tipsterDetails,
                pianiAffiliazione:
                    data.tipsterDetails.pianiAffiliazione == 'true',
                canali: data.tipsterDetails.canali
                    .filter((c) => c.selected)
                    .map((c) => ({ nome: c.nome, pagina: c.pagina })),
            },
        }
        registerUser(payload).then(
            (res) => {
                if ((res as any).response?.status == 400) {
                    if ((res as any).response.data.message == 'email') {
                        setError('email', {
                            type: 'validate',
                            message: 'Email già esistente',
                        })
                    }
                    if ((res as any).response.data.message == 'username') {
                        setError('username', {
                            type: 'validate',
                            message: 'Username già esistente',
                        })
                    }
                    return
                }

                Swal.fire({
                    title: 'Richiesta inviata!',
                    text: 'La tua richiesta di registrazione come tipster è stata inviata. Riceverai una notifica via mail quando il tuo account sarà approvato.',
                })
                navigate(routes.login)
            },
            (res) => {
                if (res.request.status === 400) {
                    // setErrors({
                    //     ...errors,
                    //     form: 'Username o email già esistenti',
                    // })

                    return
                }
            },
        )
    }

    return (
        <div className="w-full flex justify-center items-center h-full py-4">
            <Box
                className="m-4 w-full h-full md:w-[360px]"
                title="Registrati come Tipster"
            >
                <div className="h-full flex flex-col">
                    <ScrollableContent className="flex-1">
                        <div>
                            <FormProvider {...methods}>
                                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                                    <RegisterFields />
                                    <div className="pb-8 mx-auto w-full">
                                        <label htmlFor="affiliato">
                                            Referral affiliato
                                        </label>
                                        <div>
                                            <Input
                                                {...register('referer')}
                                                className="w-full"
                                                id="affiliato"
                                                placeholder="Username dell'affiliato di riferimento"
                                                autoComplete="affiliato"
                                                type="text"
                                                disabled={refererLink}
                                            />
                                        </div>
                                    </div>
                                    <CanaliForm />
                                    <div className="pb-8 mx-auto w-full">
                                        <h4>Quanti followers hai in totale?</h4>
                                        {FOLLOWERS.map((f, i) => (
                                            <div key={i}>
                                                <input
                                                    type="radio"
                                                    className="mr-1"
                                                    value={f}
                                                    {...register(
                                                        'tipsterDetails.followersRange',
                                                        {
                                                            required:
                                                                REQUIRED_ERROR_MESSAGE,
                                                        },
                                                    )}
                                                />
                                                <span className="font-light text-xs">
                                                    {f}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pb-8 mx-auto w-full">
                                        <h4>
                                            Hai piani di affiliazione attivi?
                                        </h4>
                                        <div>
                                            <input
                                                type="radio"
                                                className="mr-1"
                                                value={'true'}
                                                {...register(
                                                    'tipsterDetails.pianiAffiliazione',
                                                    {
                                                        required:
                                                            REQUIRED_ERROR_MESSAGE,
                                                    },
                                                )}
                                            />
                                            <span className="font-light text-xs">
                                                SI
                                            </span>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                className="mr-1"
                                                value={'false'}
                                                {...register(
                                                    'tipsterDetails.pianiAffiliazione',
                                                    {
                                                        required:
                                                            REQUIRED_ERROR_MESSAGE,
                                                    },
                                                )}
                                            />
                                            <span className="font-light text-xs">
                                                NO
                                            </span>
                                        </div>
                                    </div>
                                    <div className="pb-8 mx-auto w-full">
                                        <h4>
                                            Qual è il tuo attuale range di
                                            fatturato?
                                        </h4>
                                        {FATTURATO.map((f, i) => (
                                            <div key={i}>
                                                <input
                                                    type="radio"
                                                    className="mr-1"
                                                    value={f}
                                                    {...register(
                                                        'tipsterDetails.fatturatoRange',
                                                        {
                                                            required:
                                                                REQUIRED_ERROR_MESSAGE,
                                                        },
                                                    )}
                                                />
                                                <span className="font-light text-xs">
                                                    {f}
                                                </span>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="pb-8 mx-auto w-full">
                                        <div className="flex gap-2 items-center">
                                            <input
                                                {...register('adult', {
                                                    validate: (
                                                        value: boolean,
                                                    ) =>
                                                        value === true ||
                                                        'Devi avere 18+ anni per utilizzare il servizio.',
                                                })}
                                                id="adult"
                                                type="checkbox"
                                            />
                                            <label className="" htmlFor="adult">
                                                Confermo di avere 18+ anni
                                            </label>
                                        </div>
                                        {errors.adult && (
                                            <div className="error">
                                                {errors.adult?.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="pb-8 mx-auto w-full">
                                        <div className="flex gap-2 items-center">
                                            <input
                                                {...register('terms', {
                                                    validate: (
                                                        value: boolean,
                                                    ) =>
                                                        value === true ||
                                                        'Devi accettare i termini e condizioni.',
                                                })}
                                                id="terms"
                                                type="checkbox"
                                            />
                                            <label className="" htmlFor="terms">
                                                Accetto i{' '}
                                                <a
                                                    className="underline"
                                                    target="_blank"
                                                    href="https://youtipster.com/condizioni"
                                                >
                                                    termini e condizioni
                                                </a>
                                                .
                                            </label>
                                        </div>
                                        {errors.terms && (
                                            <div className="error">
                                                {errors.terms?.message}
                                            </div>
                                        )}
                                    </div>

                                    <div className="pb-8 mx-auto w-full">
                                        <div className="flex gap-2 items-center">
                                            <input
                                                {...register('contract', {
                                                    validate: (
                                                        value: boolean,
                                                    ) =>
                                                        value === true ||
                                                        'Devi accettare il contratto.',
                                                })}
                                                id="contract"
                                                type="checkbox"
                                            />
                                            <label
                                                className=""
                                                htmlFor="contract"
                                            >
                                                Accetto i{' '}
                                                <a
                                                    className="underline"
                                                    target="_blank"
                                                    href="https://youtipster.com/contratto"
                                                >
                                                    contratto affiliati
                                                </a>
                                                .
                                            </label>
                                        </div>
                                        {errors.contract && (
                                            <div className="error">
                                                {errors.contract?.message}
                                            </div>
                                        )}
                                    </div>

                                    <Button
                                        type="submit"
                                        className="text-lg w-full"
                                        color="primary"
                                    >
                                        <FontAwesomeIcon icon={faUser} /> Invia
                                        richiesta
                                    </Button>
                                </StyledForm>
                            </FormProvider>
                            <TermsAndConditions />
                        </div>
                    </ScrollableContent>
                    <div>
                        <Link
                            className="text-center mx-auto block text-primary text-xs underline my-4 font-thin"
                            to={routes.register}
                        >
                            Registrati come utente normale
                        </Link>
                        <Link
                            className="text-right block text-primary text-xs m-4 font-thin"
                            to={routes.home}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} /> Torna alla
                            home
                        </Link>
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default RegisterTipster

const StyledForm = styled.form`
    ${tw`my-4 px-4 flex flex-col justify-center items-center w-full`}
    & label {
        ${tw`text-xs mr-4 block text-left`}
    }

    & .error {
        ${tw`text-danger text-xxs pt-2`}
    }
`
