import { faBoltLightning, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosError } from 'axios'
import { plainToInstance } from 'class-transformer'
import { useState } from 'react'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import { API_ERROR_MESSAGE } from '../../constants'
import { useApi } from '../../context/Api'
import { useAppState } from '../../context/AppState'
import { ForecastComposition } from '../../models/forecast-composition.model'
import ForecastModel from '../../models/forecast.model'
import MatchModel from '../../models/match.model'
import { ApiError, apiServiceInst } from '../../services/Api'
import Button from '../Button'
import InfoMatch from './InfoMatch'
import Pronostico from './Pronostico'
import Quotazioni from './Quotazioni'

type Props = {
    match: MatchModel
}

const CreaForecast = ({ match }: Props) => {
    const [section, setSection] = useState<
        'pronostico' | 'info match' | 'quotazioni'
    >('pronostico')

    const [composition, setComposition] = useState<ForecastComposition | null>(
        null,
    )
    const [quotation, setQuotation] = useState('1')
    const [stake, setStake] = useState('1')
    const [hideMatch, setHideMatch] = useState(false)
    const [hideComposition, setHideComposition] = useState(true)

    const { addForecast, switchLoading, toggleModal } = useAppState()
    const { createForecast } = useApi()

    const saveDraft = async () => {
        if (parseFloat(quotation) == 1) {
            const result = await Swal.fire({
                text: 'Attenzione: non hai inserito la quota, vuoi continuare?',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Annulla',
                showCancelButton: true,
            })

            if (!result.isConfirmed) {
                return
            }
        }

        let parsedQuotation = quotation
        try {
            parseFloat(quotation)
        } catch (error) {
            parsedQuotation = ''
        }
        if (parsedQuotation == '0' || !parsedQuotation) {
            Swal.fire({ text: 'Quota non valida. Imposta un valore diverso.' })
            return
        }
        let parsedStake: string | undefined = stake
        try {
            parseInt(stake)
        } catch (error) {
            parsedStake = undefined
        }
        if (stake && parsedStake) {
            if (parseInt(stake) > 10 || parseInt(stake) < 1) {
                Swal.fire({
                    text: 'Stake non valido. Imposta un valore diverso.',
                })
                return
            }
        }
        if (!composition) {
            return
        }
        switchLoading(true)

        try {
            const result = await apiServiceInst.createForecast({
                single: {
                    matchId: match.id,
                    quotation: parseFloat(quotation),
                    composition: composition,
                    stake: (stake && parseInt(stake)) || undefined,
                    hideComposition,
                    hideMatch,
                },
            })
            addForecast(result as ForecastModel)
        } catch (error) {
            const apiError = plainToInstance(
                ApiError,
                (error as AxiosError).response?.data,
            )
            let message
            if (apiError.error in API_ERROR_MESSAGE) {
                message = API_ERROR_MESSAGE[apiError.error]
            } else {
                message =
                    'Errore nella creazione pronostico. Verifica che il match non sia già iniziato.'
            }
            Swal.fire('Errore', message)
        } finally {
            switchLoading(false)
        }

        toggleModal(false)
    }
    return (
        <div className="flex flex-col pb-4">
            <div className="flex items-center justify-center gap-3 text-xs font-thin">
                <TopButton
                    className={
                        section == 'info match' ? 'border border-primary' : ''
                    }
                    onClick={() => setSection('info match')}
                >
                    info match
                </TopButton>
                <TopButton
                    className={
                        section == 'pronostico' ? 'border border-primary' : ''
                    }
                    onClick={() => setSection('pronostico')}
                >
                    pronostico
                </TopButton>
            </div>
            <div className="w-full mx-auto flex-grow relative">
                <div className="">
                    <div className="w-full sm:w-3/4 mx-auto py-4">
                        {section == 'info match' && <InfoMatch match={match} />}
                        {section == 'pronostico' && (
                            <Pronostico
                                onUpdate={(data) => {
                                    setComposition(data.composition)
                                    setQuotation(data.quotation)
                                    setStake(data.stake)
                                    setHideComposition(data.hideComposition)
                                    setHideMatch(data.hideMatch)
                                }}
                            />
                        )}
                        {section == 'quotazioni' && <Quotazioni />}
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 md:mx-auto gap-2 flex justify-between">
                <Button className="flex-1" onClick={() => toggleModal(false)}>
                    <FontAwesomeIcon icon={faTimes} fixedWidth={true} /> annulla
                </Button>
                <Button
                    className="flex-1"
                    color="primary"
                    onClick={() => saveDraft()}
                >
                    <FontAwesomeIcon icon={faBoltLightning} fixedWidth={true} />
                    salva bozza
                </Button>
            </div>
        </div>
    )
}

export default CreaForecast

const TopButton = styled.button.attrs((props) => ({
    className:
        props.className +
        ' font-thin text-xs w-32 py-2 bg-white/[.1] rounded-lg',
}))``
