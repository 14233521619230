import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../context/Auth'

type Props = {
    children: JSX.Element
}

const UserGuardedRoute = (props: Props) => {
    const location = useLocation()
    const { getUser } = useAuth()
    if (getUser()?.admin) {
        return <Navigate to={location.pathname.replace('app', 'admin')} />
    }
    if (getUser()?.tipster) {
        return <Navigate to={location.pathname.replace('app', 'tipster')} />
    } else {
        return props.children
    }
}

export default UserGuardedRoute
