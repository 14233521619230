import { Outlet } from 'react-router-dom'
import bg from '../../assets/field1.jpg'

const Home = () => {
    return (
        <div
            className="w-full h-screen bg-field bg-cover flex justify-center items-center"
            style={{ backgroundImage: `url(${bg})` }}
        >
            <Outlet />
        </div>
    )
}

export default Home
