import { Expose, Type } from 'class-transformer'
import { IsDate, IsOptional } from 'class-validator'

export type FlatPack = {
    name: 'flatpack'
    mesi: number
    euro: number
}

export type Sale = {
    name: 'sconto'
    percentage: number
}

export type Special = {
    name: 'speciale'
    betcoin: number
    price: number
}

export type OfferComposition = {
    percentage?: string
    duration?: string
    price?: string
    betcoins?: string
    soldOut?: boolean
}

export enum PackCode {
    starter = 'starter',
    medium = 'medium',
    large = 'large',
    sale = 'sale',
    refund = 'refund',
    special = 'special',
    welcome = 'welcome',
    flatpack = 'flatpack',
}

export enum OfferCode {
    sale = 'sale',
    refund = 'refund',
    special = 'special',
    welcome = 'welcome',
}

class PackModel {
    @Expose()
    @IsDate()
    @Type(() => Date)
    modifiedAt: Date

    @Expose()
    id: string

    @Expose()
    name: string

    @Expose()
    betcoins: number

    @Expose()
    price: number

    @IsOptional()
    code?: PackCode

    @IsOptional()
    offer?: boolean

    @IsOptional()
    active?: boolean

    @IsOptional()
    description?: string

    @IsOptional()
    offerComposition?: OfferComposition

    @IsOptional()
    discounted?: number

    @Expose()
    soldOut: boolean

    @Expose()
    img: string
}

export default PackModel

export interface UpdatePackDTO {
    betcoins: number
    price: number
    name: string
    soldOut: boolean
}
