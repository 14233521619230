import { ReactNode, useEffect } from 'react'
import { PageSize, useAppState } from '../context/AppState'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import routes from '../routing/routes'

const ROUTE_PAGE_SIZE: { [key: string]: PageSize } = {
    [routes.tipsterPronostici]: 'full',
    [routes.affiliatePronostici]: 'full',
    [routes.adminPronostici]: 'full',
}
const BodyContainer = (props: { children: ReactNode }) => {
    const { pageSize, setPageSize } = useAppState()
    const location = useLocation()
    useEffect(() => {
        const pageSize: PageSize =
            ROUTE_PAGE_SIZE[location.pathname] || 'default'
        setPageSize(pageSize)
    }, [location])
    return (
        <div
            className={classNames(
                'p-2 md:p-4 h-full w-full block lg:px-0 lg:mx-auto',
                {
                    'lg:w-[60rem]': pageSize == 'default',
                },
            )}
        >
            {props.children}
        </div>
    )
}

export default BodyContainer
