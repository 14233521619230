import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import 'reflect-metadata'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            {/* <React.StrictMode> */}
            <div className="bg-greyBg h-full">
                <App />
            </div>
            {/* </React.StrictMode> */}
        </BrowserRouter>
    </QueryClientProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
