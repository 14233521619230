import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOutletContext } from 'react-router-dom'
import Swal from 'sweetalert2'
import { UserProfileDataDTO } from '../../models/user-profile-data.dto'
import routes from '../../routing/routes'
import { buildRefererUrl } from '../../utils'
import { UserType } from '../../types'
import { useAuth } from '../../context/Auth'

const CopyReferral = (props: { username: string; userType: UserType }) => {
    return (
        <div
            onClick={() => {
                const refererURL = buildRefererUrl(
                    props.username,
                    props.userType,
                )
                if (window.isSecureContext) {
                    navigator.clipboard.writeText(refererURL).then(() => {
                        Swal.fire({
                            toast: true,
                            title: 'Copiato!',
                            position: 'bottom-right',
                            showConfirmButton: false,
                            showCloseButton: true,
                            timer: 2000,
                        })
                    })
                } else {
                    Swal.fire({
                        toast: true,
                        html: `<a href="${refererURL}">${refererURL}</a>`,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        showCloseButton: true,
                        // timer: 2000,
                    })
                }
            }}
            className="mr-auto select-none cursor-pointer text-xxs p-1 bg-primary/[.8] rounded text-black inline-block shadow hover:shadow-xl"
        >
            Copia il tuo url di referral {props.username}{' '}
            <FontAwesomeIcon icon={faCopy} />
        </div>
    )
}

const CopyAffiliateReferral = (props: {
    username: string
    userType: UserType
}) => {
    return (
        <div
            onClick={() => {
                const refererURL = buildRefererUrl(
                    props.username,
                    props.userType,
                )
                if (window.isSecureContext) {
                    navigator.clipboard.writeText(refererURL).then(() => {
                        Swal.fire({
                            toast: true,
                            title: 'Copiato!',
                            position: 'bottom-right',
                            showConfirmButton: false,
                            showCloseButton: true,
                            timer: 2000,
                        })
                    })
                } else {
                    Swal.fire({
                        toast: true,
                        html: `<a href="${refererURL}">${refererURL}</a>`,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        showCloseButton: true,
                        // timer: 2000,
                    })
                }
            }}
            className="mr-auto select-none cursor-pointer text-xxs p-1 bg-primary/[.8] rounded text-black inline-block shadow hover:shadow-xl"
        >
            Copia il tuo url di referral {props.username}{' '}
            <FontAwesomeIcon icon={faCopy} />
        </div>
    )
}

const ProfileHome = () => {
    const context: { user: UserProfileDataDTO } = useOutletContext()
    console.log(context)
    const { getUser } = useAuth()
    return (
        <>
            {context.user && (
                <div>
                    <CopyReferral
                        username={context.user.username}
                        userType={
                            getUser()?.affiliate
                                ? 'affiliate'
                                : getUser()?.tipster
                                ? 'tipster'
                                : 'user'
                        }
                    />
                </div>
            )}
        </>
    )
}

export default ProfileHome
