import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import tw from 'twin.macro'
import { useApi } from '../../../context/Api'
import { useAppState } from '../../../context/AppState'
import ForecastModel, {
    MultiForecastModel,
} from '../../../models/forecast.model'
import { pronosticoGrid } from '../../ForecastList'
import GridLayout, { ElemGrid } from '../../GridLayout'
import { MatchGrid, PriceTag, TipsterName } from '../common'
import Styled from '../Styled'
import { forecastsToList, forecastToString } from '../utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

export const teamGrid: ElemGrid = { cols: 2, rows: 1 }

const Quota = (props: { value: number }) => {
    return (
        <div className="text-center leading-tight">
            <div className="text-xxxs font-thin uppercase">Quota</div>
            <div className="font-bold text-lg text-primary">
                {props.value?.toFixed(2)}
            </div>
        </div>
    )
}

type Props = {
    forecast: ForecastModel | MultiForecastModel
}

const SingleForecast = (forecast: ForecastModel) => (
    <GridLayout
        padding={0}
        gap={0}
        elements={[
            {
                grid: { cols: 3, fullWidth: true },
                content: <MatchGrid match={forecast.match} />,
            },
            {
                grid: pronosticoGrid,
                content: forecastToString(forecast),
            },
        ]}
    />
)

const AdminSellingForecast = ({ forecast }: Props) => {
    const { deleteForecast } = useApi()
    const [softDeleted, setSoftDeleted] = useState(!!forecast.deletedOn)
    const getMatches = () => {
        if (forecast.multi) {
            return (forecast as MultiForecastModel).forecasts.map(
                (f) => f.match,
            )
        } else if (!forecast.multi) {
            return [(forecast as ForecastModel).match]
        } else {
            return []
        }
    }
    const onDeleteForecast = (id: string) => {
        Swal.fire({
            title: 'Sei sicuro?',
            text: 'Il pronostico non sarà piu visibile agli utenti.',
            showCancelButton: true,
        }).then(async (res) => {
            if (!res.isConfirmed) {
                return
            }
            await deleteForecast(id)
            setSoftDeleted(true)
        })
    }
    return (
        <Styled>
            <div className="absolute -top-1 left-0 right-0 flex justify-center items-start">
                {forecast.multi && (
                    <span className="bg-gray-900 rounded-md px-1 text-white text-[7px] leading-3 uppercase">
                        multipla
                    </span>
                )}
            </div>
            <div className="-ml-2 -mt-1 flex justify-between">
                <TipsterName creator={forecast.creator} />
                <div className="flex items-center gap-2">
                    {!!forecast.visuals && forecast.visuals > 0 && (
                        <div className="flex gap-1 items-center text-gray-200">
                            <span className="font-normal">
                                {forecast.visuals}
                            </span>
                            <FontAwesomeIcon
                                className="text-xxs"
                                icon={faEye}
                            />
                        </div>
                    )}
                    <button
                        disabled={softDeleted}
                        className="text-red-500 p-1 disabled:text-gray-500"
                        onClick={async () =>
                            await onDeleteForecast(forecast.id)
                        }
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            </div>

            <div className="flex mx-1">
                <div className="flex-1 flex flex-col">
                    <GridLayout
                        gap={0}
                        padding={0}
                        elements={[
                            {
                                grid: {
                                    cols: 4,
                                    rows: 1,
                                    lg: { rows: 1 },
                                    fullWidth: true,
                                },
                                content: (
                                    <div className=" divide-y divide-dashed divide-gray-600">
                                        {forecastsToList(forecast).map((f) => (
                                            <div key={f.id} className="py-1">
                                                {SingleForecast(f)}
                                            </div>
                                        ))}
                                    </div>
                                ),
                            },
                            {
                                grid: { rows: 2, cols: 1, lg: { rows: 1 } },
                                content: <Quota value={forecast.quotation} />,
                            },
                            {
                                grid: { rows: 2, cols: 1, lg: { rows: 1 } },
                                content: <PriceTag price={forecast.price} />,
                            },
                        ]}
                    />
                </div>
            </div>
        </Styled>
    )
}

export default AdminSellingForecast
