import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import GridLayout from '../../../components/GridLayout'
import ScrollableContent from '../../../components/ScrollableContent'
import TipsterElem from '../../../components/tipsters/TipsterElem'
import { PageTitle } from '../../../components/typography'
import { useAppState } from '../../../context/AppState'
import { useAuth } from '../../../context/Auth'
import { apiServiceInst } from '../../../services/Api'

const Tipsters = () => {
    const { getUser } = useAuth()
    const { switchLoading } = useAppState()
    const [isAdmin, setIsAdmin] = useState(false)
    useEffect(() => {
        setIsAdmin(!!getUser()?.admin)
    }, [])

    const tipstersQuery = useQuery('tipsters-chart', {
        queryFn: () => apiServiceInst.getTipstersChart({}),
        staleTime: Infinity,
    })

    useEffect(() => {
        switchLoading(tipstersQuery.isLoading)
    }, [tipstersQuery.isLoading])
    return (
        <div className="flex flex-col h-full px-1">
            <PageTitle>Tipsters</PageTitle>
            <GridLayout
                className="mb-1 pr-2"
                padding={0}
                elements={[
                    {
                        grid: { cols: 6, fullWidth: true },
                        content: '',
                    },

                    {
                        grid: { cols: 2 },
                        content: (
                            <div className="text-xxxs uppercase text-gray-400 text-center">
                                pronostici online
                            </div>
                        ),
                    },
                    {
                        grid: { cols: 2 },
                        content: (
                            <div className="text-xxxs uppercase text-gray-400 text-center">
                                % successo
                            </div>
                        ),
                    },
                    {
                        grid: { cols: 1 },
                        content: (
                            <div className="text-xxxs uppercase text-gray-400 text-center">
                                trend
                            </div>
                        ),
                    },
                ]}
            />
            <ScrollableContent>
                <div className="flex flex-col gap-2">
                    {tipstersQuery.data?.data.map((t, i) => (
                        <Link
                            key={t.id}
                            to={t.username}
                            state={{
                                stats: {
                                    success: t.successRate,
                                    total: t.singleForecasts + t.multiForecasts,
                                    selling: 0,
                                },
                            }}
                        >
                            <TipsterElem
                                rank={i + 1}
                                tipsterData={t}
                                adminView={isAdmin}
                            />
                        </Link>
                    ))}
                </div>
            </ScrollableContent>
        </div>
    )
}

export default Tipsters
