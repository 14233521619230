import { format } from 'date-fns'
import MatchModel from '../../../models/match.model'
import { zonedDate } from '../../../utils'
import Styled from '../../../components/Forecast/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { MatchGrid } from '../../../components/Forecast/common'

type Props = {
    match: MatchModel
    idx?: number
    onPostpone?: () => void
    onEdit?: () => void
    onDelete?: () => void
}

const MatchElement = (props: Props) => {
    return (
        <Styled>
            <div
                className={
                    'flex text-xs justify-between items-center text-left cursor-pointer'
                }
            >
                <div className="grow">
                    <MatchGrid match={props.match} />
                    {props.match.status && (
                        <div className="uppercase text-xxs rounded bg-secondary-200 shadow px-1 inline-block font-bold">
                            {props.match.status}
                        </div>
                    )}
                </div>
                <div className="flex gap-2 text-sm">
                    {props.onPostpone && (
                        <button className="rounded p-1 bg-blue-500 text-black shadow">
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faClock}
                                onClick={props.onPostpone}
                            />
                        </button>
                    )}
                    <button className="rounded p-1 bg-yellow-500 text-black shadow">
                        <FontAwesomeIcon
                            fixedWidth
                            icon={faPencil}
                            onClick={props.onEdit}
                        />
                    </button>
                    <button className="rounded p-1 bg-red-700 text-black shadow">
                        <FontAwesomeIcon
                            fixedWidth
                            icon={faTrash}
                            onClick={props.onDelete}
                        />
                    </button>
                </div>
            </div>
        </Styled>
    )
}

export default MatchElement
