import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getProfilePictureUrl } from '../../../utils'
import classNames from 'classnames'
type Props = {
    onClick: () => void
    name: string
    children?: React.ReactChild
    profilePicture?: string
    showProfilePicture?: boolean
}
const ListElem = (props: Props) => (
    <div
        className={classNames('rounded bg-secondary-400 text-white px-2 py-1', {
            'flex gap-2 items-center': props.showProfilePicture,
        })}
    >
        {props.showProfilePicture && (
            <img
                className="rounded w-8 h-8"
                src={getProfilePictureUrl(props.profilePicture)}
            />
        )}
        <div className="flex justify-between items-center grow">
            <div>
                <span className="font-bold text-sm">{props.name}</span>
                <div>{props.children}</div>
            </div>
            <button
                className="w-6 rounded-full bg-secondary-400"
                onClick={props.onClick}
            >
                <FontAwesomeIcon fixedWidth icon={faEllipsisV} />
            </button>
        </div>
    </div>
)

export default ListElem
