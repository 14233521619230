import { ChangeEvent } from 'react'
import Input from './Input'
import Label from './styled/Label'

type Props = {
    label: string
    type: React.ComponentProps<typeof Input>['type']
    value?: string | number
    error?: string
    placeholder?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    className?: React.HTMLAttributes<HTMLDivElement>['className']
    disabled?: boolean
    maxLength?: number
}

const InputField = (props: Props) => {
    return (
        <div className={props.className}>
            <Label>{props.label}</Label>
            <Input
                maxLength={props.maxLength}
                disabled={props.disabled}
                type={props.type}
                placeholder={props.placeholder}
                className="h-10 font-normal w-full rounded shadow border-1 border-primary/[.15] focus:border-primary"
                value={props.value}
                onChange={props.onChange}
            />
            {props.error && (
                <span className="text-xxs text-red-500">{props.error}</span>
            )}
        </div>
    )
}

export default InputField
