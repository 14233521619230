import { format } from 'date-fns'
import MatchModel from '../../models/match.model'

const InfoMatchRow = (props: {
    left: { label: string; value: string }
    right: { label: string; value: string }
}) => {
    return (
        <div className="flex justify-center text-sm py-8 border-b  border-white/[.05]">
            <div className="flex-1 flex justify-center items-center gap-4">
                <span className="flex-1 text-right font-thin">
                    {props.left.label}:
                </span>
                <span className="flex-1 text-left text-primary">
                    {props.left.value}
                </span>
            </div>
            <div className="flex-1 flex justify-center items-center gap-4">
                <span className="flex-1 text-right font-thin">
                    {props.right.label}:
                </span>
                <span className="flex-1 text-left text-primary">
                    {props.right.value}
                </span>
            </div>
        </div>
    )
}

const InfoMatch = ({ match }: { match: MatchModel }) => {
    return (
        <div>
            <div className="">
                <InfoMatchRow
                    left={{ label: 'In Casa', value: match.homeTeam }}
                    right={{ label: 'Ospiti', value: match.awayTeam }}
                />
                <InfoMatchRow
                    left={{ label: 'Paese', value: '' }}
                    right={{ label: 'Paese', value: '' }}
                />

                <InfoMatchRow
                    left={{ label: 'Cod. Match', value: '' }}
                    right={{ label: 'Lega', value: match.league }}
                />

                <InfoMatchRow
                    left={{
                        label: 'Data',
                        value: format(new Date(match.date), 'dd/MM/yyyy'),
                    }}
                    right={{
                        label: 'Ora',
                        value: format(new Date(match.date), 'HH:mm'),
                    }}
                />
            </div>
        </div>
    )
}

export default InfoMatch
