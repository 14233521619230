export enum TransactionType {
    GIFT = 'gift',
    REFILL = 'refill',
    WITHDRAW = 'withdraw',
    REFUND = 'refund',
}

export interface TransactionModel {
    id?: string
    userId: string
    tipsterId?: string
    pack?: object
    packId?: string
    amount: number
    betcoins: number
    status: string
    type: TransactionType
    createdAt?: string
}
