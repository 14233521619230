import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import BodyContainer from '../../components/BodyContainer'
import Nav from '../../components/Nav'
import UserBar from '../../components/UserBar'
import { useApi } from '../../context/Api'
import { useAuth } from '../../context/Auth'
import tipsterNavItems from '../tipster/nav-items'
import adminNavItems from './nav-items'

const Admin = () => {
    const { user, logout } = useAuth()
    const { loginCheck } = useApi()
    useEffect(() => {
        loginCheck().then((res) => null)
    }, [])
    return (
        <div className="bg-greyBg h-screen flex flex-col">
            <Nav
                items={
                    user?.admin
                        ? adminNavItems
                        : user?.tipster
                        ? tipsterNavItems
                        : []
                }
            ></Nav>
            {user?.tipster && (
                <div className="py-[1px] text-center bg-primary/25 text-white font-bold text-xxs">
                    TIPSTER
                </div>
            )}
            <UserBar />
            <BodyContainer>
                <Outlet />
            </BodyContainer>
        </div>
    )
}

export default Admin
