import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosError } from 'axios'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import Button from '../Button'
import { useApi } from '../../context/Api'
import { useAppState } from '../../context/AppState'
import { UserProfileDataDTO } from '../../models/user-profile-data.dto'
import { getProfilePictureUrl } from '../../utils'

const MAX_SIZE =
    parseInt(process.env.REACT_APP_PROFILE_PICTURE_MAX_SIZE_MB || '5') *
    Math.pow(10, 6)
const UploadProfilePicture = (props: { preview?: string }) => {
    const location = useLocation() as { state: { user: UserProfileDataDTO } }
    const [preview, setPreview] = useState<string | null>(
        getProfilePictureUrl(
            props.preview || location.state?.user.profilePicture,
        ) || null,
    )
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const { uploadProfilePicture } = useApi()
    const { switchLoading } = useAppState()
    const onSubmit = async (data: any) => {
        const formData = new FormData()
        formData.append('file', data.file[0])

        switchLoading(true)
        const response = await uploadProfilePicture(formData)
        switchLoading(false)

        if (response.request.status <= 201) {
            Swal.fire({ title: 'Immagine caricata!' })
            window.history.replaceState({}, document.title)
        } else if (response.request.status == 400) {
            // TODO: fix error message
            Swal.fire({ title: 'File non valido' })
        }
    }
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    {preview && (
                        <img
                            className="w-full aspect-square object-cover rounded-full mx-auto my-4"
                            src={preview}
                        />
                    )}
                    <label
                        className="border mb-1 border-primary-800 px-3 py-2 rounded font-thin text-xs shadow w-full block text-center"
                        htmlFor="file-upload"
                    >
                        <FontAwesomeIcon
                            className="mr-1"
                            icon={faCloudArrowUp}
                        />
                        Seleziona immagine
                    </label>
                    <input
                        id="file-upload"
                        className="hidden"
                        type="file"
                        accept="image/*"
                        {...register('file', {
                            required: true,
                            validate: (value: FileList) => {
                                if (value.length == 0) {
                                    return 'Seleziona un file.'
                                }
                                if (value[0].size > MAX_SIZE) {
                                    return `Dimensione massima consentita ${
                                        MAX_SIZE / Math.pow(10, 6)
                                    } mb.`
                                }
                                if (!value[0].type.match(/image\/.*/)) {
                                    return 'Tipo di file non valido. Seleziona una immagine.'
                                }
                                return true
                            },

                            onChange: (e) => {
                                const file = e.currentTarget.files[0]
                                setPreview(URL.createObjectURL(file))
                            },
                        })}
                    />
                </div>
                {errors.file && (
                    <span className="text-xxs text-red-500">
                        {errors.file.message?.toString()}
                    </span>
                )}

                <Button type="submit" color="primary" className="w-full">
                    Carica
                </Button>
            </form>
        </div>
    )
}

export default UploadProfilePicture
