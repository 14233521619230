import {
    faBoltLightning,
    faClipboardList,
    faClock,
    faTachometerAlt,
} from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Nav from '../../components/Nav'
import UserBar from '../../components/UserBar'
import { useApi } from '../../context/Api'
import { useAppState } from '../../context/AppState'
import userNavItems from './nav-items'
import BodyContainer from '../../components/BodyContainer'

const Client = () => {
    const { setBalance } = useAppState()
    const { getBalance, loginCheck } = useApi()

    useEffect(() => {
        loginCheck().then((res) => null)
        getBalance().then((value) => setBalance(value))
    }, [])
    return (
        <div className="bg-greyBg h-full flex flex-col">
            <Nav items={userNavItems} />
            <UserBar />
            <BodyContainer>
                <Outlet />
            </BodyContainer>
        </div>
    )
}

export default Client
