import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import tw from 'twin.macro'
import { useApi } from '../../context/Api'
import { useAppState } from '../../context/AppState'
import {
    TransactionModel,
    TransactionType,
} from '../../models/transaction.model'
import User from '../../models/user.model'
import Button from '../Button'
import Input from '../Input'
import ScrollableContent from '../ScrollableContent'
import { SectionTitle } from '../typography'

const Badge = ({ type }: { type: TransactionType }) => {
    const bgColor =
        type == TransactionType.GIFT || type == TransactionType.REFUND
            ? 'bg-yellow-600'
            : type == TransactionType.REFILL
            ? 'bg-green-600'
            : 'bg-red-600'
    return (
        <div
            className={`rounded text-xxs font-bold px-1 py-[2px] inline-block ${bgColor}`}
        >
            {TRANSACTION_TYPE_MAP[type]}
        </div>
    )
}

const TRANSACTION_TYPE_MAP = {
    [TransactionType.GIFT]: 'Bonus',
    [TransactionType.REFILL]: 'Ricarica',
    [TransactionType.WITHDRAW]: 'Acquisto',
    [TransactionType.REFUND]: 'Rimborso',
}

const UserTransactions = ({
    user,
    onUpdate,
}: {
    user: User
    onUpdate?: (user: User) => void
}) => {
    const { giveBC, getUserTransactions } = useApi()
    const { toggleModal } = useAppState()
    const [amount, setAmount] = useState(0)
    const [transactions, setTransactions] = useState<TransactionModel[]>([])

    useEffect(() => {
        getUserTransactions(user.id).then((res) => {
            setTransactions(res)
        })
    }, [])
    return (
        <div className="flex flex-col md:flex-row items-start gap-2 h-full">
            <SectionTitle>Assegna BC</SectionTitle>
            <div className="flex items-center gap-2">
                <Input
                    value={amount}
                    type="number"
                    onChange={(e) => setAmount(parseInt(e.target.value))}
                    className="flex-1 h-10"
                />
                <Button
                    disabled={!amount}
                    onClick={() =>
                        Swal.fire({
                            showCancelButton: true,
                            showConfirmButton: true,
                            title: 'Confermi?',
                            text: `Vuoi assegnare ${amount} BC?`,
                        }).then((res) => {
                            if (res.isConfirmed) {
                                giveBC(amount, user.id).then((res) => {
                                    toggleModal(false)
                                    const newUser = { ...user }
                                    newUser.balance += amount
                                    console.log(
                                        amount,
                                        newUser.balance,
                                        newUser,
                                    )
                                    onUpdate && onUpdate(newUser)
                                    const newTransactions: TransactionModel[] =
                                        [
                                            {
                                                betcoins: amount,
                                                createdAt:
                                                    new Date().toISOString(),
                                                type: TransactionType.GIFT,
                                                amount: 0,
                                                status: 'closed',
                                                userId: user.id,
                                            },
                                            ...transactions,
                                        ]
                                    setTransactions(newTransactions)
                                    setAmount(0)
                                })
                            }
                        })
                    }
                >
                    <FontAwesomeIcon icon={faCheck} />
                </Button>
            </div>
            {/* <Hr /> */}
            <SectionTitle>Movimenti</SectionTitle>
            <ScrollableContent className="grow h-full">
                <div className="divide-y divide-white/[.15]">
                    {transactions.map((t, i) => (
                        <div className="flex items-center gap-2 py-1" key={i}>
                            <div className="w-12">
                                <Badge type={t.type} />
                            </div>
                            <span>{t.betcoins} BC</span>
                            <span className="text-xxs flex-1 text-right">
                                {(t.createdAt &&
                                    format(
                                        new Date(t.createdAt),
                                        'dd/LL/yyyy',
                                    )) ||
                                    ''}
                            </span>
                        </div>
                    ))}
                </div>
            </ScrollableContent>
        </div>
    )
}

export default UserTransactions

const StyledDiv = styled.div.attrs((props) => ({
    className:
        props.className +
        ' w-full md:w-auto border border-primary/[.2] shadow-lg rounded p-2',
}))`
    & {
        h3 {
            ${tw`mb-2`}
        }
    }
`
