import { utcToZonedTime } from 'date-fns-tz'
import { logo } from './constants'
import routes from './routing/routes'
import { UserType } from './types'

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/
export const USERNAME_REGEX = /^(?! )[^\s](?!.* $)[\dA-Za-z_ .]{1,18}[^\s]$/i
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

export const zonedDate = (date: Date | string | number): Date =>
    utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone)

export const validatePassword = (password: string) =>
    password.match(PASSWORD_REGEX)

export const getProfilePictureUrl = (filename?: string) =>
    filename
        ? process.env.REACT_APP_PROFILE_PICTURES_BASE_URL + '/' + filename
        : logo

export const buildRefererUrl = (username: string, userType: UserType) =>
    process.env.REACT_APP_URL +
    (userType === 'tipster'
        ? routes.register
        : userType === 'affiliate'
        ? routes.registerTipster
        : '') +
    '?referer=' +
    encodeURIComponent(username)

export const buildAffiliateRefererUrl = (
    username: string,
    userType: UserType,
) =>
    process.env.REACT_APP_URL +
    (userType === 'tipster'
        ? routes.register
        : userType === 'affiliate'
        ? routes.registerTipster
        : '') +
    '?referer=' +
    encodeURIComponent(username)
