export const PAYMENT_METHODS: { [k: string]: string } = {
    paypal: 'Paypal',
    neteller: 'Neteller',
    skrill: 'Skrill',
    // bonifico: 'Bonifico bancario',
    none: 'Nessuno',
}

export const logo =
    'https://ytip-assets.s3.eu-central-1.amazonaws.com/LogoYT-512.png'

export const SUCCESS_THRESHOLD = 50

export enum ApiErrorCode {
    PAYPAL_API_ERROR = 'PAYPAL_API_ERROR',
    NO_TIPSTER_PAYPAL_ID = 'NO_TIPSTER_PAYPAL_ID',
    NO_TIPSTER_NETELLER = 'NO_TIPSTER_NETELLER',
    NO_TIPSTER_SKRILL = 'NO_TIPSTER_SKRILL',
    NO_TIPSTER_PAYMENT_METHODS = 'NO_TIPSTER_PAYMENT_METHODS',
    FORECASTS_LIMIT_EXCEEDED = 'FORECASTS_LIMIT_EXCEEDED',
    ONE_FORECAST_PER_MATCH = 'ONE_FORECAST_PER_MATCH',
}

export const API_ERROR_MESSAGE: { [key in ApiErrorCode]?: string } = {
    [ApiErrorCode.ONE_FORECAST_PER_MATCH]:
        'Non è consentito pubblicare più di un pronostico singola o multipla con gli stessi eventi.',
    [ApiErrorCode.FORECASTS_LIMIT_EXCEEDED]:
        'Hai raggiunto il limite massimo di pronostici.',
}
