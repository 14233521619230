import { useState } from 'react'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import { useApi } from '../../../context/Api'
import { useAppState } from '../../../context/AppState'
import { ManualOutcomeDTO } from '../../../models/manual-outcome.dto'

const InputControl = (props: {
    label: string
    value: number
    onChange: (value: number) => void
}) => {
    return (
        <div className="my-2 w-full md:w-1/2 flex flex-col md:flex-row items-center">
            <div className="mb-2 md:mb-0 text-xs text-center md:text-right w-full mr-2 md:w-2/3">
                {props.label}
            </div>
            <Input
                type="number"
                value={props.value}
                onChange={(e) => props.onChange(parseInt(e.target.value))}
                className="md:w-16"
            />
        </div>
    )
}

const ManualOutcomeForm = ({
    matchID,
    onSubmit,
}: {
    matchID: number
    onSubmit: () => void
}) => {
    const [goals, setGoals] = useState<ManualOutcomeDTO>({
        homeTeamScore: 0,
        homeTeamScoreHalfTime: 0,
        awayTeamScore: 0,
        awayTeamScoreHalfTime: 0,
    })
    const [error, setError] = useState('')

    const update = (value: number, field: keyof ManualOutcomeDTO) => {
        const newGoals = { ...goals, [field]: value }

        setGoals(newGoals)
    }

    const { setManualOutcome } = useApi()
    const { toggleModal } = useAppState()
    const check = () => {
        return (
            goals.awayTeamScore >= goals.awayTeamScoreHalfTime &&
            goals.homeTeamScore >= goals.homeTeamScoreHalfTime
        )
    }
    return (
        <div className="flex flex-col h-full">
            <div>
                <div className="flex flex-col md:flex-row">
                    <InputControl
                        value={goals.homeTeamScoreHalfTime}
                        onChange={(value) =>
                            update(value, 'homeTeamScoreHalfTime')
                        }
                        label="Goal in casa primo tempo"
                    />
                    <InputControl
                        value={goals.awayTeamScoreHalfTime}
                        onChange={(value) =>
                            update(value, 'awayTeamScoreHalfTime')
                        }
                        label="Goal fuori casa primo tempo"
                    />
                </div>
                <div className="flex flex-col md:flex-row">
                    <InputControl
                        value={goals.homeTeamScore}
                        onChange={(value) => update(value, 'homeTeamScore')}
                        label="Goal in casa fine partita"
                    />
                    <InputControl
                        value={goals.awayTeamScore}
                        onChange={(value) => update(value, 'awayTeamScore')}
                        label="Goal fuori casa fine partita"
                    />
                </div>
            </div>
            {error && (
                <span className="text-xxs text-red-500 text-center">
                    {error}
                </span>
            )}
            <Button
                onClick={() => {
                    if (!check()) {
                        setError('Valori inseriti non validi, ricontrolla.')
                        return
                    }

                    setManualOutcome(matchID, goals).then((res) => {
                        onSubmit()
                        toggleModal(false)
                    })
                }}
                color="primary"
                className="mt-4 w-full"
            >
                Salva
            </Button>
        </div>
    )
}
export default ManualOutcomeForm
