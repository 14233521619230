import { createContext, useContext, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'

type AuthContextType = {
    getJWT: () => string | null
    setJWT: (jwt: string) => void
    check: () => boolean
    user: JWTPayload | null
    decodeJWT: (jwt: string) => JWTPayload
    logout: () => void
    authenticated: boolean
    getUser: () => JWTPayload | null
}

type Props = {
    children: JSX.Element
}

export const AuthContext = createContext<AuthContextType | null>(null)

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext)

    if (!context) {
        throw Error('Wrong usage')
    }
    return context
}
export type UserPermissions = {
    fullForecastsHistoryAccess?: boolean
}
export type JWTPayload = {
    userId: string
    username: string
    admin: boolean
    tipster: boolean
    affiliate: boolean
    betcoins: number
    emailConfirmed: boolean
    lastLogin: string
    permissions?: UserPermissions
}

export const AuthContextProvider = ({ children }: Props) => {
    const [user, setUser] = useState<JWTPayload | null>(null)
    const [authenticated, setAuthenticated] = useState(false)
    const setSession = (jwt: string) => {
        const decoded: JWTPayload = jwt_decode(jwt)
        setUser(decoded)
        setAuthenticated(true)
    }
    useEffect(() => {
        const jwt = localStorage.getItem('jwt')
        jwt && setSession(jwt)
    }, [])
    return (
        <AuthContext.Provider
            value={{
                getJWT: () => localStorage.getItem('jwt'),
                setJWT: (jwt: string) => {
                    localStorage.setItem('jwt', jwt)
                    setSession(jwt)
                },
                check: () => {
                    const jwt = localStorage.getItem('jwt')
                    if (jwt) {
                        setSession(jwt)
                        return true
                    } else {
                        // navigte(routes.login, {})
                        return false
                    }
                },
                user,
                decodeJWT: (jwt: string) => {
                    const decoded: JWTPayload = jwt_decode(jwt)
                    return decoded
                },
                logout: () => {
                    localStorage.removeItem('jwt')
                    setUser(null)
                    setAuthenticated(false)
                },
                authenticated,
                getUser: () => {
                    const jwt = localStorage.getItem('jwt')
                    if (jwt) {
                        const decoded: JWTPayload = jwt_decode(jwt)
                        return decoded
                    } else {
                        return null
                    }
                },
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
