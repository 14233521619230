import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import format from 'date-fns-tz/format'
import { useState } from 'react'
import GridLayout from '../../components/GridLayout'
import Hr from '../../components/styled/Hr'
import { useAuth } from '../../context/Auth'
import TipsterMovement from '../../models/tipster-movement.model'
import { zonedDate } from '../../utils'
import { apiServiceInst } from '../../services/Api'
import { useQuery } from 'react-query'
import { PageTitle } from '../../components/typography'
import CommissionsList from '../../components/commissions/CommissionsList'
const DateTag = ({ date }: { date: string }) => (
    <div className="w-full text-left text-white/[.8] pl-2 text-xxxs">
        {format(zonedDate(date), 'dd/MM/yyyy')}
    </div>
)

const PayoutRow = ({ movimento }: { movimento: TipsterMovement }) => (
    <div className="w-full bg-green-700/[.2] p-1 px-2">
        <DateTag date={movimento.date} />
        <GridLayout
            className="w-full"
            padding={0}
            elements={[
                {
                    grid: {
                        cols: 3,
                        rows: 1,
                        fullWidth: true,
                        md: {
                            cols: 4,
                        },
                    },
                    content: (
                        <div className="uppercase text-xs p-1 bg-green-700 rounded-sm text-center inline mr-auto">
                            pagato
                        </div>
                    ),
                },

                {
                    grid: { fullWidth: true, cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">totale</div>
                            <div className="font-bold">
                                {movimento.amount.toFixed(2)} €
                            </div>
                        </div>
                    ),
                },
            ]}
        />
    </div>
)

const CommissionRow = ({ movimento }: { movimento: TipsterMovement }) => (
    <div className="w-full bg-slate-700/[.2] p-1 px-2">
        <DateTag date={movimento.date} />
        <GridLayout
            className="w-full"
            padding={0}
            elements={[
                {
                    grid: {
                        cols: 1,
                        rows: 1,
                        hidden: true,
                        fullWidth: true,
                        sm: { hidden: false },
                    },
                    content: (
                        <div className="uppercase text-xs p-1 bg-slate-700 rounded-sm text-center inline mr-auto">
                            Commissioni
                        </div>
                    ),
                },
                {
                    grid: { cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="text-xs leading-tight flex items-center gap-1">
                                <FontAwesomeIcon
                                    className="text-xxs"
                                    icon={faUser}
                                />
                                {movimento.username}
                            </div>
                        </div>
                    ),
                },
                {
                    grid: { cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">
                                valore pack
                            </div>
                            <div className="md:text-md text-xs">
                                {movimento.amount.toFixed(2)} €
                            </div>
                        </div>
                    ),
                },
                {
                    grid: { cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">percent</div>
                            <div className="text-xs">
                                {movimento.commission}%
                            </div>
                        </div>
                    ),
                },

                {
                    grid: { fullWidth: true, cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">totale</div>
                            <div className="font-bold">
                                {(
                                    (movimento.amount * movimento.commission) /
                                    100
                                ).toFixed(2)}{' '}
                                €
                            </div>
                        </div>
                    ),
                },
            ]}
        />
    </div>
)

const Movimenti = () => {
    const { getUser } = useAuth()
    const [commissionDue, setCommissionDue] = useState<number>(0)
    const totalDueQuery = useQuery(
        ['commission/total-due', getUser()!.userId],
        () => apiServiceInst.getTipsterCommissionDue(getUser()!.userId),
        { onSuccess: (data) => setCommissionDue(data.total) },
    )

    return (
        <div className="flex flex-col h-full gap-0 relative">
            <div className="text-left">
                <div className="text-primary-600 text-xs font-bold">
                    Il tuo saldo
                </div>
                <div className="text-3xl text-white font-bold">
                    {commissionDue.toFixed(2)} €
                </div>
            </div>
            <Hr my={1} />
            <div className="grow h-full flex flex-col">
                <div className="flex">
                    <PageTitle>Movimenti</PageTitle>
                </div>
                <CommissionsList tipsterId={getUser()!.userId} />
            </div>
        </div>
    )
}

export default Movimenti
