import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import format from 'date-fns-tz/format'
import { useEffect, useState } from 'react'
import Box from '../components/Box'
import GridLayout from '../components/GridLayout'
import TipsterTransactions from '../components/Tipster/TipsterTransactions'
import { useApi } from '../context/Api'
import { useAuth } from '../context/Auth'
import TipsterMovement from '../models/tipster-movement.model'
import { zonedDate } from '../utils'
import { useQuery } from 'react-query'
import { apiServiceInst } from '../services/Api'

const DateTag = ({ date }: { date: string }) => (
    <div className="w-full text-left text-white/[.8] pl-2 text-xxxs">
        {format(zonedDate(date), 'dd/MM/yyyy')}
    </div>
)

const PayoutRow = ({ movimento }: { movimento: TipsterMovement }) => (
    <div className="w-full bg-green-700/[.2] p-1 px-2">
        <DateTag date={movimento.date} />
        <GridLayout
            className="w-full"
            padding={0}
            elements={[
                {
                    grid: {
                        cols: 3,
                        rows: 1,
                        fullWidth: true,
                        md: {
                            cols: 4,
                        },
                    },
                    content: (
                        <div className="uppercase text-xs p-1 bg-green-700 rounded-sm text-center inline mr-auto">
                            pagato
                        </div>
                    ),
                },

                {
                    grid: { fullWidth: true, cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">totale</div>
                            <div className="font-bold">
                                {movimento.amount.toFixed(2)} €
                            </div>
                        </div>
                    ),
                },
            ]}
        />
    </div>
)

const CommissionRow = ({ movimento }: { movimento: TipsterMovement }) => (
    <div className="w-full bg-slate-700/[.2] p-1 px-2">
        <DateTag date={movimento.date} />
        <GridLayout
            className="w-full"
            padding={0}
            elements={[
                {
                    grid: {
                        cols: 1,
                        rows: 1,
                        hidden: true,
                        fullWidth: true,
                        sm: { hidden: false },
                    },
                    content: (
                        <div className="uppercase text-xs p-1 bg-slate-700 rounded-sm text-center inline mr-auto">
                            Commissioni
                        </div>
                    ),
                },
                {
                    grid: { cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="text-xs leading-tight flex items-center gap-1">
                                <FontAwesomeIcon
                                    className="text-xxs"
                                    icon={faUser}
                                />
                                {movimento.username}
                            </div>
                        </div>
                    ),
                },
                {
                    grid: { cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">
                                valore pack
                            </div>
                            <div className="md:text-md text-xs">
                                {movimento.amount.toFixed(2)} €
                            </div>
                        </div>
                    ),
                },
                {
                    grid: { cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">percent</div>
                            <div className="text-xs">
                                {movimento.commission}%
                            </div>
                        </div>
                    ),
                },

                {
                    grid: { fullWidth: true, cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">totale</div>
                            <div className="font-bold">
                                {(
                                    (movimento.amount * movimento.commission) /
                                    100
                                ).toFixed(2)}{' '}
                                €
                            </div>
                        </div>
                    ),
                },
            ]}
        />
    </div>
)

const PackPurchaseRevenues = () => {
    const { getTipsterCommissionDue } = useApi()
    const { getUser } = useAuth()
    const [commissionDue, setCommissionDue] = useState<number>(0)

    const totalDueQuery = useQuery(['total-due', getUser()?.userId], () =>
        apiServiceInst.getTotalDue(),
    )
    useEffect(() => {
        getTipsterCommissionDue(getUser()?.userId).then(async (res) => {
            let val = commissionDue
            while (val + 1 < res) {
                const newVal = await new Promise<number>((res) => {
                    setTimeout(() => res((val + 1) * 1.1), 20)
                })
                setCommissionDue(newVal)
                val = newVal
            }
            setCommissionDue(res)
        })
    }, [])
    return (
        <div className="flex flex-col md:flex-row h-full md:items-start gap-2">
            <Box className="md:flex-[.3]" title="Il tuo saldo">
                <div className="p-4 flex-[.3] text-center">
                    {totalDueQuery.data && (
                        <div className="text-4xl">
                            {totalDueQuery.data?.total.toFixed(2)} €
                        </div>
                    )}
                </div>
            </Box>
            <Box className="h-full flex-1" title="Movimenti" scrollable>
                <TipsterTransactions tipsterId={getUser()?.userId!} />
            </Box>
        </div>
    )
}

export default PackPurchaseRevenues
