import {
    faBank,
    faBoltLightning,
    faClock,
    faGlobe,
    faRankingStar,
    faTachometerAlt,
    faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { NavItem } from '../../components/Nav'

const tipsterNavItems: NavItem[] = [
    // {
    //     text: 'Dashboard',
    //     icon: faTachometerAlt,
    //     to: 'dashboard',
    // },
    {
        text: 'Pronostici',
        icon: faBoltLightning,
        to: 'pronostici',
    },
    {
        text: 'Storico',
        icon: faClock,
        to: 'storico',
    },
    {
        text: 'Tipsters',
        icon: faUsers,
        to: 'tipsters',
        golden: true,
    },
    {
        text: 'Utenti',
        icon: faUsers,
        to: 'utenti',
    },
    {
        text: 'Saldo e Movimenti',
        icon: faBank,
        to: 'movimenti',
    },
]

export default tipsterNavItems
