import Button from './Button'
import ScrollableContent from './ScrollableContent'

type Props = {
    title?: string
    children?: React.ReactNode
    style?: React.CSSProperties
    className?: string
    scrollable?: boolean
    headerRightAction?: React.ReactNode
    headerLeftAction?: React.ReactNode
}

const Box = ({
    title,
    children,
    className,
    style,
    scrollable,
    headerLeftAction,
    headerRightAction,
}: Props) => {
    return (
        <div
            className={
                'relative overflow-hidden flex flex-col bg-box text-white border-black/[.15] rounded-lg shadow ' +
                className
            }
            style={style}
        >
            <div
                className="shrink-0 h-12 lg:h-16 flex justify-center items-center 
                bg-black/[.05] border-b border-white/[.03]"
            >
                <div className="flex-1 pl-2 flex justify-start">
                    {headerLeftAction}
                </div>
                <h2 className="flex-grow text-primary text-sm text-center">
                    {title}
                </h2>
                <div className="flex-1 pr-2 flex justify-start">
                    {headerRightAction}
                </div>
            </div>
            {scrollable ? (
                <div className="relative grow">
                    <div
                        className={
                            'overflow-y-auto top-0 bottom-0 left-0 right-0 absolute'
                        }
                    >
                        {children}
                    </div>
                </div>
            ) : (
                <div className="grow overflow-y-scroll">{children}</div>
            )}
        </div>
    )
}

export default Box
