import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../context/Auth'

const NotLoggedGuardedRoute = () => {
    const { getJWT, decodeJWT } = useAuth()
    const jwt = getJWT()
    if (jwt) {
        const decoded = decodeJWT(jwt)
        if (decoded.admin) {
            return <Navigate to="/admin" />
        } else if (decoded.tipster) {
            return <Navigate to="/tipster" />
        } else {
            return <Navigate to="/app" />
        }
    } else {
        return <Outlet />
    }
}

export default NotLoggedGuardedRoute
