import { useEffect, useState } from 'react'
import Box from '../../components/Box'
import GridLayout from '../../components/GridLayout'
import UtentiRow from '../../components/users/UtentiRow'
import { useApi } from '../../context/Api'
import { useAppState } from '../../context/AppState'
import User from '../../models/user.model'

const UtentiPageTipster = () => {
    const { switchLoading } = useAppState()
    const [users, setUsers] = useState<User[]>([])
    const { getUsers } = useApi()

    const getData = async () => {
        switchLoading(true)
        const data = await getUsers()
        setUsers(data)
        switchLoading(false)
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div className="flex flex-col md:flex-row gap-1 h-full">
            <Box title="Utenti" className="flex-1" scrollable>
                <div className="text-xxs bg-black/[.5] text-white/[.55] lg:p-2">
                    <GridLayout
                        elements={[
                            {
                                grid: {
                                    cols: 1,
                                    rows: 6,
                                    hidden: true,
                                    lg: { rows: 1 },
                                },
                                content: <></>,
                            },
                            {
                                grid: { cols: 2, rows: 2, lg: { rows: 1 } },
                                content: <div>USERNAME</div>,
                            },
                            {
                                grid: { cols: 2, rows: 2, lg: { rows: 1 } },
                                content: <div>NOME</div>,
                            },
                            {
                                grid: { cols: 2, rows: 2, lg: { rows: 1 } },
                                content: <div>COGNOME</div>,
                            },
                            {
                                grid: { cols: 1, rows: 3, lg: { rows: 1 } },
                                content: <div>CREDITO</div>,
                            },
                        ]}
                    />
                </div>
                {users.map((u, i) => (
                    <UtentiRow key={i} user={u} index={i} />
                ))}
            </Box>
        </div>
    )
}

export default UtentiPageTipster
