import { useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import Input from '../../components/Input'
import Modal from '../../components/Modal'
import routes from '../../routing/routes'
import Hr from '../../components/styled/Hr'
import useLogin from '../../hooks/useLogin'
import { logo } from '../../constants'

const LoginForm = () => {
    const login = useLogin()
    const [data, setData] = useState<{
        username: string | null
        password: string | null
    }>({ username: null, password: null })
    const [error, setError] = useState('')
    const onSubmit = async () => {
        if (!data.username || !data.password) {
            return
        }
        try {
            await login(data.username, data.password)
        } catch (error) {
            setError('Username o password errati')
        }
    }
    return (
        <Modal className="w-4/5 md:w-[360px]">
            <img src={logo} className="w-32 mx-auto my-4" />
            <div className="p-4 mx-auto">
                <div className="mb-8">
                    <label className="font-light text-xs">Username</label>
                    <Input
                        className="w-full"
                        onChange={(e) =>
                            setData({ ...data, username: e.target.value })
                        }
                        // placeholder="Inserisci lo username"
                        // style={{ width: '350px' }}
                    />
                </div>

                <div className="mb-8">
                    <label className="font-light text-xs">Password</label>
                    <Input
                        className="w-full"
                        type="password"
                        onChange={(e) =>
                            setData({ ...data, password: e.target.value })
                        }
                    />
                </div>
                {error && (
                    <span className="text-xxs text-red-500">{error}</span>
                )}
                <Button className="w-full block mt-4" onClick={onSubmit}>
                    Entra
                </Button>
                <Hr />
                <div className="text-center my-4">
                    <div className="text-xs font-light my-2">
                        Non hai un account?
                    </div>
                    <Link to={routes.register}>
                        <Button className="w-full font-bold" color="primary">
                            Registrati
                        </Button>
                    </Link>
                </div>

                <div className="text-center">
                    <Link
                        className="underline text-xs text-center font-light text-white mt-4 mx-auto"
                        to={routes.registerTipster}
                    >
                        Sei un tipster ?
                    </Link>
                </div>

                <div className="text-center">
                    <Link
                        className="underline text-xs text-center font-light text-primary-600 mt-4 mx-auto"
                        to={routes.passwordReset}
                    >
                        Password dimenticata?
                    </Link>
                </div>
            </div>
        </Modal>
    )
}

export default LoginForm
