import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Box from '../../components/Box'
import { useAuth } from '../../context/Auth'
import TipsterList from '../../components/tipsters/TipsterList'
import UtentiList from '../../components/users/UtentiList'

const AffiliateUtentiPage = () => {
    const { user } = useAuth()
    const [expanded, setExpanded] = useState([true, true])

    return (
        <div className="flex flex-col md:flex-row gap-1 h-full">
            <Box
                headerLeftAction={
                    <FontAwesomeIcon
                        onClick={() => setExpanded([true, !expanded[1]])}
                        className="text-primary cursor-pointer p-2 border-primary/[.1] bg-primary/[.05] rounded-lg border"
                        icon={expanded[1] ? faExpand : faCompress}
                    />
                }
                title="Tipster"
                scrollable
                className={`flex-${expanded[0] ? '1' : '0'} ${
                    expanded[1] && !expanded[0] && 'md:hidden'
                }`}
            >
                <TipsterList />
            </Box>

            <Box
                title="Utenti"
                className={`flex-${expanded[1] ? '1' : '0'}  ${
                    expanded[0] && !expanded[1] && 'md:hidden'
                }`}
                scrollable
                headerLeftAction={
                    <FontAwesomeIcon
                        onClick={() => setExpanded([!expanded[0], true])}
                        className="text-primary cursor-pointer p-2 border-primary/[.1] bg-primary/[.05] rounded-lg border"
                        icon={expanded[0] ? faExpand : faCompress}
                    />
                }
            >
                <UtentiList />
            </Box>
        </div>
    )
}

export default AffiliateUtentiPage
