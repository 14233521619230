import { useEffect, useState } from 'react'
import Box from '../components/Box'
import Button from '../components/Button'
import { useApi } from '../context/Api'
import { useAppState } from '../context/AppState'
import { getProfilePictureUrl } from '../utils'

type Period = 'week' | 'month' | 'total'

const Charts = () => {
    const { getCharts } = useApi()
    const { switchLoading } = useAppState()
    const [period, setPeriod] = useState<Period>('total')
    const [charts, setCharts] = useState<{
        [key in Period]: {
            userId: string
            username: string
            points: number
            profilePicture?: string
        }[]
    }>({ week: [], month: [], total: [] })

    useEffect(() => {
        switchLoading(true)
        getCharts(period).then((res) => {
            setCharts({ ...charts, [period]: res })
            switchLoading(false)
        })
    }, [])
    return (
        <Box title="Classifiche" scrollable className="h-full">
            <div className="text-white p-2 ml:w-48">
                <div className="flex gap-1 mb-2 w-full">
                    <Button
                        onClick={() => {
                            const newPeriod: Period = 'total'
                            setPeriod(newPeriod)
                            if (charts[newPeriod].length == 0) {
                                switchLoading(true)
                                getCharts(newPeriod).then((res) => {
                                    setCharts({ ...charts, [newPeriod]: res })
                                    switchLoading(false)
                                })
                            }
                        }}
                        color={period == 'total' ? 'primary' : null}
                        className="flex-1 w-1/3"
                    >
                        Totale
                    </Button>
                    <Button
                        onClick={() => {
                            const newPeriod: Period = 'month'
                            setPeriod(newPeriod)
                            if (charts[newPeriod].length == 0) {
                                switchLoading(true)
                                getCharts(newPeriod).then((res) => {
                                    setCharts({ ...charts, [newPeriod]: res })
                                    switchLoading(false)
                                })
                            }
                        }}
                        color={period == 'month' ? 'primary' : null}
                        className="flex-1 w-1/3"
                    >
                        Mensile
                    </Button>
                    <Button
                        onClick={() => {
                            const newPeriod: Period = 'week'
                            setPeriod(newPeriod)
                            if (charts[newPeriod].length == 0) {
                                switchLoading(true)
                                getCharts(newPeriod).then((res) => {
                                    setCharts({ ...charts, [newPeriod]: res })
                                    switchLoading(false)
                                })
                            }
                        }}
                        color={period == 'week' ? 'primary' : null}
                        className="flex-1 w-1/3 overflow-hidden"
                    >
                        <span className="overflow-ellipsis">Settimanale</span>
                    </Button>
                </div>
                <div className="rounded overflow-hidden">
                    {charts[period].map((u, i) => (
                        <div
                            key={u.userId}
                            className={`text-xs lg:p-2 bg-black/[.${
                                i % 2 == 0 ? '3' : '05'
                            }] flex px-3 py-2 gap-1 items-center`}
                        >
                            <div className="font-thin pr-2 w-3">{i + 1}</div>
                            <img
                                className="mr-1 rounded-full w-5 h-5 inline object-cover"
                                src={getProfilePictureUrl(u.profilePicture)}
                            />
                            <div className="flex-1">{u.username}</div>
                            <div className="font-bold">{u.points}</div>
                        </div>
                    ))}
                </div>
            </div>
        </Box>
    )
}

export default Charts
