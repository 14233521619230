import { faClipboard, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { ChangeEvent, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Button from '../../../components/Button'
import ScrollableContent from '../../../components/ScrollableContent'
import ToggleSwitch from '../../../components/ToggleSwitch'
import Hr from '../../../components/styled/Hr'
import TipsterDetails from '../../../models/tipster-details.model'
import { apiServiceInst } from '../../../services/Api'
import { buildRefererUrl, zonedDate } from '../../../utils'
import MenuDetailRow from './MenuDetailRow'
import { AffiliateListData } from '../../../models/affiliate-list-data.model'

type Props = {
    data: AffiliateListData
}
const AffiliateDetailsView = (props: Props) => {
    const [details, setDetails] = useState<TipsterDetails | null>(null)
    const [commissionChanged, setCommissionChanged] = useState(false)
    useEffect(() => {
        apiServiceInst
            .getTipsterDetails(props.data.id)
            .then((res) => setDetails(res))
    }, [])
    const switchEnableChart = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.disabled = true
        apiServiceInst
            .updateTipsterChartSettings(props.data.id, {
                noShow: !e.target.checked,
            })
            .then((res) => {
                e.target.disabled = false
            })
    }

    const setNewCommission = (e: ChangeEvent<HTMLInputElement>) => {
        if (!details) return
        let value = e.target.valueAsNumber || 0
        if (value != details.commission) {
            setCommissionChanged(true)
        }

        setDetails({
            ...details,
            commission: value,
        })
    }
    const updateTipstercommission = () => {
        if (!details) return
        apiServiceInst
            .updateAffiliateCommission(props.data.id, details.commission)
            .then(() =>
                Swal.fire(undefined, 'Commissioni aggiornate correttamente'),
            )
    }
    return (
        <ScrollableContent>
            {details && (
                <div className="h-full">
                    <MenuDetailRow
                        label={<FontAwesomeIcon icon={faEnvelope} />}
                    >
                        {details.email}
                    </MenuDetailRow>
                    <Hr my={2} />
                    <MenuDetailRow label="Data registrazione">
                        {format(
                            zonedDate(new Date(details.registrationDate)),
                            'dd/MM/yyyy',
                        )}
                    </MenuDetailRow>
                    <MenuDetailRow label="Ultimo accesso">
                        {format(
                            zonedDate(new Date(details.lastLogin)),
                            'dd/MM/yyyy',
                        )}
                    </MenuDetailRow>
                    <Hr my={2} />
                    {/* <MenuDetailRow label="Pronostici pubblicati">
                        {details.publishedForecasts || 0}
                    </MenuDetailRow>
                    <MenuDetailRow label="Visualizzazioni pronostici">
                        {details.forecastsVisuals || 0}
                    </MenuDetailRow>

                    <Hr my={2} />

                    <MenuDetailRow label="Classifica attiva">
                        <ToggleSwitch
                            className=""
                            id="chart"
                            checked={details.chartsEnabled}
                            onChange={switchEnableChart}
                        />
                    </MenuDetailRow>
                    <Hr my={2} />

                    <MenuDetailRow label="Account attivo">
                        <ToggleSwitch
                            className=""
                            id="active"
                            checked={details.active}
                            onChange={(e) => console.log(e)}
                        />
                    </MenuDetailRow>
                    <Hr my={2} /> */}
                    <div className="flex items-center justify-between ">
                        <span className="text-xs font-light">Commissioni</span>
                        <div className="border-b border-primary px-2">
                            <input
                                type="number"
                                maxLength={3}
                                max={100}
                                pattern="\d\."
                                className="bg-transparent w-10  text-center text-lg font-bold"
                                value={details.commission}
                                onChange={setNewCommission}
                            />
                            <span className="text-gray-300 text-xs">%</span>
                        </div>
                        <Button
                            onClick={updateTipstercommission}
                            className="block"
                            disabled={!commissionChanged}
                        >
                            Salva
                        </Button>
                    </div>
                    <Hr my={2} />
                    <Button
                        className="w-full"
                        onClick={() => {
                            const refererURL = buildRefererUrl(
                                props.data.username,
                                'tipster',
                            )
                            if (window.isSecureContext) {
                                navigator.clipboard
                                    .writeText(refererURL)
                                    .then(() => {
                                        Swal.fire({
                                            toast: true,
                                            title: 'Referral link copiato!',
                                            position: 'bottom-right',
                                            showConfirmButton: false,
                                            showCloseButton: true,
                                            timer: 2000,
                                        })
                                    })
                            } else {
                                Swal.fire({
                                    toast: true,
                                    html: `<a href="${refererURL}">${refererURL}</a>`,
                                    position: 'bottom-right',
                                    showConfirmButton: false,
                                    showCloseButton: true,
                                    // timer: 2000,
                                })
                            }
                        }}
                    >
                        Copia url referral
                        <FontAwesomeIcon fixedWidth icon={faClipboard} />
                    </Button>
                </div>
            )}
        </ScrollableContent>
    )
}
export default AffiliateDetailsView
