import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useState } from 'react'
import ForecastList from '../../../components/ForecastList'
import PaginatedList from '../../../components/Pagination/PaginatedList'
import SectionNav, { SectionNavItem } from '../../../components/SectionNav'
import { useAppState } from '../../../context/AppState'
import PaginatedResult from '../../../models/paginated-result'
import TipsterListData from '../../../models/tipster-list-data.model'
import { useTipsterLastForecastMap } from '../../../queries'
import { apiServiceInst } from '../../../services/Api'
import ListElem from './ListElem'
import TipsterCommission from './TipsterCommission'
import TipsterDetailsView from './TipsterDetailsView'
import UsersList from './UsersList'
type TipsterProp = {
    data: TipsterListData
    lastForecast?: string
}

const TipsterMenu = (props: TipsterProp) => {
    const [section, setSection] = useState('dettagli')
    return (
        <div className="h-full flex flex-col">
            <div className="mb-4">
                <SectionNav
                    elems={[
                        { id: 'dettagli', title: 'Dettagli' },
                        { id: 'guadagni', title: 'Guadagni' },
                        { id: 'utenti', title: 'Utenti' },
                        { id: 'pronostici', title: 'Pronostici' },
                    ]}
                    onSelect={(section: SectionNavItem) => {
                        setSection(section.id)
                    }}
                />
            </div>
            <div className="grow">
                {section == 'dettagli' && (
                    <TipsterDetailsView data={props.data} />
                )}
                {section == 'guadagni' && (
                    <TipsterCommission userId={props.data.id} />
                )}
                {section == 'utenti' && <UsersList tipsterId={props.data.id} />}
                {section == 'pronostici' && (
                    <ForecastList readOnly tipsterId={props.data.id} />
                )}
            </div>
        </div>
    )
}
const TipsterElem = (props: TipsterProp & { onClick: () => void }) => (
    <ListElem
        name={props.data.username}
        onClick={props.onClick}
        profilePicture={props.data.profilePicture}
        showProfilePicture
    >
        <div className="text-xxs flex gap-2 text-gray-300">
            <div className="flex gap-1">
                <span className="font-light">%S</span>
                <span className="font-bold">
                    {props.data.singleSuccessRate.toFixed(2)}
                </span>
            </div>
            <div className="flex gap-1">
                <span className="font-light">%M</span>
                <span className="font-bold">
                    {props.data.multiSuccessRate.toFixed(2)}
                </span>
            </div>
            <div className="flex gap-1">
                <span className="font-light">
                    <FontAwesomeIcon icon={faUsers} />
                </span>
                <span className="font-bold">{props.data.users || 0}</span>
            </div>
            {props.lastForecast && (
                <div className="ml-auto grow w-full">
                    <span className="font-bold">
                        {format(new Date(props.lastForecast), 'dd/MM/yyyy')}
                    </span>
                </div>
            )}
        </div>
    </ListElem>
)
const TipstersList = () => {
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(15)
    const [tipsters, setTipsters] = useState<TipsterListData[]>([])

    const lastForecastMapQuery = useTipsterLastForecastMap()
    const onPageChange = async (page: number) => {
        apiServiceInst
            .browseTipsters({
                from: page * perPage,
                to: (page + 1) * perPage,
            })
            .then((res: PaginatedResult<TipsterListData>) => {
                setTotal(res.total)
                setTipsters(res.data)
            })
    }

    const onTextSearch = async (text: string) => {
        apiServiceInst
            .browseTipsters({
                text,
            })
            .then((res: PaginatedResult<TipsterListData>) => {
                setTotal(res.total)
                setTipsters(res.data)
            })
    }
    const { toggleModal } = useAppState()
    return (
        <div className="h-full">
            <PaginatedList<TipsterListData>
                items={tipsters}
                textSearchPlaceholder="Cerca per username"
                itemBuilder={(data: TipsterListData) => (
                    <TipsterElem
                        data={data}
                        lastForecast={lastForecastMapQuery.data?.[data.id]}
                        key={data.id}
                        onClick={() =>
                            toggleModal(
                                true,
                                data.username,
                                <TipsterMenu data={data} />,
                            )
                        }
                    />
                )}
                perPage={perPage}
                total={total}
                onPageChange={onPageChange}
                onTextSearch={onTextSearch}
            />
        </div>
    )
}

export default TipstersList
