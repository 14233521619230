import { useEffect, useState } from 'react'
import { useApi } from '../../context/Api'
import User from '../../models/user.model'
import { UtentiHeader } from '../users/UtentiList'
import UtentiRow from '../users/UtentiRow'

const TipsterUsers = ({ tipsterId }: { tipsterId: string }) => {
    const { getTipsterUsers } = useApi()
    const [users, setUsers] = useState<User[]>([])
    useEffect(() => {
        getTipsterUsers(tipsterId).then((res) => setUsers(res))
    }, [])
    return (
        <div>
            <UtentiHeader />
            <div>
                {users.map((u, i) => (
                    <UtentiRow index={i} key={i} user={u} />
                ))}
            </div>
        </div>
    )
}

export default TipsterUsers
