import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios'
import { plainToInstance } from 'class-transformer'
import { ApiErrorCode } from '../context/Api'
import { AffiliateListData } from '../models/affiliate-list-data.model'
import ForecastDTO from '../models/forecast.dto'
import ForecastModel, {
    ForecastCommissionModel,
} from '../models/forecast.model'
import { PackDTO } from '../models/pack.dto'
import PaginateQuery from '../models/paginate-query.model'
import PaginatedResult from '../models/paginated-result'
import TipsterDetails from '../models/tipster-details.model'
import TipsterListData from '../models/tipster-list-data.model'
import { PaymentTypes } from '../models/tipster.model'
import UserDetails from '../models/user-details.dto'
import User from '../models/user.model'
import { GetBestForecasts } from './models.t'

export class ApiError {
    error: ApiErrorCode
    data?: string
}
class ApiService {
    baseURL: string
    instance: AxiosInstance
    constructor() {
        this.baseURL = process.env.REACT_APP_API_URL || ''
        this.instance = this.instanceInit()
    }

    private instanceInit = () => {
        const inst = axios.create({
            baseURL: this.baseURL,
        })

        inst.interceptors.request.use((request: AxiosRequestConfig<any>) => {
            const jwt = localStorage.getItem('jwt')
            if (jwt) {
                if (request.headers) {
                    request.headers.Authorization = 'Bearer ' + jwt
                } else {
                    request.headers = { Authorization: 'Bearer ' + jwt }
                }
            }
            return request
        })

        inst.interceptors.response.use(
            (config: AxiosResponse<any>) => {
                if (config.status == 403) {
                    // logout()
                    // navigate('/login')
                }
                return config
            },
            (error: AxiosError) => {
                return Promise.reject(error)
            },
        )

        return inst
    }
    private async post<T>(endpoint: string, data?: object): Promise<T> {
        const res = await this.instance.post<T>(endpoint, data)

        return res.data
    }
    public addResponseInterceptor = () => {}
    public checkUserEmailAvailable = async (
        value: string,
    ): Promise<boolean> => {
        const res = await this.instance.get('user/check/email', {
            params: { value },
        })
        return res.data
    }

    public checkUsernameAvailable = async (value: string): Promise<boolean> => {
        try {
            const res = await this.instance.get('user/check/username', {
                params: { value },
            })
            return res.data
        } catch (error) {
            return false
        }
    }

    public browseUsers = async (
        query: PaginateQuery & { tipsterId?: string },
    ): Promise<PaginatedResult<User>> => {
        const res = await this.instance.get<PaginatedResult<User>>(
            'user/browse',
            {
                params: {
                    ...query,
                    admin: false,
                    tipster: false,
                    affiliate: false,
                },
            },
        )

        return res.data
    }

    public getUserDetails = async (userId: string): Promise<UserDetails> => {
        const res = await this.instance.get<UserDetails>(
            `user/${userId}/details`,
        )

        return res.data
    }

    public browseTipsters = async (
        query: PaginateQuery,
    ): Promise<PaginatedResult<TipsterListData>> => {
        const res = await this.instance.get<PaginatedResult<TipsterListData>>(
            'tipster/browse',
            {
                params: query,
            },
        )

        return res.data
    }
    public getTipstersChart = async (
        query: PaginateQuery,
    ): Promise<PaginatedResult<TipsterListData>> => {
        const res = await this.instance.get<PaginatedResult<TipsterListData>>(
            'tipster/chart',
            {
                params: query,
            },
        )

        return res.data
    }
    public getTipsterDetails = async (
        tipsterId: string,
    ): Promise<TipsterDetails> => {
        const res = await this.instance.get<TipsterDetails>(
            `tipster/${tipsterId}/details`,
        )

        return res.data
    }

    public updateTipsterChartSettings = async (
        tipsterId: string,
        settings: { noShow: boolean },
    ): Promise<boolean> => {
        const res = await this.instance.post<boolean>(
            `tipster/${tipsterId}/chart-settings`,
            settings,
        )

        return res.data
    }

    public payoutTipster = async (
        tipsterId: string,
        method: PaymentTypes | null,
    ): Promise<boolean | ApiError> => {
        const res = await this.instance.post<boolean | ApiError>(
            `user/${tipsterId}/payout`,
            {
                method,
            },
        )
        return res.data
    }

    getTipsterCommissionDue = async (
        userId?: string,
    ): Promise<{ total: number }> => {
        const res = await this.instance.get('commission/total-due', {
            params: { userId },
        })
        return res.data
    }
    updateTipsterCommission = async (
        tipsterId: string,
        commission: number,
    ): Promise<boolean> => {
        const res = await this.instance.post<boolean>(
            `user/${tipsterId}/commission`,
            {
                commission,
            },
        )
        return res.data
    }

    updateAffiliateCommission = async (
        affiliateId: string,
        commission: number,
    ): Promise<boolean> => {
        const res = await this.instance.post<boolean>(
            `affiliate/${affiliateId}/commission`,
            {
                commission,
            },
        )
        return res.data
    }
    getTotalUsers = async (): Promise<number> => {
        const res = await this.instance.get<number>('user/total')

        return res.data
    }

    getTotalTipsters = async (): Promise<number> => {
        const res = await this.instance.get<number>('tipster/total')

        return res.data
    }
    getTopForecasts = async (
        options: GetBestForecasts,
    ): Promise<PaginatedResult<ForecastModel>> => {
        const res = await this.instance.get<PaginatedResult<ForecastModel>>(
            'forecast/for-sale/best',
            { params: options },
        )

        res.data.data = res.data.data.map((d) =>
            plainToInstance(ForecastModel, d),
        )

        return res.data
    }

    getHighOddsForecasts = async (
        options: PaginateQuery,
    ): Promise<PaginatedResult<ForecastModel>> => {
        const res = await this.instance.get<PaginatedResult<ForecastModel>>(
            'forecast/for-sale/high-odds',
            { params: options },
        )
        res.data.data = res.data.data.map((d) =>
            plainToInstance(ForecastModel, d),
        )
        return res.data
    }

    async browseForecasts(
        query: PaginateQuery & {
            userId?: string
            status: string
            purchased?: boolean
            notPurchased?: boolean
        },
    ): Promise<PaginatedResult<ForecastModel>> {
        const res = await this.instance.get<PaginatedResult<ForecastModel>>(
            'forecast/browse',
            { params: query },
        )

        return res.data
    }

    browseForSaleForecasts = async (
        query: PaginateQuery & { userId?: string },
    ): Promise<PaginatedResult<ForecastModel>> => {
        const res = await this.instance.get<PaginatedResult<ForecastModel>>(
            'forecast/for-sale/browse',
            {
                params: query,
            },
        )

        res.data.data = res.data.data.map((d) =>
            plainToInstance(ForecastModel, d),
        )

        return res.data
    }
    getLastForecast = async (
        query: PaginateQuery & { userId?: string },
    ): Promise<PaginatedResult<ForecastModel>> => {
        const res = await this.instance.get<PaginatedResult<ForecastModel>>(
            'forecast/for-sale/best-public',
            {
                params: query,
            },
        )

        res.data.data = res.data.data.map((d) =>
            plainToInstance(ForecastModel, d),
        )
        return res.data
    }

    createForecast = async (data: ForecastDTO): Promise<ForecastModel> => {
        const res = await this.instance.post('forecast', data)

        return res.data as ForecastModel
    }
    publishForecast = async (forecastID: string): Promise<boolean> => {
        const url = `forecast/publish/${forecastID}`
        const res = await this.instance.post<boolean>(url)
        return res.data
    }
    updatePack = async (
        packId: string,
        data: Partial<PackDTO>,
    ): Promise<boolean> => {
        const res = await this.instance.post<boolean>(`pack/${packId}`, data)

        return res.data
    }

    deleteMatch = async (matchId: number): Promise<boolean> => {
        const result = await this.post<boolean>(`match/${matchId}/delete`)

        return result
    }

    setMatchPostponed = async (matchId: number): Promise<boolean> => {
        const result = await this.post<boolean>(`match/${matchId}/postpone`)

        return result
    }
    getForecastsCommissions = async (
        options: PaginateQuery & { userId: string },
    ) => {
        const result = await this.instance.get<
            PaginatedResult<ForecastCommissionModel>
        >(`commission/forecast-views`, { params: options })

        return result.data
    }

    loginCheck = async (): Promise<boolean | null> => {
        try {
            const result = await this.instance.get('login-check')
            // const navigate = useNavigate()
            // navigate('')
            // return null
            return result.status == 200
        } catch (error) {
            return false
            // window.location = ''
        }
    }

    getOnHoldBC = async (): Promise<number> => {
        const result = await this.instance.get<number>(
            'promo/refund/on-hold-bc',
        )

        return result.data
    }

    public browseAffiliates = async (
        query: PaginateQuery,
    ): Promise<PaginatedResult<AffiliateListData>> => {
        const res = await this.instance.get<PaginatedResult<AffiliateListData>>(
            'affiliate/browse',
            {
                params: query,
            },
        )

        return res.data
    }

    async getTotalDue(userId?: string): Promise<{ total: number }> {
        const result = await this.instance.get<{ total: number }>(
            'commission/total-due',
            {
                params: {
                    userId,
                },
            },
        )

        return result.data
    }

    async getTipstersLastForecastMap(): Promise<{
        [key: string]: string
    }> {
        const result = await this.instance.get('tipster/last-forecast-map')

        return result.data
    }
}

export const apiServiceInst = new ApiService()
