import { useWizard } from 'react-use-wizard'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import {
    EMAIL_VALIDATION_ERROR_MESSAGE,
    REQUIRED_ERROR_MESSAGE,
    USERNAME_VALIDATION_ERROR_MESSAGE,
} from './constants'
import { EMAIL_REGEX, USERNAME_REGEX } from '../../../utils'
import { useFormContext } from 'react-hook-form'
import {
    StepError,
    StepInput,
    StepInputContainer,
    StepTitle,
} from './step-elements'
import StepsNav from './StepsNav'
import { useEffect, useState } from 'react'
import { apiServiceInst } from '../../../services/Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

const EmailStep = () => {
    const {
        register,
        trigger,
        watch,
        setError,
        formState: { errors },
    } = useFormContext<{ email: string }>()

    const watchField = watch('email')
    const [loading, setLoading] = useState(false)
    const [available, setAvailable] = useState<null | boolean>(null)
    const [nextEnabled, setNextEnabled] = useState(true)

    const checkEmail = async (value: string): Promise<boolean> => {
        if (errors.email) {
            setNextEnabled(false)
            Swal.fire({
                toast: true,
                text: EMAIL_VALIDATION_ERROR_MESSAGE,
                position: 'bottom',
                showCloseButton: true,
                showConfirmButton: false,
            })
            return false
        }
        setLoading(true)
        const result = await apiServiceInst.checkUserEmailAvailable(value)
        await new Promise((resolve) => setTimeout(resolve, 800))

        if (result === false) {
            setNextEnabled(true)
            Swal.fire({
                toast: true,
                text: 'Esiste già un account con questa email.',
                position: 'bottom',
                showCloseButton: true,
                showConfirmButton: false,
            })
        }
        setLoading(false)
        setAvailable(result)
        if (result) {
            await new Promise((resolve) => setTimeout(resolve, 500))
        }
        return result
    }

    useEffect(() => {
        trigger('email')
        setAvailable(null)
        setNextEnabled(true)
    }, [watchField])
    return (
        <div className="flex flex-col h-full">
            <StepTitle title="Inserisci la tua email" />
            <div className="grow">
                <StepInputContainer>
                    <StepInput
                        {...register('email', {
                            required: true,
                            pattern: {
                                value: EMAIL_REGEX,
                                message: EMAIL_VALIDATION_ERROR_MESSAGE,
                            },
                        })}
                        id="email"
                        autoComplete="email"
                        aria-invalid={errors.email ? 'true' : 'false'}
                    />
                    {loading && <FontAwesomeIcon icon={faSpinner} spin />}
                    {available && (
                        <FontAwesomeIcon
                            icon={faCheck}
                            className="text-success"
                        />
                    )}
                </StepInputContainer>
                {/* <StepError fieldError={errors.email} /> */}
            </div>
            <StepsNav
                onNext={() => checkEmail(watchField)}
                nextEnabled={nextEnabled}
            />
        </div>
    )
}

export default EmailStep
