import { faMagnifyingGlass, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import Input from './Input'

type Props = {
    onSearch: (query: string) => void
    hintText?: string
}

const SearchBar = (props: Props) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>
    return (
        <div className="relative w-full flex items-center">
            <FontAwesomeIcon
                className="px-2 text-xs text-white/[.4]"
                fixedWidth
                icon={faMagnifyingGlass}
            />

            <input
                ref={ref}
                type="text"
                placeholder={props.hintText}
                className="w-full bg-transparent p-2 text-xxs text-white"
                onChange={(e) => props.onSearch(e.target.value)}
            />
            <button
                onClick={() => {
                    ref.current.value = ''
                    props.onSearch('')
                }}
                className="p-1 text-red-800"
            >
                <FontAwesomeIcon fixedWidth icon={faTimes} />
            </button>
        </div>
    )
}

export default SearchBar
