import { useEffect, useState } from 'react'
import MultiStoricoForecast from '../../components/Forecast/storico/MultiStoricoForecast'
import StoricoForecast from '../../components/Forecast/storico/StoricoForecast'
import { useApi } from '../../context/Api'
import ForecastModel, { MultiForecastModel } from '../../models/forecast.model'
import { useAppState } from '../../context/AppState'
import Loader from '../../components/Loader'
type Props = {
    widget?: boolean
}

const BestStorico = (props: Props) => {
    const { getBestStoricoForecast } = useApi()
    const { switchLoading } = useAppState()
    const [localLoading, setLocalLoading] = useState(false) //for widget loading

    const [forecasts, setForecasts] = useState<
        (ForecastModel | MultiForecastModel)[]
    >([])

    useEffect(() => {
        if (!props.widget) {
            switchLoading(true)
        } else {
            setLocalLoading(true)
        }
        getBestStoricoForecast().then((res) => {
            setForecasts(res)
            if (!props.widget) {
                switchLoading(false)
            } else {
                setLocalLoading(false)
            }
        })
    }, [])
    return (
        <div className="flex flex-col gap-2 h-full">
            {localLoading && <Loader forceLoad={true} />}
            {!localLoading && (
                <>
                    {forecasts?.map((f, i) => (
                        <div key={i}>
                            {!f.multi && (
                                <StoricoForecast
                                    forecast={f as ForecastModel}
                                />
                            )}
                            {f.multi && (
                                <MultiStoricoForecast
                                    forecast={f as MultiForecastModel}
                                />
                            )}
                        </div>
                    ))}
                </>
            )}
        </div>
    )
}

export default BestStorico
