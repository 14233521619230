import { Link } from 'react-router-dom'
import { useAuth } from '../../../context/Auth'
import routes from '../../../routing/routes'

const Dashboard = () => {
    const { user } = useAuth()

    return (
        <div className="flex flex-col md:w-2/3 md:mx-auto gap-2 h-full">
            <h1 className="text-primary font-bold text-[1.5rem] mb-4">
                Dashboard
            </h1>
            <Link
                className="text-white"
                to={routes.adminDashboardPackPurchases}
            >
                <div className="border border-primary-700 shadow rounded bg-secondary w-full text-lg p-4">
                    Aquisti pack
                </div>
            </Link>

            <Link className="text-white" to={routes.adminDashboardEditPacks}>
                <div className="border border-primary-700 shadow rounded bg-secondary w-full text-lg p-4">
                    Modifica pack
                </div>
            </Link>

            <Link className="text-white" to={routes.adminDashboardManual}>
                <div className="border border-primary-700 shadow rounded bg-secondary w-full text-lg p-4">
                    Gestione match
                </div>
            </Link>
        </div>
    )
}

export default Dashboard
