import styled from 'styled-components'
import tw from 'twin.macro'

const Styled = styled.div.attrs({
    className:
        'relative p-2 lg:p-4 align-items-center text-white bg-secondary-400 rounded-xl text-xs font-light select-none',
})`
    & {
        .badge {
            ${tw`text-xxs w-4 absolute text-center`}
        }
    }
`

export default Styled
