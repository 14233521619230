import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Nav from '../../components/Nav'
import UserBar from '../../components/UserBar'
import { useApi } from '../../context/Api'
import { useAuth } from '../../context/Auth'
import tipsterNavItems from '../tipster/nav-items'
import affiliateNavItems from './nav-items'
import BodyContainer from '../../components/BodyContainer'

const Affiliate = () => {
    const { loginCheck } = useApi()
    useEffect(() => {
        console.log('affiliate main component')

        loginCheck().then((res) => null)
    }, [])
    return (
        <div className="bg-greyBg h-screen flex flex-col">
            <Nav items={affiliateNavItems}></Nav>
            <div className="py-[1px] text-center bg-yellow-700 text-white font-bold text-xxs">
                AFFILIATO
            </div>
            <UserBar />
            <BodyContainer>
                <Outlet />
            </BodyContainer>
        </div>
    )
}

export default Affiliate
