import { useQuery } from 'react-query'
import PaginateQuery from './models/paginate-query.model'
import { apiServiceInst } from './services/Api'
import { GetBestForecasts } from './services/models.t'

export const useBrowseTipstersQuery = (query: PaginateQuery) =>
    useQuery(['browse-tipsters', query], () =>
        apiServiceInst.browseTipsters(query),
    )

export const useBrowseAffiliatesQuery = (query: PaginateQuery) =>
    useQuery(['browse-affiliates', query], () =>
        apiServiceInst.browseAffiliates(query),
    )

export const useTipsterLastForecastMap = () =>
    useQuery('tipster-last-forecast-map', () =>
        apiServiceInst.getTipstersLastForecastMap(),
    )

export const useGetTopForecastsMap = (query: GetBestForecasts) =>
    useQuery(['get-best-forecasts', query], () =>
        apiServiceInst.getTopForecasts(query),
    )
