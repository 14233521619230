import { useEffect, useState } from 'react'
import Box from '../../components/Box'
import CreatedForecast from '../../components/Forecast/CreatedForecast'
import AdminSellingForecast from '../../components/Forecast/selling/AdminSellingForecast'
import { useApi } from '../../context/Api'
import ForecastModel, { MultiForecastModel } from '../../models/forecast.model'
import { PageTitle } from '../../components/typography'
import ScrollableContent from '../../components/ScrollableContent'

const Selling = () => {
    const { getAllSellingForecasts } = useApi()
    const [forecasts, setForecasts] = useState<
        (ForecastModel | MultiForecastModel)[]
    >([])
    useEffect(() => {
        getAllSellingForecasts().then((res) => setForecasts(res))
    }, [])
    return (
        <div className="h-full flex flex-col">
            <PageTitle>In vendita</PageTitle>
            <ScrollableContent>
                <div className="flex flex-col gap-2">
                    {forecasts.map((f) => (
                        <AdminSellingForecast key={f.id} forecast={f} />
                    ))}
                </div>
            </ScrollableContent>
        </div>
    )
}

export default Selling
