import {
    IconDefinition,
    faCalendarDays,
    faCaretLeft,
    faCaretRight,
    faCheck,
    faMagnifyingGlass,
    faPager,
    faRightToBracket,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import ReactPaginate from 'react-paginate'

enum Filters {
    pagination = 1,
    dateSearch = 2,
    textSearch = 3,
}

type Props = {
    totalElements: number
    perPage?: number
    onPageChange: (selectedItem: { selected: number }) => void
    paginationEnabled?: boolean
    dateSearchEnabled?: boolean
    textSearchEnabled?: boolean
    textSearchPlaceholder?: string
    onTextSearch?: (text: string) => void
}

type SectionProps = {
    icon: IconDefinition
    content: React.ReactNode
    i: number
    expanded: number | false
    setExpanded: (value: number) => void
}

const AnimatedSection = (props: SectionProps) => {
    const isOpen = props.i === props.expanded
    return (
        <motion.div
            className={classNames({
                'flex items-stretch border rounded border-secondary-400 shadow overflow-hidden':
                    true,
                grow: isOpen,
            })}
        >
            <motion.button
                className="w-auto text-xs text-white p-2 bg-secondary rounded"
                onClick={() => props.setExpanded(props.i)}
            >
                <FontAwesomeIcon fixedWidth icon={props.icon} />
            </motion.button>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        className="grow"
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, width: 'auto' },
                            collapsed: { opacity: 0, width: 0 },
                        }}
                        transition={{
                            duration: 0.5,
                            ease: [0.04, 0.62, 0.23, 0.98],
                        }}
                    >
                        {props.content}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    )
}
const FilterBar = (props: Props) => {
    const [expanded, setExpanded] = useState<number>(Filters.pagination)
    const [textSearch, setTextSearch] = useState('')
    return (
        <div className="flex items-center gap-1">
            {props.paginationEnabled && (
                <AnimatedSection
                    icon={faPager}
                    expanded={expanded}
                    i={Filters.pagination}
                    setExpanded={setExpanded}
                    content={
                        <ReactPaginate
                            className="text-white flex h-full items-center"
                            initialPage={0}
                            renderOnZeroPageCount={null}
                            breakLabel="..."
                            pageClassName="w-full h-full"
                            nextClassName="w-full text-center"
                            previousClassName="w-full text-center"
                            pageLinkClassName="text-xs flex items-center justify-center h-full"
                            activeClassName="bg-secondary-200 font-bold rounded"
                            previousLabel={
                                <FontAwesomeIcon icon={faCaretLeft} />
                            }
                            disableInitialCallback
                            nextLabel={<FontAwesomeIcon icon={faCaretRight} />}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={0}
                            // disableInitialCallback={true}
                            disabledClassName="text-gray-500"
                            pageCount={Math.ceil(
                                props.totalElements / props.perPage!,
                            )}
                            onPageChange={props.onPageChange}
                        />
                    }
                />
            )}
            {props.dateSearchEnabled && (
                <AnimatedSection
                    icon={faCalendarDays}
                    expanded={expanded}
                    i={Filters.dateSearch}
                    setExpanded={setExpanded}
                    content={
                        <div className="">
                            <div>
                                <button className="text-white">DA</button>
                            </div>
                        </div>
                    }
                />
            )}
            {props.textSearchEnabled && (
                <AnimatedSection
                    icon={faMagnifyingGlass}
                    expanded={expanded}
                    i={Filters.textSearch}
                    setExpanded={setExpanded}
                    content={
                        <div className="flex items-stretch h-full">
                            <input
                                onChange={(e) => setTextSearch(e.target.value)}
                                value={textSearch}
                                type="text"
                                placeholder={props.textSearchPlaceholder}
                                className="h-full w-full bg-transparent rounded text-xs text-white px-2"
                            />
                            <button
                                className="text-green-500 p-1"
                                onClick={() =>
                                    props.onTextSearch &&
                                    textSearch &&
                                    props.onTextSearch(textSearch)
                                }
                            >
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faRightToBracket}
                                />
                            </button>
                        </div>
                    }
                />
            )}
        </div>
    )
}

const defaultProps: Partial<Props> = {
    paginationEnabled: true,
    dateSearchEnabled: true,
    textSearchEnabled: true,
    perPage: 10,
}
FilterBar.defaultProps = defaultProps

export default FilterBar
