import {
    faCoins,
    faEllipsisV,
    faEnvelope,
    faHistory,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import PaginatedList from '../../../components/Pagination/PaginatedList'
import ScrollableContent from '../../../components/ScrollableContent'
import SectionNav, { SectionNavItem } from '../../../components/SectionNav'
import ToggleSwitch from '../../../components/ToggleSwitch'
import Hr from '../../../components/styled/Hr'
import UserTransactions from '../../../components/users/UserTransactions'
import { useApi } from '../../../context/Api'
import { useAppState } from '../../../context/AppState'
import PaginatedResult from '../../../models/paginated-result'
import UserDetails from '../../../models/user-details.dto'
import User from '../../../models/user.model'
import { apiServiceInst } from '../../../services/Api'
import { zonedDate } from '../../../utils'
import MenuDetailRow from './MenuDetailRow'

type UserProp = {
    data: User
}

const UserDetailsView = (props: UserProp) => {
    const [userDetails, setUserDetails] = useState<UserDetails | null>(null)
    useEffect(() => {
        apiServiceInst
            .getUserDetails(props.data.id)
            .then((res) => setUserDetails(res))
    }, [])
    return (
        <ScrollableContent>
            {userDetails && (
                <div className="h-full">
                    <MenuDetailRow
                        label={<FontAwesomeIcon icon={faEnvelope} />}
                    >
                        {userDetails.email}
                    </MenuDetailRow>
                    <Hr my={2} />
                    <MenuDetailRow label="Data registrazione">
                        {format(
                            zonedDate(new Date(userDetails.registrationDate)),
                            'dd/MM/yyyy',
                        )}
                    </MenuDetailRow>
                    <MenuDetailRow label="Ultimo accesso">
                        {format(
                            zonedDate(new Date(userDetails.lastLogin)),
                            'dd/MM/yyyy',
                        )}
                    </MenuDetailRow>
                    <Hr my={2} />
                    <MenuDetailRow label="BC">
                        {userDetails.balance || 0}
                    </MenuDetailRow>
                    <MenuDetailRow label="Pronostici visualizzati">
                        {userDetails.forecastsViews || 0}
                    </MenuDetailRow>
                    <MenuDetailRow label="Pacchetti acquistati">
                        {userDetails.packsPurchases || 0}
                    </MenuDetailRow>
                    <Hr my={2} />

                    <MenuDetailRow label="Tipster assegnato">
                        {userDetails.tipsterAssigned}
                    </MenuDetailRow>
                    <Hr my={2} />

                    <MenuDetailRow label="Classifica attiva">
                        <ToggleSwitch
                            className=""
                            id="chart"
                            checked={userDetails.chartsEnabled}
                            onChange={(e) => console.log(e)}
                        />
                    </MenuDetailRow>
                    <Hr my={2} />

                    <MenuDetailRow label="Account attivo">
                        <ToggleSwitch
                            className=""
                            id="chart"
                            checked={userDetails.active}
                            onChange={(e) => console.log(e)}
                        />
                    </MenuDetailRow>
                </div>
            )}
        </ScrollableContent>
    )
}

const UserMovementsView = (props: UserProp) => {
    return (
        <div className="flex flex-col h-full">
            {/* <SectionTitle>Movimenti</SectionTitle> */}
            <div className="grow">
                <UserTransactions user={props.data} />
            </div>
        </div>
    )
}
const UserMenu = (props: UserProp) => {
    const [section, setSection] = useState('dettagli')
    return (
        <div className="flex flex-col h-full">
            <div className="shrink-0 mb-4">
                <SectionNav
                    elems={[
                        { id: 'dettagli', title: 'Dettagli' },
                        { id: 'movimenti', title: 'Movimenti' },
                    ]}
                    onSelect={(section: SectionNavItem) => {
                        setSection(section.id)
                    }}
                />
            </div>
            {section == 'dettagli' && <UserDetailsView data={props.data} />}
            {section == 'movimenti' && <UserMovementsView data={props.data} />}
        </div>
    )
}

const UserElem = ({ data, onClick }: { data: User; onClick: () => void }) => {
    return (
        <div className="rounded bg-secondary-400 text-white px-2 py-1">
            <div className="flex justify-between items-center">
                <div>
                    <span className="font-bold text-sm">{data.username}</span>
                    <div className="flex text-xxs gap-2">
                        <div className="text-xxs text-gray-300">
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faHistory}
                                className="mr-1"
                            />
                            <span>
                                {format(
                                    zonedDate(data.registrationDate!),
                                    'dd/MM/yyyy',
                                )}
                            </span>
                        </div>
                        <div className="text-yellow-600">
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faCoins}
                                className="mr-1"
                            />
                            <span>{data.balance || 0}</span>
                        </div>
                    </div>
                </div>
                <button
                    className="w-6 rounded-full bg-secondary-400"
                    onClick={onClick}
                >
                    <FontAwesomeIcon fixedWidth icon={faEllipsisV} />
                </button>
            </div>
        </div>
    )
}

type UsersListProps = {
    tipsterId?: string
}
const UsersList = (props: UsersListProps) => {
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(15)
    const [users, setUsers] = useState<User[]>([])

    const onPageChange = async (page: number) => {
        apiServiceInst
            .browseUsers({
                ...props,
                from: page * perPage,
                to: (page + 1) * perPage,
            })
            .then((res: PaginatedResult<User>) => {
                setTotal(res.total)
                setUsers(res.data)
            })
    }

    const onTextSearch = async (text: string) => {
        apiServiceInst
            .browseUsers({
                ...props,
                text,
            })
            .then((res: PaginatedResult<User>) => {
                setTotal(res.total)
                setUsers(res.data)
            })
    }
    const { toggleModal } = useAppState()
    return (
        <div className="h-full">
            <PaginatedList<User>
                items={users}
                textSearchPlaceholder="Cerca per username"
                itemBuilder={(data: User) => (
                    <UserElem
                        data={data}
                        key={data.id}
                        onClick={() =>
                            toggleModal(
                                true,
                                data.username,
                                <UserMenu data={data} />,
                            )
                        }
                    />
                )}
                perPage={perPage}
                total={total}
                onPageChange={onPageChange}
                onTextSearch={onTextSearch}
            />
        </div>
    )
}

export default UsersList
