import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import MultiStoricoForecast from '../../components/Forecast/storico/MultiStoricoForecast'
import StoricoForecast from '../../components/Forecast/storico/StoricoForecast'
import ScrollableContent from '../../components/ScrollableContent'
import SearchBar from '../../components/SearchBar'
import { useAppState } from '../../context/AppState'
import ForecastModel, { MultiForecastModel } from '../../models/forecast.model'
import { apiServiceInst } from '../../services/Api'
import { PageTitle } from '../../components/typography'
import { useAuth } from '../../context/Auth'

const Storico = () => {
    const { switchLoading } = useAppState()
    const [forecasts, setForecasts] = useState<ForecastModel[]>([])
    const [step, setStep] = useState(50)
    const [params, setParams] = useState({ from: 0, to: step })
    const { getUser } = useAuth()

    const storicoQuery = useQuery(
        ['storico-forecast', params],
        () =>
            apiServiceInst.browseForecasts({
                ...params,
                status: 'evaluated',
                sortOrder: -1,
                userId: getUser()?.tipster ? getUser()?.userId : undefined,
                purchased: true,
            }),
        {
            onSuccess: (data) => setForecasts([...forecasts, ...data.data]),
        },
    )
    useEffect(() => {
        switchLoading(storicoQuery.isLoading)
    }, [storicoQuery.isLoading])

    const [filterQuery, setFilterQuery] = useState('')

    const filter = (f: ForecastModel | MultiForecastModel) => {
        const query = filterQuery.toLowerCase()
        return (
            !filterQuery ||
            (f.creator && f.creator.username.toLowerCase().match(query)) ||
            (!f.creator && 'YT'.match(query)) ||
            (!f.multi &&
                ((f as ForecastModel).match.awayTeam
                    .toLowerCase()
                    .match(query) ||
                    (f as ForecastModel).match.homeTeam
                        .toLowerCase()
                        .match(query))) ||
            (f.multi &&
                (f as MultiForecastModel).forecasts
                    .map((f) => f.match)
                    .some(
                        (m) =>
                            m.homeTeam.toLowerCase().match(query) ||
                            m.awayTeam.toLowerCase().match(query),
                    ))
        )
    }

    return (
        <div className="flex flex-col h-full">
            <PageTitle>Storico</PageTitle>
            <ScrollableContent>
                <div className="flex flex-col gap-2">
                    <div className="border-b border-white/[.1] mb-2">
                        <SearchBar
                            hintText="Cerca per squadre o tipster"
                            onSearch={(query) => setFilterQuery(query)}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        {forecasts.filter(filter).map((f, i) => (
                            <div key={i}>
                                {!f.multi && (
                                    <StoricoForecast
                                        forecast={f as ForecastModel}
                                    />
                                )}
                                {f.multi && (
                                    <MultiStoricoForecast
                                        forecast={f as MultiForecastModel}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                    {(storicoQuery.data?.total ?? 0) > params.to && (
                        <button
                            className="rounded text-center font-bold bg-primary-600 text-primary-900 py-2 text-sm"
                            onClick={async () => {
                                setParams({
                                    from: params.from + step,
                                    to: params.to + step,
                                })
                            }}
                        >
                            Mostra altri {step}
                        </button>
                    )}
                </div>
            </ScrollableContent>
        </div>
    )
}

export default Storico
