import { Type } from 'class-transformer'

export default class PackTransactionModel {
    @Type(() => Date)
    date: Date

    username: string

    amount: number

    packName: string
}
