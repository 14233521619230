import {
    faEuro,
    faMoneyBill,
    faPencil,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { ApiErrorCode, useApi } from '../../context/Api'
import Tipster, {
    PaymentTypes,
    TipsterPaymentsMethods,
} from '../../models/tipster.model'
import Button from '../Button'
import Input from '../Input'
import ScrollableContent from '../ScrollableContent'
import Hr from '../styled/Hr'
import TipsterTransactions from '../Tipster/TipsterTransactions'
import TipsterPaymentMethodsTable from './TipsterPaymentMethodsTable'

const TipsterSettings = ({ tipster }: { tipster: Tipster }) => {
    const {
        getTipsterCommissionDue,
        payoutTipster,
        updateTipsterCommission,
        getTipsterPaymentMethods,
        getTipsterLinkBonus,
        setTipsterLinkBonus,
    } = useApi()
    const [commissionDue, setCommissionDue] = useState(0)
    const [newCommission, setNewCommission] = useState(tipster.commission)
    const [linkBonus, setLinkBonus] = useState('')
    const [showEditCommission, setShowEditCommission] = useState(false)
    const [paymentMethods, setPaymentMethods] =
        useState<TipsterPaymentsMethods>()
    useEffect(() => {
        getTipsterPaymentMethods(tipster.tipsterId).then(
            (res: TipsterPaymentsMethods) => setPaymentMethods(res),
        )
        getTipsterCommissionDue(tipster.tipsterId).then((res: number) =>
            setCommissionDue(res),
        )
        getTipsterLinkBonus(tipster.tipsterId).then((res: string) =>
            setLinkBonus(res),
        )
    }, [])

    const onPayout = (method: PaymentTypes | null) => {
        Swal.fire({
            text: 'Vuoi saldare le commissioni del tipster?',
            showDenyButton: true,
            showConfirmButton: true,
            showCloseButton: true,
        }).then((res) => {
            if (res.isConfirmed) {
                payoutTipster(tipster.tipsterId, method).then((res) => {
                    if (res.error) {
                        let message
                        if (res.error == ApiErrorCode.NO_TIPSTER_PAYPAL_ID) {
                            message =
                                'Il tipster non ha una mail PayPal assegnata, impossibile effettuare il pagamento.'
                        } else {
                            message =
                                "C'è stato un problema con il pagamento, verifica che il tipster abbia inserito una mail Paypal corretta."
                        }
                        Swal.fire({
                            toast: true,
                            text: message,
                            position: 'bottom-right',
                        })
                    } else {
                        Swal.fire('Commissioni pagate correttamente.')
                        setCommissionDue(0)
                    }
                })
            }
        })
    }
    return (
        <div className="md:w-md-box mx-auto flex flex-col h-full">
            <div className="flex">
                <div className="flex-1">
                    <span className="mr-4">Percentuale:</span>
                    <span className="font-bold text-2xl text-gray-400">
                        {tipster.commission} %
                    </span>
                </div>
                <Button onClick={() => setShowEditCommission(true)}>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
            </div>
            {showEditCommission && (
                <div className="my-2 border border-gray-700 p-2 flex gap-2 items-center">
                    <Input
                        type="number"
                        value={newCommission}
                        onChange={(e) =>
                            setNewCommission(parseInt(e.target.value))
                        }
                        className="w-full h-8"
                    />
                    <Button
                        onClick={() => {
                            tipster.commission = newCommission
                            updateTipsterCommission(
                                tipster.tipsterId,
                                newCommission,
                            ).then(() =>
                                Swal.fire(
                                    undefined,
                                    'Commissioni aggiornate correttamente',
                                ).then(() => setShowEditCommission(false)),
                            )
                        }}
                        className="ml-auto block"
                    >
                        Salva
                    </Button>
                    <Button
                        color="danger"
                        onClick={() => setShowEditCommission(false)}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </div>
            )}
            <Hr />
            <div>
                <div className="mr-4">Link bonus</div>
                <div className="flex gap-1">
                    <Input
                        value={linkBonus}
                        onChange={(e) => setLinkBonus(e.target.value)}
                        className="w-full"
                    />
                    <Button
                        onClick={() => {
                            setTipsterLinkBonus(
                                tipster.tipsterId,
                                linkBonus,
                            ).then(() =>
                                Swal.fire({
                                    toast: true,
                                    title: 'Salvato!',
                                    position: 'bottom-right',
                                    showConfirmButton: false,
                                    showCloseButton: true,
                                    timer: 8000,
                                }),
                            )
                        }}
                    >
                        Salva
                    </Button>
                </div>
            </div>
            <Hr />
            <div className=" flex">
                <div className="flex-1">
                    <span className="mr-4">Da pagare:</span>
                    <span className="font-bold text-2xl text-primary">
                        {commissionDue.toFixed(2).toLocaleString()} €
                    </span>
                </div>
            </div>
            <ScrollableContent className="flex-1 rounded my-2">
                <TipsterTransactions tipsterId={tipster.id} />
            </ScrollableContent>
            <Hr />
            {paymentMethods && (
                <TipsterPaymentMethodsTable paymentMethods={paymentMethods} />
            )}
            <Button
                color="primary"
                className="w-full md:w-auto mb-2"
                disabled={commissionDue == 0}
                onClick={() => onPayout('paypal')}
            >
                Paga con Paypal
            </Button>
            <Button
                className="w-full md:w-auto"
                disabled={commissionDue == 0}
                onClick={() => onPayout('none')}
            >
                Segna come pagato
            </Button>
        </div>
    )
}

export default TipsterSettings
