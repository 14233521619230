import {
    faBoltLightning,
    faClipboard,
    faCog,
    faIdBadge,
    faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Swal from 'sweetalert2'
import { useAppState } from '../../context/AppState'
import Tipster from '../../models/tipster.model'
import { buildRefererUrl } from '../../utils'
import Button from '../Button'
import ForecastList from '../ForecastList'
import GridLayout from '../GridLayout'
import TipsterSettings from './TipsterSettings'
import TipsterUsers from './TipsterUsers'
import { useAuth } from '../../context/Auth'

type Props = {
    tipster: Tipster
    index: number
}

const TipsterRow = ({ tipster, index }: Props) => {
    const { getUser } = useAuth()
    const { toggleModal } = useAppState()
    return (
        <div
            className={`text-xxs md:text-xs lg:p-2 rounded bg-black/[.${
                index % 2 == 0 ? '2' : '05'
            }]`}
        >
            <GridLayout
                padding={2}
                elements={[
                    {
                        grid: { cols: 1, rows: 1, lg: { rows: 1 } },
                        content: (
                            <FontAwesomeIcon
                                className={
                                    tipster.affiliate ? 'text-yellow-600' : ''
                                }
                                icon={faIdBadge}
                            />
                        ),
                    },
                    {
                        grid: { cols: 3, rows: 1, lg: { rows: 1 } },
                        content: <div>{tipster.username}</div>,
                    },

                    {
                        grid: { cols: 3, rows: 1, lg: { rows: 1 } },
                        content: (
                            <div className="flex gap-1">
                                {getUser()?.admin && (
                                    <Button
                                        onClick={() =>
                                            toggleModal(
                                                true,
                                                'Impostazioni',
                                                <TipsterSettings
                                                    tipster={tipster}
                                                />,
                                            )
                                        }
                                        size="xs"
                                    >
                                        <FontAwesomeIcon icon={faCog} />
                                    </Button>
                                )}
                                <Button
                                    onClick={() =>
                                        toggleModal(
                                            true,
                                            'Utenti affiliati',
                                            <TipsterUsers
                                                tipsterId={tipster.tipsterId}
                                            />,
                                        )
                                    }
                                    size="xs"
                                >
                                    <FontAwesomeIcon icon={faUser} />
                                </Button>
                                <Button
                                    onClick={() =>
                                        toggleModal(
                                            true,
                                            'Pronostici del tipster',
                                            <ForecastList
                                                readOnly
                                                tipsterId={tipster.tipsterId}
                                            />,
                                        )
                                    }
                                    size="xs"
                                >
                                    <FontAwesomeIcon icon={faBoltLightning} />
                                </Button>
                                <Button
                                    onClick={() => {
                                        const refererURL = buildRefererUrl(
                                            tipster.username,
                                            'tipster',
                                        )
                                        if (window.isSecureContext) {
                                            navigator.clipboard
                                                .writeText(refererURL)
                                                .then(() => {
                                                    Swal.fire({
                                                        toast: true,
                                                        title: 'Referral link copiato!',
                                                        position:
                                                            'bottom-right',
                                                        showConfirmButton:
                                                            false,
                                                        showCloseButton: true,
                                                        timer: 2000,
                                                    })
                                                })
                                        } else {
                                            Swal.fire({
                                                toast: true,
                                                html: `<a href="${refererURL}">${refererURL}</a>`,
                                                position: 'bottom-right',
                                                showConfirmButton: false,
                                                showCloseButton: true,
                                                // timer: 2000,
                                            })
                                        }
                                    }}
                                    size="xs"
                                >
                                    <FontAwesomeIcon icon={faClipboard} />
                                </Button>
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    )
}

export default TipsterRow
