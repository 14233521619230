import classNames from 'classnames'
import styled from 'styled-components'
import tw from 'twin.macro'

const ScrollableContent = styled.div.attrs((props) => {
    return {
        className: classNames({
            [props.className as string]: props.className,
            'relative scroll-smooth overflow-y-scroll w-full h-full': true,
        }),
    }
})`
    & > * {
        ${tw`absolute inset-0`}
    }
`

export default ScrollableContent
