import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import Button from '../../components/Button'
import InputField from '../../components/InputField'
import { useApi } from '../../context/Api'
import { UserProfileDataDTO } from '../../models/user-profile-data.dto'
import { UserSocials } from '../../models/user.model'

const UserProfileUpdateForm = (props: any) => {
    const { updateUserData, getUserData } = useApi()
    const [dirty, setDirty] = useState(false)
    const [user, setUser] = useState<UserProfileDataDTO | null>()
    const onChangeSocial = (field: string, value: string) => {
        setDirty(true)
        const socials =
            user?.socials ||
            ({
                tiktok: '',
                instagram: '',
                facebook: '',
                telegram: '',
            } as UserSocials)
        const newUser: UserProfileDataDTO = {
            ...user!,
            socials: { ...socials, [field]: value },
        }
        setUser(newUser)
    }
    const onChange = (field: string, value: string) => {
        setDirty(true)
        const newUser = { ...user!, [field]: value }
        setUser(newUser)
    }
    const location = useLocation() as { state: { user: UserProfileDataDTO } }
    useEffect(() => {
        if (location.state?.user) {
            setUser(location.state.user)
            window.history.replaceState({}, document.title)
        } else {
            getUserData().then((res) => setUser(res))
        }
    }, [])
    return (
        <div>
            <h3 className="text-primary/[.7]">Aggiorna il profilo</h3>
            {user && (
                <form>
                    <InputField
                        label="Email"
                        type="text"
                        disabled
                        className="mt-2 mb-4"
                        value={user!.email}
                        onChange={(e) => null}
                    />

                    <InputField
                        label="Username"
                        disabled
                        type="text"
                        className="my-2 mb-4"
                        value={user!.username}
                        onChange={(e) => null}
                    />

                    <InputField
                        label="Nome"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.nome || ''}
                        onChange={(e) => onChange('nome', e.target.value)}
                    />

                    <InputField
                        label="Cognome"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.cognome || ''}
                        onChange={(e) => onChange('cognome', e.target.value)}
                    />

                    <InputField
                        label="Instagram"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.socials?.instagram || ''}
                        onChange={(e) =>
                            onChangeSocial('instagram', e.target.value)
                        }
                    />

                    <InputField
                        label="Facebook"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.socials?.facebook || ''}
                        onChange={(e) =>
                            onChangeSocial('facebook', e.target.value)
                        }
                    />

                    <InputField
                        label="TikTok"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.socials?.tiktok || ''}
                        onChange={(e) =>
                            onChangeSocial('tiktok', e.target.value)
                        }
                    />

                    <InputField
                        label="Telegram"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.socials?.telegram || ''}
                        onChange={(e) =>
                            onChangeSocial('telegram', e.target.value)
                        }
                    />

                    <Button
                        disabled={!dirty}
                        onClick={() => {
                            setDirty(false)
                            updateUserData(user!).then((res) => {
                                Swal.fire({ title: 'Profilo aggiornato!' })
                            })
                        }}
                        color="primary"
                        className="rounded w-full"
                    >
                        Aggiorna
                    </Button>
                </form>
            )}
        </div>
    )
}

export default UserProfileUpdateForm
