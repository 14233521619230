import {
    faBoltLightning,
    faClock,
    faGlobe,
    faRankingStar,
    faShop,
    faTachometerAlt,
    faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { NavItem } from '../../components/Nav'

const adminNavItems: NavItem[] = [
    {
        text: 'Dashboard',
        icon: faTachometerAlt,
        to: 'dashboard',
    },
    {
        text: 'In vendita',
        icon: faShop,
        to: 'selling',
    },
    {
        text: 'Pronostici',
        icon: faBoltLightning,
        to: 'pronostici',
    },
    {
        text: 'Storico',
        icon: faClock,
        to: 'storico',
    },
    {
        text: 'Classifiche',
        icon: faRankingStar,
        to: 'charts',
    },
    {
        text: 'Utenti',
        icon: faUsers,
        to: 'utenti',
    },
]

export default adminNavItems
