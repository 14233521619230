import { useApi } from '../../context/Api'
import { useAppState } from '../../context/AppState'
import usePublishForecast from '../../hooks/usePublishForecast'
import ForecastModel from '../../models/forecast.model'
import { zonedDate } from '../../utils'
import { pronosticoGrid } from '../ForecastList'
import GridLayout from '../GridLayout'
import Styled from './Styled'
import { MatchGrid, PriceTag } from './common'
import {
    ForecastStatus,
    forecastToString,
    forecastsToList,
    oneCellGrid,
} from './utilities'

type Props = {
    forecast: ForecastModel
    readOnly?: boolean
}

const SingleForecast = (forecast: ForecastModel) => (
    <GridLayout
        padding={0}
        gap={0}
        elements={[
            {
                grid: { cols: 3 },
                content: <MatchGrid match={forecast.match} />,
            },
            {
                grid: pronosticoGrid,
                content: forecastToString(forecast),
            },
        ]}
    />
)

const CreatedForecast = ({ forecast, readOnly }: Props) => {
    const { removeForecast } = useAppState()
    const { deleteForecast } = useApi()
    const publishForecast = usePublishForecast()
    const publish = async () => {
        const result = await publishForecast(forecast)
        if (result) {
            forecast.status = 'selling'
        }
    }
    const _delete = async () => {
        const res = await deleteForecast(forecast.id)
        removeForecast(forecast.id)
    }

    const { setSelectedForecasts } = useAppState()
    const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked
        setSelectedForecasts(checked, forecast as ForecastModel)
    }
    return (
        <Styled>
            <div className="absolute -top-1 left-0 right-0 flex justify-center items-start">
                {forecast.multi && (
                    <span className="bg-gray-900 rounded-md px-1 text-white text-[7px] leading-3 uppercase">
                        multipla
                    </span>
                )}
            </div>
            <GridLayout
                padding={0}
                gap={1}
                elements={[
                    {
                        grid: { cols: 1, rows: 1, lg: { rows: 1 } },
                        content: (
                            <ForecastStatus
                                forecast={forecast}
                                onPublish={publish}
                                onDelete={_delete}
                                readOnly={readOnly}
                            />
                        ),
                    },
                    {
                        grid: { cols: 4, rows: 1, lg: { rows: 1 } },
                        content: (
                            <div className=" divide-y divide-dashed divide-gray-600">
                                {forecastsToList(forecast).map((f) => (
                                    <div key={f.id} className="py-1">
                                        {SingleForecast(f)}
                                    </div>
                                ))}
                            </div>
                        ),
                    },
                    {
                        grid: oneCellGrid,
                        content: <PriceTag price={forecast.price} />,
                    },

                    {
                        grid: { cols: 1, rows: 1, lg: { rows: 1 } },
                        content:
                            forecast.multi || readOnly ? (
                                ''
                            ) : (
                                <div>
                                    {(process.env.REACT_APP_DEBUGGING ==
                                        'true' ||
                                        zonedDate(
                                            (forecast as ForecastModel).match
                                                .date,
                                        ) > new Date()) && (
                                        <input
                                            id={forecast.id}
                                            name={forecast.id}
                                            type="checkbox"
                                            onChange={onSelect}
                                        />
                                    )}
                                </div>
                            ),
                    },
                ]}
            />
        </Styled>
    )
}

export default CreatedForecast
