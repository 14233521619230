import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import Button from '../../components/Button'
import InputField from '../../components/InputField'
import Hr from '../../components/styled/Hr'
import { useApi } from '../../context/Api'
import {
    TipsterDetails,
    TipsterPaymentsMethods,
} from '../../models/tipster.model'
import { UserProfileDataDTO } from '../../models/user-profile-data.dto'
import { UserSocials } from '../../models/user.model'
import routes from '../../routing/routes'
import PaymentsUpdateForm from './PaymentsUpdateForm'

const DEFAULT_PAYMENTS: TipsterPaymentsMethods = {
    paypal: { email: '', numero: '' },
    neteller: { id: '', nome: '', cognome: '' },
    skrill: { id: '', nome: '', cognome: '' },
    favourite: 'none',
}

const TipsterProfileUpdateForm = (props: any) => {
    const { updateUserData, getUserData } = useApi()
    const [dirty, setDirty] = useState(false)
    const [user, setUser] = useState<UserProfileDataDTO | null>()

    const onChangeSocial = (field: string, value: string) => {
        setDirty(true)
        const socials =
            user?.socials ||
            ({
                tiktok: '',
                instagram: '',
                facebook: '',
                telegram: '',
            } as UserSocials)
        const newUser: UserProfileDataDTO = {
            ...user!,
            socials: { ...socials, [field]: value },
            tipsterDetails: {
                ragioneSociale: '',
                partitaIVA: '',
                payments: DEFAULT_PAYMENTS,
            },
        }
        setUser(newUser)
    }

    const onChangeTipsterDetail = (field: string, value: string) => {
        setDirty(true)
        const details =
            user?.tipsterDetails ||
            ({
                partitaIVA: '',
                ragioneSociale: '',
                payments: DEFAULT_PAYMENTS,
            } as TipsterDetails)
        const newUser: UserProfileDataDTO = {
            ...user!,
            tipsterDetails: { ...details, [field]: value },
        }
        setUser(newUser)
    }

    const onChange = (field: string, value: string) => {
        setDirty(true)
        const newUser = { ...user!, [field]: value }

        setUser(newUser)
    }
    const location = useLocation() as { state: { user: UserProfileDataDTO } }
    const initUser = async () => {
        let user
        if (location.state?.user) {
            user = location.state.user
            window.history.replaceState({}, document.title)
        } else {
            user = await getUserData()
        }
        if (user.tipsterDetails && !user.tipsterDetails.payments) {
            user.tipsterDetails.payments = DEFAULT_PAYMENTS
        }
        setUser(user)
    }
    useEffect(() => {
        initUser()
    }, [])
    return (
        <div>
            <h3 className="text-primary/[.7]">Aggiorna il profilo</h3>
            {user && (
                <form>
                    <InputField
                        label="Email"
                        type="text"
                        disabled
                        className="mt-2 mb-4"
                        value={user!.email || ''}
                        onChange={(e) => null}
                    />
                    <InputField
                        label="Username"
                        disabled
                        type="text"
                        className="my-2 mb-4"
                        value={user!.username || ''}
                        onChange={(e) => null}
                    />
                    <InputField
                        label="Ragione sociale"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.tipsterDetails?.ragioneSociale || ''}
                        onChange={(e) =>
                            onChangeTipsterDetail(
                                'ragioneSociale',
                                e.target.value,
                            )
                        }
                    />
                    <InputField
                        label="Partita IVA"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.tipsterDetails?.partitaIVA || ''}
                        onChange={(e) =>
                            onChangeTipsterDetail('partitaIVA', e.target.value)
                        }
                    />
                    <InputField
                        label="Nome"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.nome || ''}
                        onChange={(e) => onChange('nome', e.target.value)}
                    />
                    <InputField
                        label="Cognome"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.cognome || ''}
                        onChange={(e) => onChange('cognome', e.target.value)}
                    />
                    <Hr />
                    <h2>Metodi di pagamento</h2>
                    <div className="text-xs font-light my-2 py-1 px-2 rounded bg-primary-900/[.5] border-primary-800 border flex gap-2 items-center">
                        <FontAwesomeIcon icon={faExclamationCircle} />
                        <span>
                            Assicurati che i dati siano corretti per poter
                            ricevere i pagamenti.
                        </span>
                    </div>
                    <div className="text-xs my-2 font-light">
                        Se attualmente non disponi nessuno dei metodi di
                        pagamento,{' '}
                        <Link
                            className="text-primary underline"
                            to={routes.tipsterHelp}
                        >
                            contatta il supporto
                        </Link>
                    </div>
                    <PaymentsUpdateForm
                        payments={user.tipsterDetails?.payments!}
                        onUpdate={(payments) => {
                            setDirty(true)

                            console.log(payments)
                            setUser({
                                ...user,
                                tipsterDetails: {
                                    ...user.tipsterDetails!,
                                    payments,
                                },
                            })
                        }}
                    />
                    <Hr />
                    <h2>Social</h2>
                    <InputField
                        label="Instagram"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.socials?.instagram || ''}
                        onChange={(e) =>
                            onChangeSocial('instagram', e.target.value)
                        }
                    />
                    <InputField
                        label="Facebook"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.socials?.facebook || ''}
                        onChange={(e) =>
                            onChangeSocial('facebook', e.target.value)
                        }
                    />
                    <InputField
                        label="TikTok"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.socials?.tiktok || ''}
                        onChange={(e) =>
                            onChangeSocial('tiktok', e.target.value)
                        }
                    />
                    <InputField
                        label="Telegram"
                        type="text"
                        className="my-2 mb-4"
                        value={user!.socials?.telegram || ''}
                        onChange={(e) =>
                            onChangeSocial('telegram', e.target.value)
                        }
                    />
                    <Button
                        disabled={!dirty}
                        onClick={() => {
                            setDirty(false)
                            console.log(user)
                            updateUserData(user!).then((res) => {
                                Swal.fire({ title: 'Profilo aggiornato!' })
                            })
                        }}
                        color="primary"
                        className="rounded w-full"
                    >
                        Aggiorna
                    </Button>
                </form>
            )}
        </div>
    )
}

export default TipsterProfileUpdateForm
