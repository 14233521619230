import { ChangeEvent, useEffect, useState } from 'react'
import { HandicapData } from '../../../models/forecast-composition.model'
import ToggleSwitch from '../../ToggleSwitch'

const values: HandicapData[] = [-1, -2, -3, -4, -5]

type Props = {
    onChange: (data: HandicapData) => void
}

const HandicapForm = (props: Props) => {
    const ids = values.map((v) => v.toString() + Math.random())

    const [data, setData] = useState<HandicapData>(-1)

    const onCheck = (
        e: ChangeEvent<HTMLInputElement>,
        handicap: HandicapData,
    ) => {
        e.target.checked && setData(handicap)
    }
    useEffect(() => props.onChange(data), [data])

    return (
        <div className="flex gap-1 justify-start md:justify-around">
            {values.map((d, i) => (
                <ToggleSwitch
                    id={ids[i]}
                    key={d.toString()}
                    label={d.toString()}
                    checked={data == d}
                    onChange={(e) => onCheck(e, d)}
                />
            ))}
        </div>
    )
}

export default HandicapForm
