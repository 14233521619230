import styled from 'styled-components'

type Props = {
    style?: React.CSSProperties
    className?: string
    placeHolder?: string
}

const Input = styled.input.attrs((props) => {
    return {
        className:
            props.className +
            ' pl-4 bg-black/[.15] border h-10 rounded shadow border-1 border-primary/[.15] ' +
            'text-sm disabled:bg-black/[.05] disabled:text-gray-500',
    }
})``

// ({ className, style, placeHolder }: Props) => {
//     return (
//         <input
//             type="text"
//             style={style}
//             className={
//                 className +
//                 " "
//             }
//             placeholder={placeHolder}
//         />
//     )
// }
export default Input
