import {
    faBoltLightning,
    faClock,
    faGlobe,
    faTachometerAlt,
    faUsers,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

export type NavItem = {
    text: string
    icon: IconDefinition
    to: string
    golden?: boolean
}

type Props = {
    items: NavItem[]
}

const activeClasses = ['border-b', 'border-primary', 'bg-white/[.035]']

const Nav = (props: Props) => {
    return (
        <nav className="shrink-0 bg-black/[.45] h-16 flex justify-center items-center text-primary mb-1">
            {props.items.map((item, i) => (
                <NavLink
                    key={i}
                    to={item.to}
                    className={({ isActive }) =>
                        classNames({
                            'flex flex-col w-40 items-center text-center h-full justify-center cursor-pointer':
                                true,
                            'border-b-4 border-primary-600 bg-secondary-500':
                                isActive,
                            'text-yellow-400': item.golden,
                        })
                    }
                >
                    <FontAwesomeIcon className="pb-1" icon={item.icon} />
                    <small
                        className={classNames({
                            'text-xxs font-light': true,
                        })}
                    >
                        {item.text}
                    </small>
                </NavLink>
            ))}
        </nav>
    )
}
export default Nav
