import { useEffect, useState } from 'react'
import { useApi } from '../context/Api'
import { useAppState } from '../context/AppState'
import ForecastModel, { MultiForecastModel } from '../models/forecast.model'
import CreatedForecast from './Forecast/CreatedForecast'
import GridLayout, { ElemGrid } from './GridLayout'
import BoxHeader from './styled/BoxHeader'

export const teamGrid: ElemGrid = { cols: 2, rows: 1, lg: { cols: 1 } }
export const pronosticoGrid: ElemGrid = {
    cols: 2,
    rows: 2,
    lg: { rows: 1, cols: 1 },
}
export const statusGrid: ElemGrid = { cols: 1, rows: 2, lg: { rows: 1 } }

type Props = {
    tipsterId?: string
    readOnly?: boolean
    forecasts?: ForecastModel[]
}
const ForecastList = (props: Props) => {
    const { switchLoading } = useAppState()
    const [forecasts, setForecasts] = useState<ForecastModel[]>([])
    const { getForecasts } = useApi()
    useEffect(() => {
        if (!props.forecasts) {
            switchLoading(true)
            getForecasts(props.tipsterId).then((res) => {
                setForecasts(res)
                switchLoading(false)
            })
        }
    }, [])
    useEffect(() => {
        props.forecasts && setForecasts(props.forecasts)
    }, [props.forecasts])
    return (
        <div className="p-2 flex flex-col gap-2">
            {forecasts.map((f: ForecastModel) => (
                <CreatedForecast
                    key={f.id}
                    readOnly={props.readOnly}
                    forecast={f}
                />
            ))}
        </div>
    )
}

export default ForecastList
