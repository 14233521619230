import {
    faBoltLightning,
    faCircleCheck,
    faCircleXmark,
    faClock,
    faFutbol,
    faNoteSticky,
    faPlus,
    faShoppingCart,
    faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useAuth } from '../../context/Auth'
import {
    Combo,
    EsattoData,
    ForecastComposition,
    HandicapData,
    MultiGoalData,
    UnderOverData,
} from '../../models/forecast-composition.model'
import ForecastModel, { MultiForecastModel } from '../../models/forecast.model'
import { zonedDate } from '../../utils'
import Button from '../Button'
import { Elem, ElemGrid } from '../GridLayout'

export const oneCellGrid: ElemGrid = { cols: 1, rows: 1 }

export const inGameStatus = (
    <div className="text-primary text-center">
        <FontAwesomeIcon className="animate-ping" icon={faFutbol} />
        <span className="hidden sm:block font-bold">IN GIOCO</span>
    </div>
)
export const scheduledStatus = <FontAwesomeIcon icon={faClock} />

export const ForecastStatus = ({
    forecast,
    onPublish,
    onDelete,
    readOnly,
}: {
    forecast: ForecastModel | MultiForecastModel
    onPublish?: () => void
    onDelete?: () => void
    readOnly?: boolean
}) => {
    const { getUser } = useAuth()
    switch (true) {
        case forecast.status == 'draft':
            return (
                <>
                    {!readOnly && onPublish && onDelete && (
                        <div className="flex gap-1 flex-col w-full">
                            <Button
                                onClick={onPublish}
                                size="xs"
                                color="success"
                                className="w-full"
                            >
                                <FontAwesomeIcon icon={faBoltLightning} />{' '}
                                <span className="hidden sm:inline">
                                    Pubblica
                                </span>
                            </Button>
                            <Button
                                onClick={onDelete}
                                size="xs"
                                className="w-full"
                            >
                                <FontAwesomeIcon icon={faTrashCan} />{' '}
                                <span className="hidden sm:inline">
                                    Elimina
                                </span>
                            </Button>
                        </div>
                    )}
                    {readOnly && (
                        <div className=" text-center text-gray-500">
                            <FontAwesomeIcon icon={faNoteSticky} />
                            <span className="hidden sm:block font-bold">
                                BOZZA
                            </span>
                        </div>
                    )}
                </>
            )
        case forecast.multi &&
            (forecast as MultiForecastModel).forecasts.some(
                (f: ForecastModel) => zonedDate(f.match.date) <= new Date(),
            ):
            return inGameStatus
        case !forecast.multi &&
            zonedDate((forecast as ForecastModel).match.date) <= new Date():
            return inGameStatus
        case forecast.status == 'selling' &&
            (getUser()?.tipster || getUser()?.admin):
            return (
                <div className="text-success text-center">
                    <FontAwesomeIcon icon={faShoppingCart} />
                    <span className="hidden sm:block">in vendita</span>
                </div>
            )
        case forecast.status == 'selling':
            return (
                <div>
                    <FontAwesomeIcon icon={faClock} />
                </div>
            )
        case forecast.status == 'closed':
            return <FontAwesomeIcon icon={faClock} />
        default:
            return <></>
    }
}

export const dateGrid = (hidden = false): ElemGrid => ({
    rows: 2,
    cols: 1,
    hidden,
    lg: { rows: 1 },
})

export const dateGridelement = (
    matchId: number,
    matchDate: Date,
    hidden = false,
): Elem => ({
    grid: dateGrid(hidden),
    content: dateGridContent(matchId, matchDate),
})

export const dateGridContent = (matchId: number, matchDate: Date) => {
    let date
    let time
    try {
        date = format(zonedDate(matchDate), 'dd/MM/yyyy')
        time = format(matchDate, 'HH:mm')
    } catch (error) {}
    return (
        <div className="lg:block text-xs text-center text-white">
            <div className="text-xxs sm:block">{matchId}</div>
            <div className="text-xxs sm:text-xs">{date}</div>
            <div className="text-xxs sm:text-xs"> {time}</div>
        </div>
    )
}

export const teamGridContent = (teamName: string) => (
    <div className="text-center leading-tight py-[1px] font-bold truncate text-xxs sm:text-xs text-yellow-400">
        {teamName}
    </div>
)

export const teamGridElement = (teamName: string): Elem => ({
    grid: { cols: 5, rows: 1 },
    content: teamGridContent(teamName),
})

export const esitoGridElement = (success: boolean): Elem => ({
    grid: { cols: 1, rows: 2, lg: { rows: 1 } },
    content: esitoGridContent(success),
})

export const esitoGridContent = (success: boolean) => (
    <div className="text-center text-lg px-2 md:px-4">
        {success === true ? (
            <FontAwesomeIcon icon={faCircleCheck} className="text-success" />
        ) : success === false ? (
            <FontAwesomeIcon icon={faCircleXmark} className="text-danger" />
        ) : (
            ''
        )}
    </div>
)

export const pronosticoGridElem = (forecast: ForecastModel) => ({
    grid: { cols: 2, rows: 2, lg: { rows: 1 } },
    content: <div className="text-center">{forecastToString(forecast)}</div>,
})

const Parziale = ({
    composition,
}: {
    composition: ForecastComposition | Combo
}) => (
    <span className="text-xxxs px-[3px] py-[1px] rounded-sm bg-gray-700 font-bold inline-block whitespace-nowrap">
        {composition.parziali == 'primo_tempo'
            ? '1° t'
            : composition.parziali == 'secondo_tempo'
            ? '2° t'
            : ''}
    </span>
)

const Team = ({
    composition,
}: {
    composition: ForecastComposition | Combo
}) => (
    <span className="text-xxxs px-[3px] py-[1px] rounded-sm bg-gray-700 font-bold inline-block whitespace-nowrap">
        {composition.team == 'in_casa'
            ? 'home'
            : composition.team == 'fuori_casa'
            ? 'away'
            : ''}
    </span>
)
export const parzialeAndTeam = (composition: ForecastComposition | Combo) => {
    return (
        <>
            {(composition.parziali != 'fine_partita' ||
                composition.team != 'nd') && (
                <div className="flex flex-col gap-1 items-center">
                    {composition.parziali != 'fine_partita' && (
                        <Parziale composition={composition} />
                    )}
                    {composition.team != 'nd' && (
                        <Team composition={composition} />
                    )}
                </div>
            )}
        </>
    )
}
export const forecastToString = (forecast: ForecastModel) => {
    return (
        <div className="leading-none flex flex-col items-center text-center">
            <div className="flex items-center gap-1">
                {parzialeAndTeam(forecast.composition)}
                {pronosticoToString(forecast.composition)}
            </div>
            {forecast.composition.combo && (
                <>
                    <FontAwesomeIcon className="text-xs my-1" icon={faPlus} />
                    <div className="flex items-center gap-1">
                        {parzialeAndTeam(forecast.composition.combo)}
                        {pronosticoToString(forecast.composition.combo)}
                    </div>
                </>
            )}
        </div>
    )
}

const pronosticoToString = (composition: ForecastComposition | Combo) => {
    let data = composition.tipoData
    switch (composition.tipo) {
        case 'esatto':
            data = data as EsattoData
            return (
                <div>
                    <div className="text-xxxs leading-4">ESATTO</div>
                    <div className="font-bold">
                        {data.goal_in_casa} - {data.goal_fuori_casa}
                    </div>
                </div>
            )

        case 'esito':
            return <div className="font-bold text-center">{data as string}</div>
        case 'goal/no_goal':
            return (
                <div className="font-bold text-xxs">
                    {data ? 'GOAL' : 'NO GOAL'}
                </div>
            )
        case 'handicap':
            data = data as HandicapData
            return <div className="font-bold">{'H ' + data}</div>
        case 'under/over':
            data = data as UnderOverData
            return (
                <div>
                    <div className="uppercase text-xxxs">
                        {data.over ? 'over' : 'under'}
                    </div>
                    <div className="font-bold">{data.over || data.under}</div>
                </div>
            )
        case 'multigoal':
            data = data as MultiGoalData

            return (
                <div className="text-center">
                    <div className="text-xxxs leading-tight">MULTIGOAL</div>
                    <div className="font-bold ">{data}</div>
                </div>
            )
        default:
            return ''
    }
}

export const getMatches = (forecast: ForecastModel | MultiForecastModel) => {
    if (forecast.multi) {
        return (forecast as MultiForecastModel).forecasts.map((f) => f.match)
    } else if (!forecast.multi) {
        return [(forecast as ForecastModel).match]
    } else {
        return []
    }
}

export const forecastsToList = (
    forecast: ForecastModel | MultiForecastModel,
) => {
    let result: ForecastModel[] = []
    if (forecast.multi) {
        result = (forecast as MultiForecastModel).forecasts
    } else if (!forecast.multi) {
        result = [forecast as ForecastModel]
    }

    return result
}
