import { faCompress, faExpand, faList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Box from '../../components/Box'
import Button from '../../components/Button'
import ForecastList from '../../components/ForecastList'
import Palinsesto from '../../components/Palinsesto'
import { useApi } from '../../context/Api'
import { useAppState } from '../../context/AppState'
import { useAuth } from '../../context/Auth'

const Pronostici = () => {
    const { createForecast, getForecasts } = useApi()
    const { getUser } = useAuth()
    const saveMultiDraft = async () => {
        // for (let f of selectedForecasts) {
        //     let idx = newForecasts.indexOf(f)
        //     newForecasts.splice(idx, 1)
        // }
        const uniqueMatchIds = selectedForecasts.map((f) => f.match.id)
        if (new Set(uniqueMatchIds).size != selectedForecasts.length) {
            Swal.fire({
                text: 'I pronostici devono essere per match diversi.',
            })
            return
        }

        Swal.fire({
            input: 'number',
            title: 'Inserisci lo stake',
            text: 'Valore compreso tra 1 e 10. Lascia vuoto per non inserire lo stake.',
            showDenyButton: true,
            showConfirmButton: true,
            showCloseButton: true,
        }).then(async (result) => {
            const stake: number | undefined = result.value

            if (stake && (stake < 1 || stake > 10)) {
                Swal.fire({
                    text: 'Stake non valido. Imposta un valore diverso.',
                })
                return
            }

            const newForecast = await createForecast({
                multi: {
                    forecasts: selectedForecasts.map((f) => f.id),
                    stake: stake || undefined,
                },
            })
            if (!(newForecast instanceof AxiosError)) {
                let newForecasts = [newForecast, ...forecasts]

                setForecasts(newForecasts)
                emptySelectedForecasts()
            }
        })
    }
    const {
        selectedForecasts,
        emptySelectedForecasts,
        setForecasts,
        forecasts,
    } = useAppState()
    useEffect(() => {
        getForecasts().then((res) => setForecasts(res))
    }, [])
    const [expanded, setExpanded] = useState([true, true])
    return (
        <div className="flex flex-col md:flex-row gap-2 h-full">
            <Box
                title="Palinsesto"
                className={`flex-${expanded[0] ? '1' : '0'} ${
                    expanded[1] && !expanded[0] && 'md:hidden'
                }`}
                scrollable
                headerLeftAction={
                    <FontAwesomeIcon
                        onClick={() => setExpanded([true, !expanded[1]])}
                        className="text-primary cursor-pointer p-2 border-primary/[.1]
                        bg-primary/[.05] rounded-lg border"
                        icon={expanded[1] ? faExpand : faCompress}
                    />
                }
            >
                <Palinsesto />
            </Box>
            <Box
                title="Pronostici"
                className={`flex-${expanded[1] ? '1' : '0'}  ${
                    expanded[0] && !expanded[1] && 'md:hidden'
                }`}
                scrollable
                headerLeftAction={
                    <FontAwesomeIcon
                        onClick={() => setExpanded([!expanded[0], true])}
                        className="text-primary cursor-pointer p-2 border-primary/[.1] bg-primary/[.05] rounded-lg border"
                        icon={expanded[0] ? faExpand : faCompress}
                    />
                }
                headerRightAction={
                    selectedForecasts.length > 1 && (
                        <Button
                            color="primary"
                            size="xs"
                            className="ml-auto block uppercase text-xxs"
                            onClick={saveMultiDraft}
                        >
                            <FontAwesomeIcon icon={faList} /> multi
                        </Button>
                    )
                }
            >
                <ForecastList forecasts={forecasts} />
            </Box>
        </div>
    )
}

export default Pronostici
