import { useEffect, useRef, useState } from 'react'
import FilterBar from './FilterBar'
import ScrollableContent from '../ScrollableContent'

type Props<T> = {
    items: T[]
    itemBuilder: (data: T, index?: number) => React.ReactNode
    onPageChange: (pageNum: number) => void
    onTextSearch?: (text: string) => void
    onDateSearch?: (from: Date, to: Date) => void
    perPage: number
    total: number
    textSearchPlaceholder?: string
    gap?: number
}

const PaginatedList = <T,>(props: Props<T>) => {
    const scrollableElem = useRef<HTMLDivElement>(null)

    useEffect(() => {
        props.onPageChange(0)
    }, [])
    return (
        <div className="flex flex-col h-full">
            <div className="mb-2">
                <FilterBar
                    onPageChange={(selectedItem) => {
                        props.onPageChange(selectedItem.selected)
                        if (scrollableElem.current) {
                            scrollableElem.current.scrollTop = 0
                        }
                    }}
                    totalElements={props.total}
                    perPage={props.perPage}
                    dateSearchEnabled={!!props.onDateSearch}
                    textSearchEnabled={!!props.onTextSearch}
                    textSearchPlaceholder={props.textSearchPlaceholder}
                    onTextSearch={props.onTextSearch}
                />
            </div>
            <ScrollableContent ref={scrollableElem} className="grow">
                <div className={`flex flex-col gap-${props.gap || 2}`}>
                    {props.items.map((t, i) =>
                        props.itemBuilder(props.items[i], i),
                    )}
                </div>
            </ScrollableContent>
        </div>
    )
}

export default PaginatedList
