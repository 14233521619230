import { useEffect, useState } from 'react'
import { useApi } from '../../context/Api'
import { useAppState } from '../../context/AppState'
import User from '../../models/user.model'
import GridLayout from '../GridLayout'
import SearchBar from '../SearchBar'
import UtentiRow from './UtentiRow'
import { format } from 'date-fns'
import { zonedDate } from '../../utils'

export const UtentiHeader = () => (
    <div className="text-xxs bg-black/[.5] text-white/[.55] lg:p-2">
        <GridLayout
            elements={[
                {
                    grid: { cols: 1, rows: 1, lg: { rows: 1 } },
                    content: <></>,
                },
                {
                    grid: { cols: 3, rows: 1, lg: { rows: 1 } },
                    content: <div>USERNAME</div>,
                },

                {
                    grid: { cols: 1, rows: 1, lg: { rows: 1 } },
                    content: <div>BC</div>,
                },
                {
                    grid: { cols: 2, rows: 1, lg: { rows: 1 } },
                    content: <div>AZIONI</div>,
                },
            ]}
        />
    </div>
)

const UtentiList = () => {
    const { switchLoading } = useAppState()
    const [users, setUsers] = useState<User[]>([])
    const { getUsers } = useApi()

    const [filterQuery, setFilterQuery] = useState('')

    const getData = async () => {
        switchLoading(true)
        const data = await getUsers()
        setUsers(data)
        switchLoading(false)
    }

    const filter = (u: User) => {
        const query = filterQuery.toLowerCase()

        return (
            !filterQuery ||
            [
                u.nome,
                u.cognome,
                u.username,
                format(zonedDate(new Date(u.registrationDate!)), 'dd/MM/yyyy'),
            ].some((v) => v && v.toLowerCase().match(query))
        )
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <div>
            <div>
                <SearchBar
                    onSearch={(query) => setFilterQuery(query)}
                    hintText="Nome, cognome, username o data di registrazione"
                />
            </div>

            <UtentiHeader />
            {users.filter(filter).map((u, i) => (
                <UtentiRow
                    onUpdate={(user) => {
                        const idx = users.indexOf(u)
                        const newUsers = [...users]
                        newUsers[idx] = user
                        setUsers(newUsers)
                    }}
                    key={i}
                    user={u}
                    index={i}
                />
            ))}
        </div>
    )
}

export default UtentiList
