import { useEffect, useState } from 'react'
import TipsterListData from '../../models/tipster-list-data.model'
import { getProfilePictureUrl } from '../../utils'
import GridLayout from '../GridLayout'
import { SUCCESS_THRESHOLD } from '../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faMinus } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

type Props = {
    tipsterData: TipsterListData
    adminView?: boolean
    onClick?: (id: string) => void
    rank?: number
}

const THRESHOLD_TEXT = `≤${SUCCESS_THRESHOLD}%`

const TipsterElem = (props: Props) => {
    const [rate, setRate] = useState(THRESHOLD_TEXT)
    useEffect(() => {
        const rate = props.tipsterData.successRate
            ? props.tipsterData.successRate.toFixed(2) + '%'
            : THRESHOLD_TEXT

        setRate(rate)
    }, [])

    const rankDirection =
        props.tipsterData.rateDirection === null ? (
            <FontAwesomeIcon className="text-yellow-600" icon={faMinus} />
        ) : props.tipsterData.rateDirection === false ? (
            <FontAwesomeIcon
                className="text-yellow-600 rotate-180"
                icon={faArrowUp}
            />
        ) : (
            <FontAwesomeIcon className="text-yellow-600" icon={faArrowUp} />
        )
    return (
        <div
            className="bg-secondary-400 rounded-xl px-2 py-1 cursor-pointer shadow flex relative overflow-hidden"
            onClick={() => props.onClick && props.onClick(props.tipsterData.id)}
        >
            {props.rank !== undefined && (
                <div className="absolute rounded-full bg-yellow-600 top-[.5px] left-[.5px] text-xs w-5 h-5 border-2 flex items-center justify-center border-secondary-400 text-white text-center">
                    {props.rank}
                </div>
            )}
            <GridLayout
                className="w-full"
                padding={0}
                elements={[
                    {
                        grid: { cols: 6, fullWidth: true },
                        content: (
                            <div className="whitespace-nowrap overflow-hidden flex gap-2 items-center">
                                <img
                                    className="rounded-full w-10 h-10 inline"
                                    src={getProfilePictureUrl(
                                        props.tipsterData.profilePicture,
                                    )}
                                />
                                <span className="text-white text-left w-full text-xxs font-bold whitespace-normal overflow-ellipsis overflow-hidden flex-1">
                                    {props.tipsterData.username}
                                </span>
                            </div>
                        ),
                    },
                    {
                        grid: { cols: 2, fullWidth: true },
                        content: (
                            <div className="bg-black flex gap-1 w-[45px] justify-center items-center py-[1px] rounded-full text-white text-xs">
                                <div
                                    className={classNames(
                                        'w-[10px] h-[10px] inline-block rounded-full',
                                        {
                                            'bg-green-500':
                                                props.tipsterData
                                                    .onlineForecasts,
                                            'bg-red-500':
                                                !props.tipsterData
                                                    .onlineForecasts,
                                        },
                                    )}
                                />
                                {props.tipsterData.onlineForecasts}
                            </div>
                        ),
                    },
                    {
                        grid: { cols: 2, fullWidth: true },
                        content: (
                            <div className="text-center leading-tight font-bold text-xs text-primary">
                                {rate}
                            </div>
                        ),
                    },

                    {
                        grid: { cols: 1 },
                        content: <div>{rankDirection}</div>,
                    },
                ]}
            />
        </div>
    )
}

export default TipsterElem
