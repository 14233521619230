import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import styled from 'styled-components'

type Props = {
    children?: React.ReactNode
    title?: string
    style?: React.CSSProperties
    className?: string
    onClose?: () => void
    noBackdrop?: boolean
    noCloseButton?: boolean
}

const Modal = ({
    children,
    title,
    style,
    className,
    onClose,
    noBackdrop,
    noCloseButton,
}: Props) => {
    return (
        <div className="z-40 absolute inset-0 w-screen h-screen p-2 lg:p-10 flex justify-center items-center">
            <div
                onClick={onClose}
                className={classNames({
                    '': noBackdrop,
                    'absolute inset-0 backdrop-blur-sm bg-black/[.5]':
                        !noBackdrop,
                })}
            ></div>
            <Styled
                style={style}
                className={`${className} animate__zoomIn flex flex-col`}
            >
                {title && (
                    <div className="relative lg:h-16 h-12 flex justify-center items-center bg-black/[.05] border-b border-white/[.03]">
                        <h2 className="flex-grow text-center">{title}</h2>
                        {!noCloseButton && (
                            <button
                                onClick={onClose}
                                className="absolute p-3 right-0 top-0 hover:bg-white/[.03] rounded-xl"
                            >
                                <FontAwesomeIcon
                                    fixedWidth={true}
                                    icon={faTimes}
                                />
                            </button>
                        )}
                    </div>
                )}
                <div className="flex-grow">{children}</div>
            </Styled>
        </div>
    )
}

export default Modal

const Styled = styled.div.attrs((props) => ({
    className:
        props.className +
        ' rounded bg-secondary border border-secondary-300 text-white shadow-lg animate__animated',
}))``
