import { useEffect, useState } from 'react'
import SellingForecast from '../../../components/Forecast/selling/SellingForecast'
import ScrollableContent from '../../../components/ScrollableContent'
import { NotAvailableTitle } from '../../../components/typography'
import { useAppState } from '../../../context/AppState'
import ForecastModel from '../../../models/forecast.model'
import PaginateQuery from '../../../models/paginate-query.model'
import PaginatedResult from '../../../models/paginated-result'

type Props = {
    get: (query: PaginateQuery) => Promise<PaginatedResult<ForecastModel>>
    allowNext?: boolean
    step?: number
    fromDate?: Date
    toDate?: Date
}
const ForecastsList = (props: Props) => {
    const [forecasts, setForecasts] = useState<ForecastModel[]>([])
    const { switchLoading } = useAppState()
    const [to, setTo] = useState<number>(props.step || 0)
    const [total, setTotal] = useState(0)
    const getForecasts = async (to: number) => {
        const query = {
            fromDate: props.fromDate,
            toDate: props.toDate,
            ...(props.step ? { from: 0, to: to } : {}),
        }
        props.get(query).then((res) => {
            setForecasts(res.data)
            setTotal(res.total)
        })
    }
    useEffect(() => {
        getForecasts(to)
    }, [props.fromDate])

    return (
        <div className="flex flex-col h-full w-full">
            <ScrollableContent>
                <div className="flex flex-col gap-2">
                    {forecasts.map((f) => (
                        <SellingForecast
                            key={f.id}
                            onBuy={(forecast) => {
                                setForecasts(
                                    forecasts.filter((ff) => ff.id != f.id),
                                )
                            }}
                            forecast={f}
                        />
                    ))}
                    {forecasts.length == 0 && <NotAvailableTitle forecasts />}
                    {props.step && total > to && (
                        <button
                            className="rounded text-center font-bold bg-primary-600 text-primary-900 py-2 text-sm"
                            onClick={async () => {
                                setTo(to + props.step!)
                                await getForecasts(to + props.step!)
                            }}
                        >
                            Mostra altri {props.step}
                        </button>
                    )}
                </div>
            </ScrollableContent>
        </div>
    )
}

export default ForecastsList
