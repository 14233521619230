import { useAppState } from '../context/AppState'
import Modal from './Modal'
import ScrollableContent from './ScrollableContent'

const AppModal = () => {
    const { modalContent, modalTitle, toggleModal } = useAppState()
    return (
        <Modal
            title={modalTitle}
            onClose={() => toggleModal(false)}
            className="w-full h-full lg:w-4/5"
        >
            <ScrollableContent>
                <div className="p-3">{modalContent}</div>
            </ScrollableContent>
        </Modal>
    )
}
export default AppModal
