import TipsterOverview from './Overview'

const TipsterDashboard = () => {
    return (
        <div className="h-full flex">
            <TipsterOverview />
        </div>
    )
}

export default TipsterDashboard
