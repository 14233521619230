import { Link, useSearchParams } from 'react-router-dom'
import { useApi } from '../../../context/Api'
import UserRegistrationDataDTO from '../../../models/user-registration-data.dto'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { CommonInputTypes } from './RegisterFields'
import { Wizard } from 'react-use-wizard'
import UsernameStep from './UsernameStep'
import PasswordStep from './PasswordStep'
import { StepBox, StepsWrapper } from './step-elements'
import ProgressBar from './ProgressBar'
import CompleteStep from './CompleteStep'
import EmailStep from './EmailStep'
import { AnimatePresence, motion } from 'framer-motion'
import useLogin from '../../../hooks/useLogin'
import { useEffect, useState } from 'react'

type InputTypes = CommonInputTypes & {
    username: string
    adult: boolean
    terms: boolean
    privacy: boolean
    referer: string
}

const Register = () => {
    const { registerUser } = useApi()
    const methods = useForm<InputTypes>()
    const { handleSubmit, setValue, getValues, setError } = methods
    const login = useLogin()

    const [refererLink, setRefererLink] = useState(false)

    const [searchParams] = useSearchParams()
    useEffect(() => {
        if (searchParams.get('referer')) {
            const referer = searchParams.get('referer')
            setValue('referer', referer!)
            setRefererLink(true)
        }
    }, [])

    const onSubmit = async (fieldValues: FieldValues) => {
        try {
            registerUser(fieldValues as UserRegistrationDataDTO).then(
                async (res) => {
                    if ((res as any).response?.status == 400) {
                        if ((res as any).response.data.message == 'email') {
                            setError('email', {
                                type: 'validate',
                                message: 'Email già esistente',
                            })
                        }
                        if ((res as any).response.data.message == 'username') {
                            setError('username', {
                                type: 'validate',
                                message: 'Username già esistente',
                            })
                        }
                        return
                    }

                    await login(fieldValues.username, fieldValues.password)
                },
                (res) => {
                    if (res.request.status === 400) {
                        console.log(res.request)
                        // setErrors({
                        //     ...errors,
                        //     form: 'Username o email già esistenti',
                        // })

                        return
                    }
                },
            )
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <StepBox>
            <div className="mb-12 mt-8 text-center">
                <h1 className="text-primary font-bold mb-2 text-sm">
                    Benvenuto su
                    <br />
                    <span className="text-4xl">YouTipster!</span>
                </h1>
                <p className="text-white text-xs">
                    La più grande community di Tipster e pronostici al mondo!
                </p>
            </div>
            <div className="grow">
                <FormProvider {...methods}>
                    <form
                        className=" text-white flex flex-col h-full"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <AnimatePresence>
                            <Wizard
                                wrapper={<StepsWrapper />}
                                footer={<ProgressBar total={3} />}
                            >
                                <EmailStep />
                                <UsernameStep />
                                <PasswordStep />
                                <CompleteStep
                                    onComplete={() => console.log(getValues())}
                                />
                            </Wizard>
                        </AnimatePresence>
                    </form>
                </FormProvider>
            </div>
            <Link to="/" className="bg-transparent">
                <div className="text-gray-300 text-xs text-center underline py-4">
                    Torna alla home
                </div>
            </Link>
        </StepBox>
    )
}

export default Register
