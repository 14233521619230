import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ForecastModel from '../../../models/forecast.model'
import MatchModel from '../../../models/match.model'
import GridLayout, { ElemGrid } from '../../GridLayout'
import { MatchDateTime, TeamTag, TipsterName } from '../common'
import Styled from '../Styled'
import { esitoGridContent } from '../utilities'
import { ForecastHeader, commonElements } from './common'

type Props = {
    forecast: ForecastModel
}

const StoricoForecast = ({ forecast }: Props) => (
    <Styled>
        <ForecastHeader forecast={forecast} />

        <div className="flex items-center">
            <GridLayout
                className="px-2 flex-1"
                padding={0}
                gap={0}
                elements={commonElements(forecast)}
            />
            {esitoGridContent(forecast.success)}
        </div>
    </Styled>
)

export default StoricoForecast
