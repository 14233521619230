import {
    Combo,
    ForecastComposition,
    Parziali,
    Team,
    Tipo,
    TipoCombo,
} from '../../models/forecast-composition.model'

export const PARZIALI: Parziali[] = [
    'fine_partita',
    'primo_tempo',
    'secondo_tempo',
]
export const TEAMS: Team[] = ['nd', 'in_casa', 'fuori_casa']

export const TYPES: Tipo[] = [
    'esatto',
    'esito',
    'goal/no_goal',
    'handicap',
    'under/over',
    'multigoal',
]

export type ConstraintConfiguration<T> = {
    default?: T
    available: T[]
}

export const defaultConfiguration = <T>(
    value: T,
    available: T[],
): ConstraintConfiguration<T> => {
    return {
        default: available.includes(value) ? value : available[0],
        available,
    }
}

export const teamOptionConstraints = (
    composition: ForecastComposition,
): ConstraintConfiguration<Team> => {
    const disabledConfig: ConstraintConfiguration<Team> = {
        default: 'nd',
        available: [],
    }
    const defaultConfig = defaultConfiguration<Team>(composition.team, [
        'fuori_casa',
        'in_casa',
        'nd',
    ])
    switch (composition.tipo) {
        case 'handicap':
            return defaultConfiguration<Team>(composition.team, [
                'in_casa',
                'fuori_casa',
            ])
        case 'esatto':
        case 'esito':
        case 'goal/no_goal':
            return disabledConfig
        case 'multigoal':
            if (composition.combo) {
                return disabledConfig
            }
            break
        case 'under/over':
            if (composition.combo && composition.combo.tipo == 'goal/no_goal')
                return disabledConfig
            break
        default:
            return defaultConfig
    }
    return defaultConfig
}

export const parzialiConstraints = (
    composition: ForecastComposition,
): ConstraintConfiguration<Parziali> => {
    const defaultValue = defaultConfiguration<Parziali>(
        composition.parziali,
        PARZIALI,
    )
    const disabledConfig: ConstraintConfiguration<Parziali> = {
        default: 'fine_partita',
        available: [],
    }
    switch (composition.tipo) {
        case 'esito':
        case 'goal/no_goal':
        case 'under/over':
            if (composition.combo) {
                if (composition.combo.tipo == composition.tipo) {
                    return defaultConfiguration<Parziali>(
                        composition.parziali,
                        ['primo_tempo'],
                    )
                } else {
                    return disabledConfig
                }
            }
            break
        case 'esatto':
        case 'multigoal':
            if (composition.combo) {
                return defaultConfiguration<Parziali>(composition.parziali, [
                    'primo_tempo',
                ])
            }
            break
        default:
            return defaultValue
    }
    return defaultValue
}

export const comboConstraints = (
    composition: ForecastComposition,
): ConstraintConfiguration<Tipo> => {
    switch (composition.tipo) {
        case 'esatto':
            return { default: 'esatto', available: ['esatto'] }
        case 'esito':
            return defaultConfiguration<Tipo>(
                composition.combo?.tipo || TYPES[0],
                ['esito', 'goal/no_goal', 'multigoal', 'under/over'],
            )
        case 'multigoal':
            return defaultConfiguration<Tipo>(
                composition.combo?.tipo || TYPES[0],
                ['multigoal'],
            )
        case 'under/over':
        case 'goal/no_goal':
            return defaultConfiguration<Tipo>(
                composition.combo?.tipo || TYPES[0],
                ['goal/no_goal', 'under/over'],
            )

        case 'handicap':
        default:
            return { available: [] }
    }
}

export const comboParzialiConstraints = (
    composition: ForecastComposition,
): ConstraintConfiguration<Parziali> => {
    const defaultConfig = defaultConfiguration<Parziali>(
        composition.combo!.parziali,
        PARZIALI,
    )
    const disabledConfig: ConstraintConfiguration<Parziali> = {
        default: 'fine_partita',
        available: [],
    }
    switch (composition.tipo) {
        case 'esito':
            switch (composition.combo!.tipo) {
                case 'esito':
                    return {
                        default: 'secondo_tempo',
                        available: ['secondo_tempo'],
                    }
                case 'goal/no_goal':
                case 'under/over':
                case 'multigoal':
                    return disabledConfig
            }
            break
        case 'esatto':
            if (composition.combo!.tipo == 'esatto') {
                return {
                    default: 'secondo_tempo',
                    available: ['secondo_tempo'],
                }
            }
            break
        case 'goal/no_goal':
            switch (composition.combo!.tipo) {
                case 'under/over':
                    return disabledConfig

                case 'goal/no_goal':
                    return {
                        default: 'secondo_tempo',
                        available: ['secondo_tempo'],
                    }
                default:
                    break
            }
            break
        case 'multigoal':
            if (composition.combo!.tipo == 'multigoal') {
                return {
                    default: 'secondo_tempo',
                    available: ['secondo_tempo'],
                }
            }
            break
        case 'under/over':
            if (composition.combo!.tipo == 'goal/no_goal') return disabledConfig
            if (composition.combo!.tipo == 'under/over')
                return {
                    default: 'secondo_tempo',
                    available: ['secondo_tempo'],
                }
            break
        default:
            return defaultConfig
    }
    return defaultConfig
}

export const comboTeamConstraints = (
    composition: ForecastComposition,
): ConstraintConfiguration<Team> => {
    const defaultConfig = defaultConfiguration<Team>(
        composition.combo!.team,
        TEAMS,
    )
    const disabledConfig: ConstraintConfiguration<Team> = {
        default: 'nd',
        available: [],
    }
    switch (composition.tipo) {
        case 'esatto':
            return disabledConfig
        case 'esito':
            switch (composition.combo!.tipo) {
                case 'esito':
                case 'under/over':
                case 'goal/no_goal':
                    return disabledConfig

                default:
                    break
            }
            break
        case 'goal/no_goal':
            switch (composition.combo!.tipo) {
                case 'under/over':
                case 'goal/no_goal':
                    return disabledConfig

                default:
                    break
            }
            break
        case 'handicap':
        case 'multigoal':
            return disabledConfig

        case 'under/over':
            if (composition.combo!.tipo == 'goal/no_goal') {
                return disabledConfig
            }
            break
        default:
            break
    }
    return defaultConfig
}
