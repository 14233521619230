import { ChangeEvent, useEffect, useState } from 'react'
import {
    UnderOverData,
    UnderOverValuesData,
} from '../../../models/forecast-composition.model'
import ToggleSwitch from '../../ToggleSwitch'

const values: UnderOverValuesData[] = [0.5, 1.5, 2.5, 3.5, 4.5, 5.5]

type Props = {
    onChange: (data: UnderOverData) => void
}

const UnderOverForm = (props: Props) => {
    const ids = values.map(
        (v) => v?.toString() + '-' + Math.random() || '' + Math.random(),
    )

    const [data, setData] = useState<UnderOverData>({ under: 0.5, over: null })

    const onCheck = (
        e: ChangeEvent<HTMLInputElement>,
        key: 'under' | 'over',
        value: UnderOverValuesData,
    ) => {
        console.log(key)
        let newData = { ...data }
        newData[key] = value
        newData[key == 'over' ? 'under' : 'over'] = null
        e.target.checked && setData(newData)
    }
    useEffect(() => props.onChange(data), [data])

    return (
        <div className="flex justify-around">
            <div className="flex flex-col gap-1 items-center">
                <div>Under</div>
                {values.map((d, i) => (
                    <ToggleSwitch
                        id={'under-' + ids[i]}
                        key={'under-' + d!.toString()}
                        label={d!.toString()}
                        checked={data.under == d}
                        onChange={(e) => onCheck(e, 'under', d)}
                    />
                ))}
            </div>

            <div className="flex flex-col gap-1 items-center">
                <div>Over</div>

                {values.map((d, i) => (
                    <ToggleSwitch
                        id={'over-' + ids[i]}
                        key={'over-' + d?.toString()}
                        label={d!.toString()}
                        checked={data.over == d}
                        onChange={(e) => onCheck(e, 'over', d)}
                    />
                ))}
            </div>
        </div>
    )
}

export default UnderOverForm
