import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import routes from '../routing/routes'
import Modal from './Modal'

type Props = {
    children: React.ReactNode
    title?: string
}
const HomeBox = (props: Props) => {
    return (
        <Modal className="w-4/5 lg:w-[600px] p-8 h-2/3">
            <div className="flex flex-col h-full">
                {props.title && <h1 className="mb-8">{props.title}</h1>}
                <div className="flex-1">{props.children}</div>
                <Link
                    className="text-right block text-primary text-xs m-4 font-thin"
                    to={routes.home}
                >
                    <FontAwesomeIcon icon={faArrowLeft} /> Torna alla home
                </Link>
            </div>
        </Modal>
    )
}

export default HomeBox
