import refundIcon from '../assets/refund.png'
import PackModel from '../models/pack.model'

const PricingCard = (props: { pack: PackModel; onClick: () => void }) => {
    return (
        <div
            className="relative p-2 w-100 max-w-[480px] bg-cover text-white box-border rounded-2xl overflow-hidden shadow"
            style={{ backgroundImage: `url(${props.pack.img})` }}
        >
            <div className="absolute text-sm font-bold top-1 left-3 drop-shadow-stroke">
                {props.pack.name}
            </div>
            <div className="absolute top-1 right-3 font-bold drop-shadow-stroke">
                {props.pack.price}€
            </div>
            <div className="flex flex-col gap-2">
                <div className="text-[2.2rem] font-bold text-center text-yellow-400 drop-shadow-stroke">
                    {props.pack.betcoins} BC
                </div>
                <div className="bg-[#006607b3] rounded-lg flex items-center gap-2 justify-center font-bold p-2 text-xs w-100">
                    <img
                        src={refundIcon}
                        alt="Refund Icon"
                        className="w-6 h-6"
                    />
                    <div className="drop-shadow-stroke text-center">
                        Rimborso dei crediti lose sul primo pacchetto
                        acquistato!*
                    </div>
                </div>
                <button
                    onClick={props.onClick}
                    className="bg-yellow-400 text-md py-1 border-2 border-greyBg rounded-2xl font-bold text-greyBg shadow w-2/3 block mx-auto"
                >
                    ATTIVA OFFERTA
                </button>
            </div>
        </div>
    )
}

export default PricingCard
