import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import tw from 'twin.macro'
import { useApi } from '../../../context/Api'
import { useAppState } from '../../../context/AppState'
import ForecastModel from '../../../models/forecast.model'
import GridLayout, { ElemGrid } from '../../GridLayout'
import { MatchDateTime, MatchGrid, PriceTag, TipsterName } from '../common'
import Styled from '../Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { differenceInSeconds } from 'date-fns'
import classNames from 'classnames'
import routes from '../../../routing/routes'
import { forecastToString } from '../utilities'

export const teamGrid: ElemGrid = { cols: 2, rows: 1 }

const MostraButton = (props: {
    disabled?: boolean
    forecast: ForecastModel
    onClick: (e: any) => void
}) => {
    const text =
        props.forecast.multi || props.forecast.hideComposition
            ? 'pronostico'
            : props.forecast.hideMatch
            ? 'evento'
            : ''
    return (
        <button
            disabled={props.disabled}
            className="relative ml-2 my-2 p-1 uppercase text-xxs text-black/70 rounded shadow-lg font-bold bg-primary self-center w-auto break-words text-center"
            onClick={props.onClick}
        >
            <span>
                Mostra
                <br />
                {text}
            </span>
        </button>
    )
}

const Quota = (props: { value: number }) => {
    return (
        <div className="text-center leading-tight">
            <div className="text-xxxs font-thin uppercase">Quota</div>
            <div className="font-bold text-lg text-primary">
                {props.value?.toFixed(2)}
            </div>
        </div>
    )
}

const DataToShow = (props: { forecast: ForecastModel }) => {
    let forecasts
    if (props.forecast.multi) {
        forecasts = props.forecast.forecasts
    } else {
        forecasts = [props.forecast]
    }
    return (
        <div className=" divide-y divide-dashed divide-gray-600">
            {forecasts?.map((f, i) => (
                <>
                    {!f.hideMatch && (
                        <div key={i} className="py-1">
                            <MatchGrid match={f.match} />
                        </div>
                    )}
                    {!f.hideComposition && (
                        <div className="py-2">
                            <MatchDateTime matchDate={f.match.date} hideTime />
                            <div className="text-yellow-400">
                                {forecastToString(f)}
                            </div>
                        </div>
                    )}
                </>
            ))}
        </div>
    )
}
type Props = {
    forecast: ForecastModel
    onBuy: (forecast: ForecastModel) => void
    onBeforeBuy?: (forecast: ForecastModel) => boolean
    purchaseDisabled?: boolean
    widget?: boolean
}

const SellingForecast = ({
    forecast,
    onBuy,
    purchaseDisabled: purchaseDisabled,
    widget,
    onBeforeBuy,
}: Props) => {
    const { setBalance, balance, switchLoading } = useAppState()
    const { buyForecast } = useApi()
    const navigate = useNavigate()
    const [timerInterval, setTimerInterval] = useState<ReturnType<
        typeof setInterval
    > | null>(null)
    const [expired, setExpired] = useState(false)
    const getRemainingTime = () => {
        const diff = differenceInSeconds(forecast.eventDate, new Date())
        const hours = Math.floor(diff / (60 * 60))
        const minutes = Math.floor(diff / 60 - hours * 60)
        const seconds = Math.floor(diff - hours * 60 * 60 - minutes * 60)

        return {
            hours,
            minutes,
            seconds,
        }
    }

    const [timeRemaining, setTimeRemaining] = useState<{
        hours: number
        minutes: number
        seconds: number
    } | null>(getRemainingTime())
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(getRemainingTime())
        }, 1000)

        setTimerInterval(interval)
    }, [])

    useEffect(() => {
        if (
            timerInterval &&
            timeRemaining &&
            timeRemaining.hours == 0 &&
            timeRemaining.minutes == 0 &&
            timeRemaining.seconds == 0
        ) {
            clearInterval(timerInterval)
            setExpired(true)
        }
    }, [timeRemaining])
    const buy = async (forecast: ForecastModel) => {
        if (onBeforeBuy && !onBeforeBuy(forecast)) {
            return
        }
        if (balance < forecast.price) {
            Swal.fire({
                title: 'Ops! Sembra tu non abbia crediti.',
                text: 'Segli un pacchetto per sbloccare i pronostici e vincere insieme a noi!',
                showConfirmButton: true,
                confirmButtonText: 'Vai!',
                confirmButtonColor: `${tw`text-green-500`}`,
            }).then(() => navigate('/app/dashboard'))

            return
        }

        Swal.fire({
            title: `Vuoi visualizzare il pronostico per ${forecast.price} betcoins?`,
            confirmButtonText: 'Visualizza',
            cancelButtonText: 'Annulla',
            showCloseButton: true,
            showCancelButton: true,
            reverseButtons: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                switchLoading(true)
                const res = await buyForecast(forecast.id)
                setBalance(balance - forecast.price)
                onBuy(forecast)

                Swal.fire(
                    'Complimenti!',
                    'Puoi visualizzare il pronostico nella sezione "Sbloccati"',
                    'success',
                )
                switchLoading(false)
            } else if (result.isDenied) {
            }
        })
    }

    return (
        <Styled>
            {forecast.price == 0 && (
                <span className="bg-yellow-300 text-yellow-900 shadow-sm absolute right-0 rounded-sm font-bold rotate-12 top-0 text-base px-1">
                    FREE!
                </span>
            )}
            <div className="absolute -top-1 left-0 right-0 flex justify-center items-start">
                {forecast.multi && (
                    <span className="bg-gray-900 rounded-md px-1 text-white text-[7px] leading-3 uppercase">
                        multipla
                    </span>
                )}
            </div>
            <div className="-ml-2 -mt-1 flex justify-between">
                <TipsterName creator={forecast.creator} />
                {timeRemaining && (
                    <div
                        className={classNames(
                            'text-xxs flex gap-1 items-center',
                            {
                                'text-red-500':
                                    timeRemaining.hours == 0 &&
                                    timeRemaining.minutes < 10,
                            },
                        )}
                    >
                        <FontAwesomeIcon icon={faClock} />
                        <span className="font-mono font-bold">
                            {timeRemaining.hours.toString().padStart(2, '0')}:
                            {timeRemaining.minutes.toString().padStart(2, '0')}:
                            {timeRemaining.seconds.toString().padStart(2, '0')}
                        </span>
                    </div>
                )}
            </div>

            <div className="flex mx-1">
                <div className="flex-1 flex flex-col">
                    <GridLayout
                        gap={0}
                        padding={0}
                        elements={[
                            {
                                grid: {
                                    cols: 2,
                                    rows: 2,
                                    lg: { cols: 3, rows: 1 },
                                    fullWidth: true,
                                },
                                content: <DataToShow forecast={forecast} />,
                            },
                            {
                                grid: {
                                    hidden: true,
                                    rows: 1,
                                    lg: { rows: 1 },
                                },
                                content: (
                                    <div className="blur text-lg">XXXXXXXX</div>
                                ),
                            },
                            {
                                grid: { rows: 2, cols: 1, lg: { rows: 1 } },
                                content: <Quota value={forecast.quotation} />,
                            },
                            {
                                grid: { rows: 2, cols: 1, lg: { rows: 1 } },
                                content:
                                    forecast.price > 0 &&
                                    (<PriceTag price={forecast.price} /> || ''),
                            },
                        ]}
                    />
                </div>

                {!purchaseDisabled && (
                    <MostraButton
                        disabled={expired}
                        forecast={forecast}
                        onClick={() => buy(forecast)}
                    />
                )}
            </div>
        </Styled>
    )
}

export default SellingForecast
