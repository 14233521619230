import { ChangeEvent, useState } from 'react'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import { useAppState } from '../../../context/AppState'
import { PackDTO } from '../../../models/pack.dto'
import PackModel, { PackCode, UpdatePackDTO } from '../../../models/pack.model'
import { apiServiceInst } from '../../../services/Api'
import ToggleSwitch from '../../../components/ToggleSwitch'

type Props = {
    pack: PackModel
    onSave: (data: Partial<UpdatePackDTO>) => void
}

const PackForm = ({ pack, onSave }: Props) => {
    const { switchLoading, toggleModal } = useAppState()

    const [updateData, setUpdateData] = useState<Partial<UpdatePackDTO>>({
        name: pack.name,
        betcoins: pack.betcoins,
        price: pack.price,
        soldOut: pack.soldOut,
    })

    const save = async () => {
        switchLoading(true)
        const result = await apiServiceInst.updatePack(pack.code!, updateData)
        switchLoading(false)
        onSave(updateData)
        toggleModal(false)
    }
    return (
        <div className="flex flex-col">
            <div className="flex flex-col md:flex-row justify-start md:justify-between">
                <div className="mb-8 md:m-0">
                    <label
                        className="block font-light text-sm pr-2"
                        htmlFor="name"
                    >
                        Nome
                    </label>
                    <Input
                        id="name"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setUpdateData({
                                ...updateData,
                                name: e.target.value,
                            })
                        }
                        value={updateData.name}
                        className="w-full"
                    />
                </div>
                <div className="mb-8 md:m-0">
                    <label
                        className="block font-light text-sm pr-2"
                        htmlFor="betcoins"
                    >
                        Betcoins
                    </label>
                    <Input
                        type="number"
                        id="betcoins"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setUpdateData({
                                ...updateData,
                                betcoins: e.target.valueAsNumber,
                            })
                        }
                        value={updateData.betcoins}
                        className="w-full"
                    />
                </div>
                <div className="mb-8 md:m-0">
                    <label
                        className="block font-light text-sm pr-2"
                        htmlFor="price"
                    >
                        Prezzo
                    </label>
                    <Input
                        className="w-full"
                        type="number"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setUpdateData({
                                ...updateData,
                                price: e.target.valueAsNumber,
                            })
                        }
                        id="price"
                        value={updateData.price}
                    />
                </div>

                <div className="mb-8 md:m-0">
                    <label
                        className="block font-light text-sm pr-2"
                        htmlFor="price"
                    >
                        Sold out
                    </label>
                    <ToggleSwitch
                        id="sold-out"
                        checked={updateData.soldOut}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setUpdateData({
                                ...updateData,
                                soldOut: e.target.checked,
                            })
                        }
                    />
                </div>
                <Button onClick={save}>Salva</Button>
            </div>
        </div>
    )
}

export default PackForm
