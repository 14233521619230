import { useEffect, useState } from 'react'
import SoldForecast from '../../components/Forecast/SoldForecast'
import { useApi } from '../../context/Api'
import ForecastModel, { MultiForecastModel } from '../../models/forecast.model'
import ScrollableContent from '../../components/ScrollableContent'
import { PageTitle } from '../../components/typography'

const MyForecasts = () => {
    const [forecasts, setForecasts] = useState<
        (ForecastModel | MultiForecastModel)[]
    >([])
    const { getMyForecasts } = useApi()
    const getData = async () => {
        const _forecasts = await getMyForecasts()
        setForecasts(_forecasts)
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <div className="h-full flex flex-col">
            <PageTitle>Sbloccati</PageTitle>
            <ScrollableContent>
                <div className="flex flex-col gap-2">
                    {forecasts.map((f, i) => (
                        <SoldForecast forecast={f} />
                    ))}
                    {forecasts.length == 0 && (
                        <div className="text-center p-8 text-sm font-light text-gray-500/[.5]">
                            Nessun pronostico attivo.
                        </div>
                    )}
                </div>
            </ScrollableContent>
        </div>
    )
}

export default MyForecasts
