import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ForecastModel, {
    MultiForecastModel,
} from '../../../models/forecast.model'
import GridLayout, { ElemGrid } from '../../GridLayout'
import { TipsterName } from '../common'
import Styled from '../Styled'
import { esitoGridContent } from '../utilities'
import { ForecastHeader, commonElements } from './common'

type Props = {
    forecast: MultiForecastModel
}

const SingleForecast = (forecast: ForecastModel) => (
    <GridLayout
        padding={0}
        gap={0}
        rows={{ mob: 1, lg: 1 }}
        elements={commonElements(forecast, false)}
    />
)

const MultiStoricoForecast = ({ forecast }: Props) => {
    return (
        <Styled>
            <ForecastHeader forecast={forecast} />
            <div className="absolute -top-1 left-0 right-0 flex justify-center items-start">
                <span className="bg-gray-900 rounded-md px-1 text-white text-[7px] leading-3 uppercase">
                    multipla
                </span>
            </div>
            <div className="flex items-center">
                <div className="flex-1 px-2">
                    <div className="w-full divide-y divide-dashed -my-2 divide-gray-600">
                        {forecast!.forecasts.map((f, i) => (
                            <div key={i} className="py-2">
                                {SingleForecast(f)}
                            </div>
                        ))}
                    </div>
                </div>
                {esitoGridContent(forecast.success)}
            </div>
        </Styled>
    )
}

export default MultiStoricoForecast
