import classNames from 'classnames'
import React, { useState } from 'react'

export type SectionNavItem = {
    id: string
    title: string | React.ReactNode
}
type Props = {
    elems: SectionNavItem[]
    onSelect: (item: SectionNavItem) => void
    fullWidthItems?: boolean
}

const NavElem = ({
    title,
    active,
}: {
    title: string | React.ReactNode
    active?: boolean
}) => (
    <div
        className={classNames({
            'text-center w-auto px-2 py-2 border-b-2 text-xs whitespace-nowrap ':
                true,
            'border-primary-600 font-bold': active,
        })}
    >
        {title}
    </div>
)

const SectionNav = (props: Props) => {
    const [selected, setSelected] = useState(props.elems[0].id)
    return (
        <div className="flex w-full flex-nowrap overflow-x-scroll gap-1 shrink-0 text-white">
            {props.elems.map((el: SectionNavItem) => (
                <div
                    className={classNames('cursor-pointer', {
                        grow: props.fullWidthItems,
                    })}
                    key={el.id}
                    onClick={() => {
                        setSelected(el.id)
                        props.onSelect(el)
                    }}
                >
                    <NavElem title={el.title} active={selected == el.id} />
                </div>
            ))}
        </div>
    )
}

export default SectionNav
