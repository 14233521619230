import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import Swal from 'sweetalert2'
import Box from '../components/Box'
import Button from '../components/Button'
import { useApi } from '../context/Api'
import { useAuth } from '../context/Auth'
import { UserProfileDataDTO } from '../models/user-profile-data.dto'
import routes from '../routing/routes'

const Profile = () => {
    const { getUserData } = useApi()
    const [user, setUser] = useState<UserProfileDataDTO | null>()
    useEffect(() => {
        getUserData().then((res) => {
            setUser(res)
        })
    }, [])
    return (
        <Box
            scrollable
            title="Profilo"
            className="h-full w-full md:w-md-box mx-auto"
        >
            <div className="p-4">
                {user && (
                    <div className="overflow-x-scroll">
                        <div className="flex gap-2 items-stretch">
                            {/* <Link to={routes.relativeHome}>
                                <Button className="h-full">Visualizza</Button>
                            </Link> */}
                            <Link to={routes.changePassword}>
                                <Button>Cambia password</Button>
                            </Link>
                            <Link to="update" state={{ user }}>
                                <Button>Aggiorna profilo</Button>
                            </Link>
                            <Link to="upload-profile-picture" state={{ user }}>
                                <Button>Cambia immagine</Button>
                            </Link>
                        </div>
                    </div>
                )}
                <div className="py-2 my-2">
                    <Outlet context={{ user }} />
                </div>
            </div>
        </Box>
    )
}

export default Profile
