import {
    faArrowLeft,
    faCheck,
    faEye,
    faEyeSlash,
    faHome,
    faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import tw from 'twin.macro'
import Box from '../../../components/Box'
import Button from '../../../components/Button'
import useLogin from '../../../hooks/useLogin'
import Input from '../../../components/Input'
import { useApi } from '../../../context/Api'
import UserRegistrationDataDTO from '../../../models/user-registration-data.dto'
import routes from '../../../routing/routes'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import RegisterFields, { CommonInputTypes } from './RegisterFields'
import TermsAndConditions from '../TermsAndConditions'

type InputTypes = CommonInputTypes & {
    adult: boolean
    terms: boolean
    privacy: boolean
    referer: string
}

const RegisterAffiliate = ({ asTipster }: { asTipster?: boolean }) => {
    const { registerUser } = useApi()
    const methods = useForm<InputTypes>()
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setError,
        setValue,
    } = methods

    const login = useLogin()

    useEffect(() => {}, [])

    const onSubmit = async (fieldValues: FieldValues) => {
        try {
            let values = fieldValues as UserRegistrationDataDTO
            values.affiliate = true

            registerUser(fieldValues as UserRegistrationDataDTO).then(
                async (res) => {
                    if ((res as any).response?.status == 400) {
                        if ((res as any).response.data.message == 'email') {
                            setError('email', {
                                type: 'validate',
                                message: 'Email già esistente',
                            })
                        }
                        if ((res as any).response.data.message == 'username') {
                            setError('username', {
                                type: 'validate',
                                message: 'Username già esistente',
                            })
                        }
                        return
                    }

                    await login(fieldValues.username, fieldValues.password)
                },
                (res) => {
                    if (res.request.status === 400) {
                        console.log(res.request)
                        // setErrors({
                        //     ...errors,
                        //     form: 'Username o email già esistenti',
                        // })

                        return
                    }
                },
            )
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="w-full flex justify-center items-center h-full py-4">
            <Box
                scrollable
                className="m-4 w-full md:w-[360px] h-full"
                title="Registrati come affiliato"
            >
                <FormProvider {...methods}>
                    <StyledForm onSubmit={handleSubmit(onSubmit)}>
                        <RegisterFields />

                        <div className="pb-8 mx-auto w-full">
                            <div className="flex gap-2 items-center">
                                <input
                                    {...register('adult', {
                                        validate: (value: boolean) =>
                                            value === true ||
                                            'Devi avere 18+ anni per utilizzare il servizio.',
                                    })}
                                    id="adult"
                                    type="checkbox"
                                />
                                <label className="" htmlFor="adult">
                                    Confermo di avere 18+ anni
                                </label>
                            </div>
                            {errors.adult && (
                                <div className="error">
                                    {errors.adult?.message}
                                </div>
                            )}
                        </div>

                        <div className="pb-8 mx-auto w-full">
                            <div className="flex gap-2 items-center">
                                <input
                                    {...register('privacy', {
                                        validate: (value: boolean) =>
                                            value === true ||
                                            'Devi accettare le condizioni di privacy per utilizzare il servizio',
                                    })}
                                    id="privacy"
                                    type="checkbox"
                                />
                                <label className="" htmlFor="privacy">
                                    Accetto le condizioni di{' '}
                                    <a
                                        className="underline"
                                        target="_blank"
                                        href="https://youtipster.com/privacy"
                                    >
                                        privacy
                                    </a>
                                    .
                                </label>
                            </div>
                            {errors.privacy && (
                                <div className="error">
                                    {errors.privacy?.message}
                                </div>
                            )}
                        </div>

                        <div className="pb-8 mx-auto w-full">
                            <div className="flex gap-2 items-center">
                                <input
                                    {...register('terms', {
                                        validate: (value: boolean) =>
                                            value === true ||
                                            'Devi accettare i termini e condizioni.',
                                    })}
                                    id="terms"
                                    type="checkbox"
                                />
                                <label className="" htmlFor="terms">
                                    Accetto i{' '}
                                    <a
                                        className="underline"
                                        target="_blank"
                                        href="https://youtipster.com/condizioni"
                                    >
                                        termini e condizioni
                                    </a>
                                    .
                                </label>
                            </div>
                        </div>

                        <Button
                            type="submit"
                            className="text-lg w-full"
                            color="primary"
                        >
                            <FontAwesomeIcon icon={faUser} />{' '}
                            {asTipster ? 'Invia richiesta' : 'Registrati'}
                        </Button>
                    </StyledForm>
                </FormProvider>
                <TermsAndConditions />
                <Link
                    className="text-center mx-auto block text-primary text-xs underline my-4 font-thin"
                    to={asTipster ? routes.register : routes.registerTipster}
                >
                    {`Registrati come ${
                        asTipster ? 'utente normale' : 'tipster'
                    }`}
                </Link>

                <Link
                    className="text-right block text-primary text-xs m-4 font-thin"
                    to={routes.home}
                >
                    <FontAwesomeIcon icon={faArrowLeft} /> Torna alla home
                </Link>
            </Box>
        </div>
    )
}

export default RegisterAffiliate

const StyledForm = styled.form`
    ${tw`p-4 flex flex-col justify-center items-center w-full`}
    & label {
        ${tw`text-xs mr-4 block text-left`}
    }

    & .error {
        ${tw`text-danger text-xxs pt-2`}
    }
`
