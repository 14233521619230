const MenuDetailRow = ({
    children,
    label,
}: {
    children: React.ReactChild
    label: string | React.ReactNode
}) => (
    <div className="flex justify-between items-center">
        <span className="text-xs font-light">{label}</span>
        <span className="font-bold text-sm">{children}</span>
    </div>
)

export default MenuDetailRow
