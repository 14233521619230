import {
    faBank,
    faBoltLightning,
    faClock,
    faGlobe,
    faRankingStar,
    faShop,
    faTachometerAlt,
    faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { NavItem } from '../../components/Nav'

const affiliateNavItems: NavItem[] = [
    {
        text: 'Pronostici',
        icon: faBoltLightning,
        to: 'pronostici',
    },
    {
        text: 'Storico',
        icon: faClock,
        to: 'storico',
    },
    {
        text: 'Classifiche',
        icon: faRankingStar,
        to: 'charts',
    },
    {
        text: 'Utenti',
        icon: faUsers,
        to: 'utenti',
    },
    {
        text: 'Saldo e Movimenti',
        icon: faBank,
        to: 'movimenti',
    },
]

export default affiliateNavItems
