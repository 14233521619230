import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useApi } from '../../context/Api'
import TipsterMovement from '../../models/tipster-movement.model'
import { zonedDate } from '../../utils'
import GridLayout from '../GridLayout'
import MutedText from '../styled/MutedText'

const DateTag = ({ date }: { date: string }) => (
    <div className="w-full text-left text-white/[.8] pl-2 text-xxxs">
        {format(zonedDate(date), 'dd/MM/yyyy')}
    </div>
)

const PayoutRow = ({ movimento }: { movimento: TipsterMovement }) => (
    <div className="w-full bg-green-700/[.2] p-1 px-2">
        <DateTag date={movimento.date} />
        <GridLayout
            className="w-full"
            padding={0}
            elements={[
                {
                    grid: {
                        cols: 3,
                        rows: 1,
                        fullWidth: true,
                        md: {
                            cols: 4,
                        },
                    },
                    content: (
                        <div className="uppercase text-xs p-1 bg-green-700 rounded-sm text-center inline mr-auto">
                            pagato
                        </div>
                    ),
                },

                {
                    grid: { fullWidth: true, cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">totale</div>
                            <div className="font-bold whitespace-nowrap">
                                {movimento.amount?.toFixed(2)} €
                            </div>
                        </div>
                    ),
                },
            ]}
        />
    </div>
)

const CommissionRow = ({ movimento }: { movimento: TipsterMovement }) => (
    <div className="w-full bg-slate-700/[.2] p-1 px-2">
        <DateTag date={movimento.date} />
        <GridLayout
            className="w-full"
            padding={0}
            elements={[
                {
                    grid: {
                        cols: 1,
                        rows: 1,
                        hidden: true,
                        fullWidth: true,
                        sm: { hidden: false },
                    },
                    content: (
                        <div className="uppercase text-xs p-1 bg-slate-700 rounded-sm text-center inline mr-auto">
                            Commissioni
                        </div>
                    ),
                },
                {
                    grid: { cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="text-xs leading-tight flex items-center gap-1">
                                <FontAwesomeIcon
                                    className="text-xxs"
                                    icon={faUser}
                                />
                                {movimento.username}
                            </div>
                        </div>
                    ),
                },
                {
                    grid: { cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">
                                valore pack
                            </div>
                            <div className="md:text-md text-xs whitespace-nowrap">
                                {movimento.amount?.toFixed(2)} €
                            </div>
                        </div>
                    ),
                },
                {
                    grid: { cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">percent</div>
                            <div className="text-xs">
                                {movimento.commission}%
                            </div>
                        </div>
                    ),
                },

                {
                    grid: { fullWidth: true, cols: 1, rows: 1 },
                    content: (
                        <div className="">
                            <div className="uppercase text-xxxs">totale</div>
                            <div className="font-bold">
                                {(
                                    (movimento.amount * movimento.commission) /
                                    100
                                ).toFixed(2)}{' '}
                                €
                            </div>
                        </div>
                    ),
                },
            ]}
        />
    </div>
)
const TipsterTransactions = ({ tipsterId }: { tipsterId: string }) => {
    const [movements, setMovements] = useState<TipsterMovement[]>([])
    const { getUserRevenuesMovements } = useApi()

    useEffect(() => {
        getUserRevenuesMovements(tipsterId).then((res) => setMovements(res))
    }, [])
    return (
        <div className="flex-1 divide-y-2 divide-black/[.15]">
            {movements.length == 0 && (
                <MutedText className="text-center p-8">
                    Nessun movimento.
                </MutedText>
            )}
            {movements.map((m, i) => (
                <div key={i}>
                    {m.type == 'payout' && <PayoutRow movimento={m} />}
                    {m.type == 'pack-commission' && (
                        <CommissionRow movimento={m} key={i} />
                    )}
                </div>
            ))}
        </div>
    )
}

export default TipsterTransactions
