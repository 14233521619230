import { useEffect, useState } from 'react'
import { ForecastCommissionModel } from '../../models/forecast.model'
import { useQuery } from 'react-query'
import { apiServiceInst } from '../../services/Api'
import ForecastCommissions from './ForecastCommissions'
import PaginatedList from '../Pagination/PaginatedList'
type Props = {
    tipsterId: string
}

const CommissionsList = (props: Props) => {
    const [page, setPage] = useState(0)

    const [perPage, setPerPage] = useState(15)
    const commissionsQuery = useQuery(['commissions-forecast', page], () =>
        apiServiceInst.getForecastsCommissions({
            from: page * perPage,
            to: (page + 1) * perPage,
            userId: props.tipsterId,
        }),
    )

    return (
        <div className="flex flex-col gap-2 h-full">
            {commissionsQuery.data && commissionsQuery.data.total > 0 && (
                <PaginatedList
                    items={commissionsQuery.data?.data || []}
                    total={commissionsQuery.data?.total || 0}
                    onPageChange={(pageNum) => setPage(pageNum)}
                    itemBuilder={(data) => (
                        <ForecastCommissions forecast={data} />
                    )}
                    perPage={perPage}
                />
            )}
        </div>
    )
}

export default CommissionsList
