import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useApi } from '../context/Api'
import { useAppState } from '../context/AppState'
import { useAuth } from '../context/Auth'
import routes from '../routing/routes'
import useAuthorizations from './useAuthorizations'

const useLogin = () => {
    const { login } = useApi()
    const { switchLoading } = useAppState()
    const { setJWT, decodeJWT } = useAuth()
    const navigate = useNavigate()
    const { navigateHome } = useAuthorizations()
    return async (username: string, password: string) => {
        switchLoading(true)
        try {
            const res = await login(username, password)
            setJWT(res.access_token)
            const user = decodeJWT(res.access_token) // decoding two times here

            if (!user.lastLogin) {
                Swal.fire({
                    text: 'Ti diamo il benvenuto! Controlla la tua mail per confermare il tuo account.',
                })
            }
            const afterLoginRedirect =
                sessionStorage.getItem('afterLoginRedirect')
            if (afterLoginRedirect) {
                console.log('redirect to ', afterLoginRedirect)
                navigate(afterLoginRedirect)
                return
            }
            navigateHome(user)
        } catch (error) {
            throw error
        } finally {
            switchLoading(false)
        }
    }
}

export default useLogin
